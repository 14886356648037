import { Card, CardContent, CardMedia, Stack, Typography, Grid } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalFooterCloseBtn, ModalFooterSaveBtn } from '../'
import questinMarkSvg from './question-mark.svg'
import { handleInputChange } from '../../../_helpers';
import { FormBrowseFile } from "../../form";

function UpdateProfileModal({ show, showLoader, data, closeModal, updateProfile }) {

    const [profileData, setProfileData] = useState({ profile_picture: '' });
    const [action] = useState({ isProcessing: false, isSubmitted: false });

    /**
     * function to handle input changes and modify the value
     * @author Naveen
     * @created_at 21 Sept 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        // setErrors({ ...errors, ...(validate(name, value, data)) });
        profileData[name] = value;
        setProfileData({ ...profileData });
    }

    const onClose = () => {
        closeModal(false);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        updateProfile({ profile_picture: profileData.profile_picture });
    }

    return (
        <Modal isDialogOpen={show} onClose={onClose} loader={showLoader} maxWidth="sm" PaperProps={{ style: { padding: 0 } }}>
            <Card style={{ boxShadow: `none`, padding: 30 }}>
                <CardMedia component="img" image={questinMarkSvg} style={{ position: "absolute", padding: 0, bottom: 0, right: 0, top: 0, width: "60%", opacity: '0.1' }} />
                <form onSubmit={handleSubmit} noValidate className='ns-form' >
                    <ModalBody style={{ padding: 0 }}>
                        <CardContent style={{ display: `flex`, alignItems: `center`, padding: `2rem 1rem 0 1rem` }}>
                            <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                                <FormBrowseFile
                                    tabIndex={10}
                                    name="profile_picture"
                                    placeholder="Add Profile Image"
                                    label='profile_picture'
                                    value={profileData.profile_picture}
                                    // error={action.isSubmitted && errors.profile_picture ? errors.profile_picture : ''}
                                    onChange={handleChange}
                                    acceptType=".png,.jpg,.jpeg"
                                    info="Allowed Format: png, jpg, jpeg | Allowed Maximum Size: 2 MB"
                                />
                            </Grid>
                        </CardContent>
                    </ModalBody>
                    <ModalFooter style={{ padding: 0 }}>
                        <ModalFooterSaveBtn tabIndex={3} text='Submit' isProcessing={action.isProcessing} />
                        <ModalFooterCloseBtn tabIndex={4} onClose={onClose} />
                    </ModalFooter>
                </form>
            </Card>
        </Modal>
    )
}

export { UpdateProfileModal }
