
import { forwardRef } from "react";
import PropTypes from "prop-types";
import StyledBox from "./StyledBox";

const Box = forwardRef(({ variant, bgColor, color, opacity, borderRadius, shadow, coloredShadow, ...rest }, ref) => (
  <StyledBox
    {...rest}
    ref={ref}
    ownerState={{ variant, bgColor, color, opacity, borderRadius, shadow, coloredShadow }}
  />
)
);

Box.defaultProps = {
  variant: "contained",
  bgColor: "transparent",
  // color: "dark",
  opacity: 1,
  borderRadius: "none",
  shadow: "none",
  coloredShadow: "none",
};

// Typechecking props for the MDBox
Box.propTypes = {
  variant: PropTypes.oneOf(["contained", "gradient"]),
  bgColor: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.number,
  borderRadius: PropTypes.string,
  shadow: PropTypes.string,
  coloredShadow: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "none",
  ]),
};

export { Box };
