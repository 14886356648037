import React from 'react';
import { Box, Card, CardContent, Grid, Skeleton } from '@mui/material';
import { styled } from '@mui/system';

const AdvertisementPaper = styled(({ ...props }) => (<Card {...props} />))`
        padding: 0;
        & .MuiSvgIcon-root {
            color: ${props => props.theme.palette.secondary.main};
            background: rgba(255, 255, 255, 0.64);
            border-radius: 50%;
            padding: 3px;
        }
        & .MuiTypography-root {
            color: ${props => props.theme.palette.white.main};
        },
` ;

function StaffExperienceCardLoader({ ...props }) {

    var rows = [];
    for (var i = 0; i < 4; i++) {
        rows.push(
            <Grid item xs={12} sm={12} md={3} lg={6} xl={6} key={i} {...props}>
                <AdvertisementPaper {...props} >
                    <CardContent style={{ position: "relative", height: `450px`, top: '30px', right: 0, left: 0, bottom: 0 }}>
                        <Grid container spacing={0} display={`flex`} alignItems={`center`} justifyContent={`center`} >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box style={{ paddingLeft: '40px', paddingTop: '50px', minHeight: `120px` }}>
                                    <Skeleton animation="wave" height={50} width="90%" style={{ marginBottom: 2 }} />
                                    <Skeleton animation="wave" height={50} width="90%" style={{ marginBottom: 2 }} />
                                    <Skeleton animation="wave" height={50} width="90%" style={{ marginBottom: 2 }} />
                                    <Skeleton animation="wave" height={50} width="90%" style={{ marginBottom: 2 }} />
                                    <Skeleton animation="wave" height={50} width="90%" style={{ marginBottom: 2 }} />
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box style={{ paddingLeft: '40px', paddingTop: '0px', minHeight: `120px` }}>
                                    <Skeleton animation="wave" height={90} width="90%" style={{ marginBottom: 5 }} />
                                </Box>
                            </Grid>

                        </Grid>

                    </CardContent>
                </AdvertisementPaper>
            </Grid>
        )
    }

    return <React.Fragment> {rows} </React.Fragment>

}

export { StaffExperienceCardLoader };