import {
    LEAVE_REQUEST, LEAVE_SUCCESS, LEAVE_FAILURE,
    CREATE_LEAVE_REQUEST, CREATE_LEAVE_SUCCESS, CREATE_LEAVE_FAILURE,
    DELETE_LEAVE_REQUEST, DELETE_LEAVE_SUCCESS, DELETE_LEAVE_FAILURE,
    GET_ALL_APPROVED_LEAVES_REQUEST, GET_ALL_APPROVED_LEAVES_SUCCESS, GET_ALL_APPROVED_LEAVES_FAILURE
} from "../actions";

const initialState = {
    leaves_loading: false, leaves: [], leaves_errors: {},
    page: 1, per_page: 20, total: 0, total_pages: 0,
    calendar_approved_leaves_loading: false, calendar_approved_leaves: [], calendar_approved_leaves_errors: {},
};

export function LeavesReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::Leaves details redux */
        case LEAVE_REQUEST: {
            return {
                ...state,
                leaves_loading: true,
                leaves_errors: {}
            };
        }
        case LEAVE_SUCCESS: {

            return {
                ...state,
                leaves_loading: false,
                leaves: action.data,
                leaves_errors: {},
                page: state.per_page,
                per_page: state.per_page,
                total: state.total,
                total_pages: state.total_pages
            };
        }
        case LEAVE_FAILURE: {
            return {
                ...state,
                leaves_loading: false,
                leaves_errors: action.errors
            };
        }
        /** end::Leaves details redux */

        /** begin::Create leaves redux */
        case CREATE_LEAVE_REQUEST: {
            return {
                ...state,
                leaves_loading: true,
                leaves_errors: {}
            };
        }
        case CREATE_LEAVE_SUCCESS: {
            return {
                ...state,
                leaves_loading: false,
                leaves_errors: {},
            };
        }
        case CREATE_LEAVE_FAILURE: {
            return {
                ...state,
                leaves_loading: false,
                leaves_errors: action.errors
            };
        }
        /** end::Create leaves redux */

        /** begin::Delete leaves redux */
        case DELETE_LEAVE_REQUEST: {
            return {
                ...state,
                delete_leave_loading: true,
                delete_leave_errors: {}
            };
        }
        case DELETE_LEAVE_SUCCESS: {

            let leaveArray = [...state.leaves];
            const LeaveFilter = leaveArray.filter(leave => leave.id !== action.data.id)

            return {
                ...state,
                leaves: LeaveFilter,
                delete_leave_loading: false,
                delete_leave: action.data,
                delete_leave_errors: {},
            };
        }
        case DELETE_LEAVE_FAILURE: {
            return {
                ...state,
                delete_leave_loading: false,
                delete_leave_errors: action.errors
            };
        }
        /** end::Delete leaves redux */

        /** begin::Get leaves for calendar redux */
        case GET_ALL_APPROVED_LEAVES_REQUEST: {
            return {
                ...state,
                calendar_approved_leaves_loading: true,
                calendar_approved_leaves_errors: {}
            };
        }
        case GET_ALL_APPROVED_LEAVES_SUCCESS: {

            return {
                ...state,
                calendar_approved_leaves_loading: false,
                calendar_approved_leaves: action.data,
                calendar_approved_leaves_errors: {},
            };
        }
        case GET_ALL_APPROVED_LEAVES_FAILURE: {
            return {
                ...state,
                calendar_approved_leaves_loading: false,
                calendar_approved_leaves_errors: action.errors
            };
        }
        /** end::Get leaves for calendar redux */

        default:
            return state;
    }
}