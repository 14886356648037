import React from 'react';
import { Box, CardContent, Grid, Skeleton } from '@mui/material';

function ProfileDetailsCardLoader({ ...props }) {

    var rows = [];
    for (var i = 0; i < 1; i++) {
        rows.push(
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={i} style={{ paddingTop: '10px', height: `180px` }} {...props}>
                <Grid container spacing={0} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={4} style={{ paddingTop: 0, margin: 0, width: "210px" }} >
                        <Box style={{ height: `150px`, width: "210px", paddingTop: 0, paddingBottom: "100px", paddingLeft: "30px" }} >
                            <Skeleton animation="wave" variant="circular" width={"150px"} height={"150px"} paddingBottom={"15px"} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={8} >
                        <Box style={{ paddingTop: 0, minHeight: `120px` }}>
                            <Skeleton animation="wave" height={40} width="30%" style={{ marginBottom: 5 }} />
                            <Skeleton animation="wave" height={20} width="50%" style={{ marginBottom: 5 }} />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={4} display={`flex`} alignItems={`center`} justifyContent={`center`} >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6} style={{ paddingLeft: 0 }}>
                        <Box style={{ paddingTop: 30, paddingLeft: 0, paddingRight: 1 }}>
                            <Skeleton animation="wave" height={150} width="105%" style={{ marginBottom: 0 }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6} style={{ paddingRight: 0 }}>
                        <Box style={{ paddingTop: 30, paddingRight: 0, paddingLeft: 1 }}>
                            <Skeleton animation="wave" height={150} width="105%" style={{ marginBottom: 0 }} />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return <React.Fragment> {rows} </React.Fragment>
}

export { ProfileDetailsCardLoader };