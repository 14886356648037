import * as React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { FormControl, FormHelperText } from '@mui/material';
import { FormDatePickerStyle as useStyles } from '.';
import moment  from 'moment';



/**
 * Declare default value of input properties 
 */
const defaultProps = {
    className: "",
    isReadOnly: false,
    maxLength: 255,
    maskType: ''
};



function FormDatePicker({ label, name, placeholder, className, onChange, tabIndex, id, error, isReadOnly, pattern, maxLength, maskType, minDate = null, value, info, views, inputFormat,...props }) {
    const [val, setVal] = React.useState((value && value !== '') ? moment(value, 'DD-MM-yyyy').format('MM-DD-YYYY') : null);

    const classes = useStyles();

    const handleChange = (e) => {
        setVal(e);
        onChange({ target: { type: `date_time`, name: name, value: moment(e).format('DD/MM/yyyy') } });
    }

    return (

        <FormControl fullWidth className={`${className}`} >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    views={views}  //['year', 'month']
                    value={val}
                    onChange={handleChange}
                    label={label}
                    onError={console.log}
                    minDate={minDate}
                    inputFormat= {inputFormat ?? 'dd/MM/yyyy'}
                    readOnly = {isReadOnly}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
            {(info) && <FormHelperText id={`helper_${name}`} className={classes.info}>{info}</FormHelperText>}
            {(error) && <FormHelperText id={`error_${name}`} error className={classes.info}>{error}</FormHelperText>}
        </FormControl>

    )
}


FormDatePicker.defaultProps = defaultProps;

export { FormDatePicker }