import React, { useState, useEffect } from 'react'
import { Chip, Grid, Stack } from '@mui/material';
import { Modal, ModalBody, ModalFooterCloseBtn, ModalFooterSaveBtn, ModalHeader } from '../../../_components/modal';
import { handleInputChange } from '../../../_helpers';
import { FormDatePicker } from '../../../_components/form';

// Initialize form input values to null
const inputs = { date: '' };

function PayslipFilter({ ...props }) {

    /** Initialize and declare state */
    const { payslipFilter, show, closeModal, applyFilter } = props;
    const [data, setData] = useState({ ...inputs });
    const [filteredData, setFilteredData] = useState({});

    useEffect(() => {
        setData({ date: payslipFilter.date })

        let filtered = {};
        Object.keys(payslipFilter).forEach(function (item, i) {
            if (payslipFilter[item])
                filtered[item] = payslipFilter[item];
        });
        setFilteredData({ ...filtered });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payslipFilter])

    /**
     * function to close Payslip filter modal
     * @param {string} date Entered date on Payslip filter modal 
     * @return view
     * @author Naveen
     * @created_at 21 Sept 2022
     */
    const onClose = () => {
        setData({ date: '' });
        closeModal(false);
    }

    /**
     * function to clear Payslip filter modal
     * @param {string} date Entered date on Payslip filter modal 
     * @return view
     * @author Naveen
     * @created_at 21 Sept 2022 
     */
    const onClear = () => {
        setData({ date: '' });
        applyFilter({ date: '' });
        closeModal(false);
    }

    /**
     * @param {string} e.name input name 
     * @param {string} e.value input value
     * @author Naveen
     * @created_at 21 Sept 2022
     */
    function handleSubmit(e) {
        e.preventDefault();
        closeModal(false)
        applyFilter(data);
    }

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Naveen
     * @created_at 21 Sept 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        data[name] = value;
        setData({ ...data })
    }

    /**
     * function to clear all data entered on Payslip filter modal
     * @return view
     * @author Naveen
     * @created_at 21 Sept 2022 
     */
    const clearFilterItem = (item) => {
        const clearItem = { ...data };
        clearItem[item] = '';
        setData({ ...clearItem });
        applyFilter({ ...clearItem });

    }

    return (
        <React.Fragment>
            {
                Object.keys(filteredData).length > 0 &&
                (
                    <Grid container mb={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Stack direction='row' spacing={1}>
                                {
                                    Object.keys(filteredData).map((item, i) => {
                                        // if (item === 'status') {
                                        //     const status = filterStatus.find(function (status) { return status.value === filteredData[item]; });
                                        //     return <Chip key={i} label={status.label} onDelete={() => clearFilterItem(item)} />
                                        // } else {
                                        return <Chip keys={1} label={filteredData[item]} onDelete={() => clearFilterItem(item)} style={{ textTransform: `capitalize` }} />
                                        // }
                                    })
                                }
                            </Stack>
                        </Grid>
                    </Grid>
                )
            }
            <Modal isDialogOpen={show} onClose={onClose} maxWidth='xs'>
                <form onSubmit={handleSubmit} noValidate >
                    <ModalHeader id='payslipFilter' onClose={onClose} title="Filter" />
                    <ModalBody>
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <FormDatePicker
                                    tabIndex={2}
                                    label='Created Between'
                                    name='date'
                                    placeholder="Year and Month"
                                    onChange={handleChange}
                                    value={data.date}
                                    views={['month', 'year']}
                                    inputFormat='MMMM yyyy'
                                />
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8} >
                                <Stack direction="row" justifyContent="left" alignItems="center" spacing={1}  >
                                    <ModalFooterSaveBtn tabIndex={4} text="Filter" />
                                    <ModalFooterCloseBtn tabIndex={3} onClose={onClear} text="Clear" />
                                </Stack>
                            </Grid>
                        </Grid>
                    </ModalBody>
                </form>
            </Modal>
        </React.Fragment>
    );
}

export { PayslipFilter }