import {
    ADD_PERMISSION_REQUEST, ADD_PERMISSION_SUCCESS, ADD_PERMISSION_FAILURE,
    UPDATE_PERMISSION_REQUEST, UPDATE_PERMISSION_SUCCESS, UPDATE_PERMISSION_FAILURE,
    CHANGE_PERMISSION_STATUS_REQUEST, CHANGE_PERMISSION_STATUS_SUCCESS, CHANGE_PERMISSION_STATUS_FAILURE,
    PERMISSIONS_SELECT_LIST_REQUEST, PERMISSIONS_SELECT_LIST_SUCCESS, PERMISSIONS_SELECT_LIST_FAILURE,
    GET_PERMISSIONS_REQUEST, GET_PERMISSIONS_SUCCESS, GET_PERMISSIONS_FAILURE,
} from "../actions";

const initialState = {
    add_permission_loading: false, add_permission_errors: {},
    change_permission_status_loading: false, change_permission_status_errors: {},
    permissions_select_list_loading: false, permissions_select_list: [], permissions_select_list_errors: {},
    permissions_loading: false, permissions: [], permissions_errors: [],
    page: 1, per_page: 18, total: 0, total_pages: 0,
};

export function PermissionReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::Create Permission redux */
        case ADD_PERMISSION_REQUEST: {
            return {
                ...state,
                add_permission_loading: true,
                add_permission_errors: {}
            };
        }
        case ADD_PERMISSION_SUCCESS: {
            return {
                ...state,
                add_permission_loading: false,
                add_permission_errors: {},
            };
        }
        case ADD_PERMISSION_FAILURE: {
            return {
                ...state,
                add_permission_loading: false,
                add_permission_errors: action.errors
            };
        }
        /** end::Create Permission redux */

        /** begin::Update Permission redux */
        case UPDATE_PERMISSION_REQUEST: {
            return {
                ...state,
                add_permission_loading: true,
                add_permission_errors: {}
            };
        }
        case UPDATE_PERMISSION_SUCCESS: {
            return {
                ...state,
                add_permission_loading: false,
                add_permission_errors: {},
            };
        }
        case UPDATE_PERMISSION_FAILURE: {
            return {
                ...state,
                add_permission_loading: false,
                add_permission_errors: action.errors
            };
        }
        /** end::Update Permission redux */

        /** begin::Change Permission status redux */
        case CHANGE_PERMISSION_STATUS_REQUEST: {
            return {
                ...state,
                change_permission_status_loading: true,
                change_permission_status_errors: {}
            };
        }
        case CHANGE_PERMISSION_STATUS_SUCCESS: {
            let newArray = [...state.permissions];
            const permissionIndex = newArray.findIndex(permissions => permissions.id === action.data.id);

            if (permissionIndex !== -1) {
                newArray[permissionIndex].status = action.data.status;
            }

            return {
                ...state,
                change_permission_status_loading: false,
                change_permission_status_errors: {},
                users: newArray
            };
        }
        case CHANGE_PERMISSION_STATUS_FAILURE: {
            return {
                ...state,
                change_permission_status_loading: false,
                change_permission_status_errors: action.errors
            };
        }
        /** end::Change Permission status redux */

        /** begin::Permissions select list redux */
        case PERMISSIONS_SELECT_LIST_REQUEST: {
            return {
                ...state,
                permissions_select_list_loading: true,
                permissions_select_list_errors: {}
            };
        }
        case PERMISSIONS_SELECT_LIST_SUCCESS: {

            return {
                ...state,
                permissions_select_list_loading: false,
                permissions_select_list_errors: {},
                permissions_select_list: action.data

            };

        }
        case PERMISSIONS_SELECT_LIST_FAILURE: {
            return {
                ...state,
                permissions_select_list_loading: false,
                permissions_select_list_errors: action.errors,
                permissions_select_list: []
            };
        }
        /** end::Permissions select list redux */

        /** begin::Permissions list redux */
        case GET_PERMISSIONS_REQUEST: {
            return {
                ...state,
                permissions_loading: true,
                permissions_errors: {}
            };
        }
        case GET_PERMISSIONS_SUCCESS: {
            let newArray = [...state.permissions]; //making a new array
            if (action.data.page === 1) {
                newArray = []
            }
            action.data.permissions.forEach((payload) => {
                const permissionIndex = newArray.findIndex(permissions => permissions.id === payload.id);
                if (permissionIndex !== -1) {
                    newArray[permissionIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                permissions_loading: false,
                permissions: action.data.permissions,
                permissions_errors: {},
                page: state.page,
                per_page: state.per_page,
                total: state.total,
                total_pages: state.total_pages
            };
        }
        case GET_PERMISSIONS_FAILURE: {
            return {
                ...state,
                permissions_loading: false,
                permissions: [],
                permissions_errors: action.errors
            };
        }
        /** end::Users list redux */

        default:
            return state;
    }
}
