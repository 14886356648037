import React, { useState, useEffect } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { PostAuthRoutes } from '../../routes';
import { GeneralButton } from '../../_components/controls/general-button/GeneralButton';
import { FilterAlt } from '@mui/icons-material';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { PayslipFilter } from './payslip-filter';
import { PayslipCard } from '.'
import jsPDF from 'jspdf';
import { Download } from '@mui/icons-material';
import { SalaryLogAction } from '../../redux/actions'
import { PageHeader } from '../../_components';
import autoTable from 'jspdf-autotable'
import { AbilityCan } from '../../_helpers/permission/AbilityCan';

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
]; // Declare monthNames array

function Payslip(params) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    /** Redux actions and state */
    const { user_loading: isLoading, user } = useSelector((state) => state.UserReducer);
    const { salary_log_loading: isSalaryLogLoading, salary_log } = useSelector((state) => state.SalaryLogReducer);
    const listSalaryLog = (params) => dispatch(SalaryLogAction.listSalaryLog(params));

    let maxSalaryArray = salary_log;
    let firstDate = '';

    if (salary_log.length > 0) {
        firstDate = 0
        for (let i = 0; i < salary_log.length; i++) {
            let maxDate = new Date(salary_log[i].created_at)

            if (maxDate > firstDate) {
                firstDate = maxDate
                maxSalaryArray = salary_log[i]
            }
        }
    } // get latest salary_log data

    let joining_date_array = [];
    let joining_date_month = '';
    let created_date_array = [];
    let created_date_month = '';

    if (user.joining_date) {
        joining_date_array = user.joining_date.split('-')
        let join_d = new Date(user.joining_date)
        joining_date_month = monthNames[join_d.getMonth()]
    } // get users joining date month

    if (maxSalaryArray.created_at) {
        created_date_array = maxSalaryArray.created_at.split('-')
        let created_d = new Date(maxSalaryArray.created_at)
        created_date_month = monthNames[created_d.getMonth()]
    } // get latest salary_log created date month

    const { designationDetails } = user
    const { salaryDetails } = user

    /** Initialize and declare state */
    const [showPayslipFilterModal, setShowPayslipFilterModal] = useState(false);
    const [payload, setPayload] = useState({
        limit: 100, page: 1,
        filter: { date: searchParams.get('date') ?? '' }
    });

    useEffect(() => {
        const queryParam = { ...payload.filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });
        queryParam.p = payload.page;
        if (payload.filter.date) queryParam.date = payload.filter.date; else delete queryParam.date;
        let pageUrl = `${PostAuthRoutes('payslip').path}?${queryString.stringify(queryParam)}`;

        window.history.pushState({}, '', pageUrl);
        getNext();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter, payload.search])


    /**
     * function to list salary log
     * @return view
     * @author Naveen
     * @created_at 21 Sept 2022
     */
    const getNext = async () => {
        await listSalaryLog(payload);
    }

    /**
     * function to open/close Payslip filter modal
     * @param {string} null 
     * @return view
     * @author Naveen
     * @created_at 21 Sept 2022
     */
    const payslipFilterModal = () => {
        setShowPayslipFilterModal(!showPayslipFilterModal);
    }

    /**
     * function to apply the selected filter options on the Payslip list
     * @param {Object} filterData 
     * @param {String} filterData.status Selected status from the Payslip filter modal
     * @return view
     * @author Naveen
     * @created_at 21 Sept 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData });
    }

    /**
     * Function to Download Payslip
     * @param {string} null 
     * @return view
     * @author Naveen
     * @created_at 21 Sept 2022
     */
    const payslipDownload = () => {
        var doc = new jsPDF('landscape', 'px', 'a4', 'false');
        doc.setFont('Helvertica', 'bold')
        doc.text(230, 30, `Payslip for ${created_date_month}, ${created_date_array[0]}`)
        doc.text(60, 90, `Name:${user.first_name}`)
        doc.text(60, 110, `Designation: ${designationDetails.designation}`)
        doc.text(60, 130, `Mobile: ${user.mobile}`)
        doc.text(60, 150, `Email: ${user.email}`)

        doc.text(400, 90, `CTC: ${salaryDetails.basic_salary}`)
        doc.text(400, 110, `Address:${user.address}, ${user.city}-${user.pin}`)
        doc.text(400, 130, `Joining Date: ${joining_date_month}, ${joining_date_array[0]}`)
        doc.text(400, 150, `Bank Name: ${user.bank_name}`)

        doc.autoTable({
            theme: 'grid',
            margin: { top: 200 },
            head: [["Earning", "Amount", "Deduction", "Amount"]],
            body: [["Basic", `${maxSalaryArray.basic_salary}`, "Monthly Tax Deduction", `${maxSalaryArray.tax_deduction_at_source}`,],
            ["HRA", `${maxSalaryArray.hra}`, " ", " "],
            ["Total Earnings", `${maxSalaryArray.basic_salary + maxSalaryArray.hra}`, "Total Deductions", `${maxSalaryArray.tax_deduction_at_source}`],
            [" ", " ", "Net Pay", `${maxSalaryArray.net_salary}`]
            ]
        }) // Create DataTable 

        doc.text(60, 380, `Employer Signature`)
        doc.text(400, 380, `Employee Signature`)
        doc.setFontSize(14);
        doc.text(60, 400, `HR`)
        doc.text(400, 400, `${user.first_name}`)

        doc.save(`${user.first_name} GeneratedPayslip.pdf`)
    }

    return (
        <React.Fragment>
            <Box>
                <PageHeader
                    title={PostAuthRoutes('payslip').name}
                    action={
                        <Stack spacing={1} direction="row" alignItems={`center`}>
                            <AbilityCan I='pay_slip_download' passThrough={(user.role === 'developer') ? true : false}>
                                {salary_log.length > 0 && <GeneralButton label={<Download />} onClick={() => payslipDownload()} />}
                            </AbilityCan>
                            <AbilityCan I='pay_slip_filter' passThrough={(user.role === 'developer') ? true : false}>
                                <GeneralButton label={<FilterAlt />} onClick={() => payslipFilterModal()} />
                            </AbilityCan>
                        </Stack>
                    }
                />
                <PayslipFilter show={showPayslipFilterModal} applyFilter={applyFilter} closeModal={payslipFilterModal} payslipFilter={payload.filter} />
                <Grid container spacing={3} >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <PayslipCard filter={payload.filter} />
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    )
}

export { Payslip }