import { apiServices } from "../../_helpers";

export const StaffSkillServices = {
  /**
     * Service for create staff skill
     * @param {Object} params - The params which are used for the api
     * @author Naveen  
     * @created_at 07 Mar 2023
     */
  createStaffSkill: params => {
    return apiServices
      .post("/agent/staff-skills/create", params)
      .then(response => {
        return response;
      });
  },

  /**
     * Service to List Staff Skills
     * @param {Object} params - The params which are used for the api
     * @author Naveen  
     * @created_at 07 Mar 2023
    */
  listStaffSkills: params => {
    return apiServices.post("agent/staff-skills", params).then(response => {
      return response;
    });
  },

  /**
     * Service for deleting an staff skill
     * @param {Object} params - The params which are used for the api
     * @author Naveen  
     * @created_at 07 Mar 2023
    */
  deleteStaffSkill: params => {
    return apiServices
      .put("agent/staff-skills/delete", params)
      .then(response => {
        return response;
      });
  }
};
