import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card, CardContent } from "../../../../_components/card";
import { PageHeader } from "../../../../_components";
import { GeneralButton } from "../../../../_components/controls/general-button/GeneralButton";
import { PostAuthRoutes } from "../../../../routes";
import { StaffSkillAction } from "../../../../redux/actions";
import { Box } from "@mui/system";
import { Cancel, DeleteForeverRounded } from "@mui/icons-material";
import { Typography, Stack, TextField, Grid, Button } from "@mui/material";

const Tags = ({ data, handleDelete }) => {
  return (
    <Box
      sx={{
        background: "#283240",
        height: "100%",
        display: "flex",
        padding: "0.4rem",
        margin: "0 0.5rem 0 0",
        justifyContent: "center",
        alignContent: "center",
        color: "#ffffff"
      }}
    >
      <Stack direction="row" gap={1}>
        <Typography>
          {data}
        </Typography>
        <Cancel sx={{ cursor: "pointer" }} onClick={() => handleDelete(data)} />
      </Stack>
    </Box>
  );
};

function InputTags() {
  /** Initialize plugins and variables */
  const dispatch = useDispatch();
  const history = useHistory();

  /** Redux actions and state */
  const { skill_loading: isSkillsLoading, staff_skills } = useSelector(
    state => state.StaffSkillReducer
  );
  const listStaffSkills = params =>
    dispatch(StaffSkillAction.listStaffSkills(params));
  const createStaffSkill = params =>
    dispatch(StaffSkillAction.createStaffSkill(params));
  const deleteStaffSkill = params =>
    dispatch(StaffSkillAction.deleteStaffSkill(params));

  const [tags, SetTags] = useState([]);
  const tagRef = useRef();

  useEffect(() => {
    listStaffSkills();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //HandleSubmit
  const handleOnSubmit = e => {
    e.preventDefault();
    SetTags([...tags, tagRef.current.value]);
    tagRef.current.value = "";
  };

  const handleDelete = value => {
    const newtags = tags.filter(val => val !== value);
    SetTags(newtags);
  };

  /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author Naveen  
     * @created_at 07 Mar 2023
     */
  function handleSubmit(e) {
    const formData = {
      skill: tags
    };

    addSkillRequest(formData);
  }

  /**
   * function to create Staff Skill
   * @param {object} formData form object
   * @author Naveen
   * @created_at 07 Mar 2023
  */
  async function addSkillRequest(formData) {
    const isCreated = await createStaffSkill(formData);

    if (isCreated && isCreated.status === 1) {
      SetTags([]);
      let redirectPath = PostAuthRoutes("profile_details").path;
      history.push(redirectPath);
    }
  }

  /**
 * function to Delete staff skills
 * @return view
 * @author Naveen
 * @created_at 07 Mar 2023
*/
  function deleteSkill(id) {
    deleteStaffSkill({ skill_id: id });
  }

  return (
    <form onSubmit={handleOnSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card sx={{ marginTop: "10px" }}>
            <PageHeader title="Key Skills" />
            <CardContent>
              <Stack spacing={1} direction="row">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box sx={{ flexGrow: 1 }}>
                    <TextField
                      inputRef={tagRef}
                      fullWidth
                      variant="standard"
                      size="small"
                      sx={{ margin: "1rem 0" }}
                      margin="none"
                      placeholder={tags.length < 5 ? "Enter tags" : ""}
                      InputProps={{
                        startAdornment: (
                          <Box sx={{ margin: "0 0.2rem 0 0", display: "flex" }}>
                            {tags.map((data, index) => {
                              return (
                                <Tags
                                  data={data}
                                  handleDelete={handleDelete}
                                  key={index}
                                />
                              );
                            })}
                          </Box>
                        )
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={2}>
                  <GeneralButton
                    label={<React.Fragment>Submit</React.Fragment>}
                    onClick={() => handleSubmit()}
                  />
                </Grid>
              </Stack>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {staff_skills.length > 0 &&
                  staff_skills.map((skill, i) => {
                    return (
                      <Grid item xs={2} sm={2} md={2} lg={2} xl={2} key={i}>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{
                            width: "100%",
                            height: 30,
                            border: "1px solid grey",
                            borderRadius: "5px",
                            backgroundColor: "#E8E8E8"
                          }}
                        >
                          <Typography variant="body1">
                            &nbsp;{skill.skill_name}
                          </Typography>
                          <Button
                            sx={{ margin: 0, padding: 0 }}
                            onClick={() => deleteSkill(skill.id)}
                          >
                            {" "}<DeleteForeverRounded />
                          </Button>
                        </Box>
                      </Grid>
                    );
                  })}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
}

export { InputTags };
