import React from 'react';
import { Box, CardContent, Grid, Skeleton, Divider } from '@mui/material';

function PayslipCardLoader({ ...props }) {

    var rows = [];
    for (var i = 0; i < 1; i++) {
        rows.push(
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={i} style={{ paddingTop: '10px', height: `450px` }} {...props}>
                <CardContent style={{ position: "relative", height: `350px`, top: 0, right: 0, left: 0, bottom: 0 }}>
                    <Grid container spacing={0} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={6} style={{ paddingTop: 0, margin: 0, width: "210px" }} >
                            <Box style={{ paddingTop: 0, minHeight: `120px` }}>
                                <Skeleton animation="wave" height={30} width="50%" style={{ marginBottom: 5 }} />
                                <Skeleton animation="wave" height={30} width="50%" style={{ marginBottom: 5 }} />
                                <Skeleton animation="wave" height={30} width="50%" style={{ marginBottom: 5 }} />
                                <Skeleton animation="wave" height={30} width="50%" style={{ marginBottom: 5 }} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={6} >
                            <Box style={{ paddingTop: 0, minHeight: `120px` }}>
                                <Skeleton animation="wave" height={30} width="50%" style={{ marginBottom: 5 }} />
                                <Skeleton animation="wave" height={30} width="50%" style={{ marginBottom: 5 }} />
                                <Skeleton animation="wave" height={30} width="50%" style={{ marginBottom: 5 }} />
                                <Skeleton animation="wave" height={30} width="50%" style={{ marginBottom: 5 }} />
                            </Box>
                        </Grid>
                        <Divider />
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                            <Box style={{ paddingTop: 0, minHeight: `120px` }}>
                                <Skeleton animation="wave" height={300} width="100%" style={{ marginBottom: 5 }} />
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Grid>
        )
    }

    return <React.Fragment> {rows} </React.Fragment>
}

export { PayslipCardLoader };