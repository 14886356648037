import { MoreVert } from '@mui/icons-material'
import { Box, CardHeader, IconButton, Menu, MenuItem, Grid, Typography, Stack } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from "react-redux";
import { useHistory } from 'react-router'
import { PostAuthRoutes } from '../../../routes'
import { Card, CardContent } from '../../../_components/card'
import { AbilityCan } from '../../../_helpers/permission/AbilityCan';

function StaffExperienceCard({ staff_experiences, staffExperienceDeleteModal }) {

    /** Initialize values from staff experience props */
    const { id } = staff_experiences;

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);

    /** Initialize and declare state */
    const [statusData, setStatusData] = useState({ id: id });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    let history = useHistory();

    let joining_date_array = [];
    let resigning_date_array = [];
    if (staff_experiences && staff_experiences.joining_date) {
        joining_date_array = staff_experiences.joining_date.split('-')
    }

    if (staff_experiences && staff_experiences.resigning_date) {
        resigning_date_array = staff_experiences.resigning_date.split('-')
    }

    /**
     * function to handle click menu
     * @param {object} event form object
     * @author Naveen
     * @created_at 21 Sept 2022 
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * function to handle close menu
     * @param {string} null
     * @author Naveen
     * @created_at 21 Sept 2022 
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * function to delete Staff Experience 
     * @param {string} null
     * @author Naveen
     * @created_at 21 Sept 2022 
     */
    function handleStaffExperienceDeleteClick() {
        setAnchorEl(null);
        setTimeout(() => {
            staffExperienceDeleteModal(statusData);
        }, 100);
    }

    /**
     * function to update Staff Experience 
     * @param {string} null
     * @author Naveen
     * @created_at 21 Sept 2022 
     */
    function handleUpdateClick() {
        setAnchorEl(null);
        history.push({
            pathname: PostAuthRoutes('update_staff_experience').path,
            state: { staff_experiences: staff_experiences }
        });
    }

    return (
        <Card>
            <CardHeader
                title={staff_experiences.designation}
                action={
                    <React.Fragment>
                        <IconButton aria-label="more" id="long-button" aria-controls={open ? 'long-menu' : undefined} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" onClick={handleClick} ><MoreVert /></IconButton>
                        <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button' }} anchorEl={anchorEl} open={open} onClose={handleClose}  >
                            <AbilityCan I='update_staff_experience' passThrough={(user.role === 'developer') ? true : false}>
                                <MenuItem onClick={handleUpdateClick}>{`Update`}</MenuItem>
                            </AbilityCan>
                            <AbilityCan I='delete_staff_experience' passThrough={(user.role === 'developer') ? true : false}>
                                <MenuItem onClick={handleStaffExperienceDeleteClick}>{`Delete`}</MenuItem>
                            </AbilityCan>
                        </Menu>
                    </React.Fragment>
                } />
            <CardContent>
                <Grid container spacing={3} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={12} >

                        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ padding: '4px', borderRadius: '10px', marginBottom: '10px' }} spacing={2}>
                            <Box display="flex" justifyContent="space-between" alignItems="center" >
                                <Typography variant="h6"> {`Organization : `}</Typography>
                                <Typography variant="body1" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>&nbsp;{staff_experiences.organization}</Typography>
                            </Box>
                        </Stack>

                        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ padding: '4px', borderRadius: '10px', marginBottom: '10px' }} spacing={2}>
                            <Box display="flex" justifyContent="space-between" alignItems="center" >
                                <Typography variant="h6"> {`Joining Date : `}</Typography>
                                <Typography variant="body1" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>&nbsp;{`${joining_date_array[2].slice(0, 2)}/${joining_date_array[1]}/${joining_date_array[0]}`}</Typography>
                            </Box>
                        </Stack>

                        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ padding: '4px', borderRadius: '10px', marginBottom: '10px' }} spacing={2}>
                            <Box display="flex" justifyContent="space-between" alignItems="center" >
                                <Typography variant="h6"> {`Resigning Date : `}</Typography>
                                <Typography variant="body1" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>&nbsp;{`${resigning_date_array[2].slice(0, 2)}/${resigning_date_array[1]}/${resigning_date_array[0]}`}</Typography>
                            </Box>
                        </Stack>

                        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ padding: '4px', borderRadius: '10px', marginBottom: '10px' }} spacing={2}>
                            <Box display="flex" justifyContent="space-between" alignItems="center" >
                                <Typography variant="h6"> {`CTC : `}</Typography>
                                <Typography variant="body1" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>&nbsp;{staff_experiences.ctc}</Typography>
                            </Box>
                        </Stack>

                        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ padding: '4px', borderRadius: '10px', marginBottom: '10px' }} spacing={2}>
                            <Box display="flex" justifyContent="space-between" alignItems="center" >
                                <Typography variant="h6"> {`Location : `}</Typography>
                                <Typography variant="body1" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>&nbsp;{staff_experiences.location}</Typography>
                            </Box>
                        </Stack>

                        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ padding: '4px', borderRadius: '10px', marginBottom: '10px' }} spacing={2} >
                            <Box display="flex" justifyContent="space-between" alignItems="left" >
                                <Typography variant="h6"> {`Remark: `}</Typography>
                                <Typography variant="body1" align='left' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center`, paddingLeft: '10px' }}>&nbsp;{staff_experiences.remark}</Typography>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )

}

export { StaffExperienceCard }