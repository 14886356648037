module.exports = {
    /**
     * function to validate the registration form inputs
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author
     */
    validate: (name, value, data = {}) => {

        //eslint-disable-next-line
        const errors = {};
        switch (name) {
            case 'leave_type': {
                errors.leave_type = '';
                if (value === '')
                    errors.leave_type = 'Select Leave Type.';
                break;
            } case 'date': {
                errors.date = '';
                if (value === '')
                    errors.date = 'Select Date.';
                break;
            } case 'reason': {
                errors.reason = '';
                if (value === '')
                    errors.reason = 'Select Reason.';
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
}