import { useEffect, useMemo, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';

import { APP_NAME } from './_helpers';
import { AppNameContext } from './_contexts';
import { PreAuthMainComponents, PrivateRoute } from './routes';
import { GlobalAction } from './redux/actions';

import { PostSignIn } from './_layouts/post-signin';
import { PageLoader, InitialLoader, NotFound, Toast } from './_components';
import { CssBaseline, useMediaQuery } from '@mui/material';
import { ThemeProvider } from '@mui/system';

import themeLight from './_theme/theme-light'
import themeDark from './_theme/theme-dark'
import './App.scss';

function App() {

	/** Initialize plugins and variables */
	const menuRoutes = Object.values(PreAuthMainComponents());
	const dispatch = useDispatch();

	/** Redux actions and state */
	const { pageLoader, initialLoader, toastMessage: toast } = useSelector((state) => state.GlobalReducer);

	/** Initialize and declare state */
	const [isDarkMode, setIsDarkMode] = useState(false)

	useEffect(() => {
		dispatch(GlobalAction.initialLoader(false))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

	useMemo(
		() => setIsDarkMode(prefersDarkMode),
		[prefersDarkMode],
	);

	return (
		<AppNameContext.Provider value={APP_NAME}>
			<HelmetProvider>
				<ThemeProvider theme={isDarkMode ? themeDark : themeLight}>
					<CssBaseline />
					<Switch>
						{menuRoutes.map((route, index) => (
							<Route exact key={index} path={route.path} component={route.component} />
						))}
						<PrivateRoute path='/' component={PostSignIn}></PrivateRoute>
						<Route component={NotFound} />
					</Switch>
					<PageLoader loader={pageLoader} />
					<InitialLoader loader={initialLoader} />
					<Toast message={toast} />
				</ThemeProvider>
			</HelmetProvider>
		</AppNameContext.Provider>
	);
}

export { App };
