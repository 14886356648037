/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React Base Styles
import colors from "../../base/colors";
import borders from "../../base/borders";
import typography from "../../base/typography";

// // Soft UI Dashboard PRO helper functions
import pxToRem from "../../../functions/pxToRem";
import rgba from "../../../functions/rgba";

const { transparent, text } = colors;
const { borderRadius, borderWidth } = borders;
const { size } = typography;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  styleOverrides: {
    root: {
      backgroundColor: transparent.main,
      fontSize: size.sm,
      borderRadius: borderRadius.md,

      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: text.primary,
      },

      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          border: `${borderWidth[1]} solid ${rgba(text.primary, 0.6)}`,
          borderColor: text.primary,
        },
      },
    },

    notchedOutline: {
      borderColor: text.dark,
    },

    input: {
      color: text.main,
      padding: pxToRem(12),
      backgroundColor: transparent.main,
    },

    inputSizeSmall: {
      fontSize: size.xs,
      padding: pxToRem(10),
    },

    multiline: {
      color: text.main,
      padding: 0,
    },
  },
};
