import { Box, Card, CardContent, Grid, Stack } from "@mui/material"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from 'react-router';
import { LeavesAction, AttendanceAction } from "../../redux/actions"
import { PostAuthRoutes } from "../../routes"
import { PageHeader } from "../../_components"
import { Calendar } from "../../_components/calendar"
import { GeneralButton } from '../../_components/controls/general-button/GeneralButton';
import { AbilityCan } from '../../_helpers/permission/AbilityCan';

function StaffCalendar() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let history = useHistory();

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);
    const { calendar_approved_leaves } = useSelector((state) => state.LeavesReducer);
    const { calendar_staff_attendance } = useSelector((state) => state.AttendanceReducer);
    const getAllApprovedLeaves = (params) => dispatch(LeavesAction.getAllApprovedLeaves(params));
    const getAllStaffAttendance = (params) => dispatch(AttendanceAction.getAllStaffAttendance(params));

    useEffect(() => {
        getAllApprovedLeaves();
        getAllStaffAttendance();
    }, [])

    /**
     * function to create Attendance Request
     * @param {string} null 
     * @return view
     * @author Naveen
     * @created_at 10 Oct 2022
     */
    function createAttendanceRequest() {
        history.push({
            pathname: PostAuthRoutes('create_attendance').path,
        });
    }

    return (
        <React.Fragment>
            <Box>
                <PageHeader
                    title={PostAuthRoutes('staff_calendar').name}
                    action={
                        <Stack spacing={1} direction="row" alignItems={`center`}>
                            <AbilityCan I='staff_calendar_mark_attendance' passThrough={(user.role === 'developer') ? true : false}>
                                <GeneralButton label={<React.Fragment>Mark Attendance</React.Fragment>} onClick={() => createAttendanceRequest()} />
                            </AbilityCan>
                        </Stack>
                    }
                />
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Card>
                            <CardContent>
                                <Calendar events={calendar_staff_attendance} />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>

    )

}

export { StaffCalendar }