/**
 =========================================================
 * Akshay's Template - v0.0.0
 =========================================================
 *
 * Created by Akshay N on 01 January 2022
 */

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {
    disableRipple: false,
  },
};
