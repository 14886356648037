import { apiServices } from '../../_helpers';

export const StaffExperienceServices = {

    /**
     * Service for create staff experiences
     * @param {Object} params - The params which are used for the api
     * @author Naveen  
     * @created_at 21 September 2022
     */
    createStaffExperience: (params) => {
        return apiServices.post('/agent/staff_experiences/create', params)
            .then((response) => { return response; })
    },

    /**
     * Service to get all Staff Experiences
     * @param {Object} params - The params which are used for the api
     * @author Naveen  
     * @created_at 21 September 2022
    */
    listStaffExperience: (params) => {
        return apiServices.post('agent/staff_experiences', params)
            .then((response) => { return response; })
    },

    /**
     * Service for update staff experience
     * @param {Object} params - The params which are used for the api
     * @author Naveen  
     * @created_at 21 September 2022
    */
    updateStaffExperience: (params) => {
        return apiServices.put('agent/staff_experiences/update', params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => { return response; })
    },

    /**
     * Service for deleting an staff experience
     * @param {Object} params - The params which are used for the api
     * @author Naveen  
     * @created_at 21 September 2022
    */
    deleteStaffExperience: (params) => {
        return apiServices.put('agent/staff_experiences/delete', params)
            .then((response) => { return response; })
    },

}