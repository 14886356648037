export const APP_NAME = process.env.REACT_APP_APP_NAME ?? '';

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const API_REQUEST_TIMEOUT = 10000;

export const URL_PRIVACY_POLICY = 'https://confyans.com';
export const URL_TERMS_OF_SERVICE = 'https://confyans.com';

export const AVATAR_BROKEN_IMAGE = 'https://ad-run-digital.s3.amazonaws.com/default/avatar-broken.jpg';


export const DRAWER_WIDTH = 240;