import { INITIAL_LOADER, PAGE_LOADER, SET_DRAWER, SET_TOAST } from '../actions';

const initialState = { initialLoader: true, pageLoader: false, isDrawerOpen: ((localStorage.getItem('is_drawer_open') && (localStorage.getItem('is_drawer_open') === 'true')) ? true : false), toastMessage: null };

export function GlobalReducer(state = initialState, action) {
    switch (action.type) {
        case INITIAL_LOADER:
            return {
                ...state,
                initialLoader: action.loader,
            };
        case PAGE_LOADER:
            return {
                ...state,
                pageLoader: action.loader,
            };
        case SET_DRAWER:
            return {
                ...state,
                isDrawerOpen: action.isOpen,
            };
        case SET_TOAST:
            return {
                ...state,
                toastMessage: action.message,
            };
        default:
            return state;
    }
}

