import { AccessTime } from "@mui/icons-material";
import { Avatar, Box, Card, CardContent, Stack, Typography } from "@mui/material";


function ActivityLogCard({ activity_log }) {

    /** Initialize values from activity_log props */
    const { name, profile_picture_url, created_at, description } = activity_log;

    return (
        <Card>
            <CardContent sx={{ mt: 2 }}>
                <Stack spacing={2} direction="row" justifyContent="left" alignItems="center">
                    <Avatar src={profile_picture_url} alt={name} />
                    <Box>
                        <Typography variant="body2">{`${name} ${description}`}</Typography>
                        <Typography variant="body2" sx={{ fontSize: 10 }}> <AccessTime /> {created_at}</Typography>
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    )

}

export { ActivityLogCard }