import { Email, Phone, SupportAgent, MoreVert } from '@mui/icons-material'
import { Box, CardHeader, Grid, Typography, Stack, CardMedia, IconButton, Menu, MenuItem, Chip } from '@mui/material'
import Divider from "@mui/material/Divider";
import React, { useState, useEffect } from 'react'
import { Card, CardContent } from '../../../_components/card'

function PreviousCompaniesCard({ company, statusUpdateModal }) {

    /** Initialize values from brand props */
    const { id, status } = company;

    /** Initialize and declare state */
    const [statusData, setStatusData] = useState({ id: company.id, status: company.status });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);


    useEffect(() => {
        setStatusData({ id: id, status: status })
    }, [id, status])

    /**
     * function to handle click menu
     * @param {object} event form object
     * @author  
     * @created_at 14 June 2022 
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * function to handle close menu
     * @param {string} null
     * @author  
     * @created_at 14 June 2022 
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * function to change company status 
     * @param {string} null
     * @author  Naveen
     * @created_at 6 Nov 2022 
     */
    function handleStatusChangeClick() {
        setAnchorEl(null);
        setTimeout(() => {
            statusUpdateModal(statusData);
        }, 100);
    }

    return (
        <Card>
            <CardHeader
                title={company.company_name}
                action={
                    <Box>
                        <Stack direction="row" display={`flex`} alignItems={`center`} justifyContent={`center`} spacing={0}>
                            <Chip size='small' label={status === '1' ? 'Active' : 'Inactive'} color={status === '1' ? 'success' : 'error'} />
                            <IconButton aria-label="more" id="long-button" aria-controls={open ? 'long-menu' : undefined} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" onClick={handleClick} ><MoreVert /></IconButton>
                            <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button' }} anchorEl={anchorEl} open={open} onClose={handleClose}  >
                                <MenuItem onClick={handleStatusChangeClick}>{status === '1' ? 'Deactivate' : 'Activate'}</MenuItem>
                            </Menu>
                        </Stack>
                    </Box>
                }
            />
            <CardContent sx={{ pt: 0 }}>
                <Grid container spacing={3} display={`flex`} alignItems={`center`} justifyContent={`center`} >
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={12} >
                        <Stack direction="row" justifyContent="center" alignItems="center" sx={{ padding: '4px', borderRadius: '10px', marginBottom: '10px' }} spacing={2}>
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: `100px`, width: `50%` }} >
                                <CardMedia height={`100%`} component={"img"} />
                            </Box>
                        </Stack>
                        <Divider />
                        <Typography variant="h6" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}><Email />&nbsp;{`${company.company_email}`}</Typography>
                        <Typography variant="h6" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}><Phone />&nbsp;{`${company.company_phone}`}</Typography>
                        <Typography variant="h6" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}><SupportAgent />&nbsp;{`${company.company_representative}`}</Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export { PreviousCompaniesCard }