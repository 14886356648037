import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router';
import queryString from 'query-string';
import { Chip, Button, ButtonGroup } from '@mui/material';
import { DeleteForeverRounded } from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip';
import { DataTable } from '../../../_components/data-table/DataTable';
import { PostAuthRoutes } from '../../../routes';
import { LeavesAction } from '../../../redux/actions';
import { AbilityCan } from "../../../_helpers/permission/AbilityCan";

function LeavesDataTable({ leaveDeleteModal, filter, ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let history = useHistory();

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);
    const { per_page, total, leaves_loading: isLeavesLoading, leaves } = useSelector((state) => state.LeavesReducer);
    const listLeaves = (params) => dispatch(LeavesAction.listLeaves(params));

    /** Initialize and declare state */
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState({ '_id': 'asc' });
    const [payload, setPayload] = useState({ filter });

    const columns = [
        { name: "No", center: true, width: '6%', cell: (row, i) => ((per_page * (page - 1)) + (i + 1)) },
        { name: "Leave Type", selector: (row, i) => (row.leave_type), sortField: 'leave_type', sortable: true, center: true, width: '16%' },
        { name: "From Date", selector: (row, i) => (row.from_date), sortField: 'from_date', sortable: true, center: true, width: '16%', cell: row => (<FromDate row={row} />) },
        { name: "To Date", selector: (row, i) => (row.to_date), sortField: 'to_date', sortable: true, center: true, width: '16%', cell: row => (<ToDate row={row} />) },
        { name: "Reason", selector: (row, i) => (row.reason), sortField: 'reason', sortable: true, left: true, width: '34%' },
        { name: "Status", selector: (row, i) => (row.status), sortField: 'status', sortable: true, center: true, width: '12%', cell: row => (<StatusRow row={row} />) },
    ];

    /**
     * function to display status value on rows
     * @param {Object} row
     * @param {string} row.status - status values on the row
     * @return json
     * @author Naveen
     * @created_at 21 sept 2022 
     */
    const StatusRow = ({ row }) => {

        function deleteLeaveRequest(row) {
            setTimeout(() => {
                leaveDeleteModal({ id: row.id });
            }, 100);
        }

        let status = <Chip label={row.status} className="badge-status capitalize" />;
        if (row.status === 'requested')
            status = <Chip label={row.status[0].toUpperCase() + row.status.substring(1)} color="success" className="badge-status capitalize" />;
        else if (row.status === 'approved')
            status = <Chip label={row.status[0].toUpperCase() + row.status.substring(1)} color="error" className="badge-status capitalize" />;
        
        let statusHover = (
            <ButtonGroup variant="text" size="small" className='row-action'>
                <AbilityCan I='leaves_delete' passThrough={(user.role === 'developer') ? true : false}>
                    <Tooltip title="Delete">
                        <Button onClick={() => deleteLeaveRequest(row)}> <DeleteForeverRounded /></Button>
                    </Tooltip>
                </AbilityCan>
            </ButtonGroup>
        )
        if (row.status === 'approved')
            statusHover= "";
        return <React.Fragment>{status}{statusHover}</React.Fragment>;

    }

    /**
     * function to display from date value on rows
     * @param {Object} row
     * @param {string} row.status - status values on the row
     * @return json
     * @author Naveen
     * @created_at 21 sept 2022 
     */
    const FromDate = ({ row }) => {

        const from_date_array = row.from_date.split('-')
        const row_from_date = `${from_date_array[2].slice(0, 2)}/${from_date_array[1]}/${from_date_array[0]}`

        let from_date = <Chip label={row_from_date} className="badge-status capitalize" />;

        return <React.Fragment>{from_date}</React.Fragment>;
    }

    /**
     * function to display to date value on rows
     * @param {Object} row
     * @param {string} row.status - status values on the row
     * @return json
     * @author Naveen
     * @created_at 21 sept 2022 
     */
    const ToDate = ({ row }) => {

        const to_date_array = row.to_date.split('-')
        const row_to_date = `${to_date_array[2].slice(0, 2)}/${to_date_array[1]}/${to_date_array[0]}`

        let to_date = <Chip label={row_to_date} className="badge-status capitalize" />;

        return <React.Fragment>{to_date}</React.Fragment>;
    }

    useEffect(() => {
        setPayload({ filter, sort });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])

    useEffect(() => {

        const queryParam = { ...filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });

        queryParam.p = page;
        if (payload.filter.date) queryParam.date = payload.filter.date; else delete queryParam.date;
        // if (filter.status) queryParam.status = filter.status; else delete queryParam.status;
        history.push({
            pathname: PostAuthRoutes('leave').path,
            search: `?${queryString.stringify(queryParam)}`
        });
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter])

    /**
     * function to list salary log
     * @return view
     * @author Naveen
     * @created_at 21 Sept 2022
     */
    const getData = async () => {
        await listLeaves(payload);
    }

    // useEffect(() => {
    //     listLeaves();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    /**
     * function to sort columns
     * @return view
     * @author Naveen
     * @created_at 21 sept 2022
     */
    const handleSort = (column, sortDirection) => {
        const sortField = column.sortField;
        setSort({ [sortField]: sortDirection })
    }

    /**
     * function to select columns
     * @return view
     * @author Naveen
     * @created_at 21 sept 2022
     */
    const handleSelect = (data) => {
        console.log(data.selectedRows)
    }

    return (
        <DataTable
            loading={isLeavesLoading}
            columns={columns}
            data={leaves}
            total={total}
            per_page={per_page}
            setPage={page => setPage(page)}
            handleSelect={handleSelect}
            handleSort={handleSort}
        />
    )
}

export { LeavesDataTable }