import { Card, CardContent, CardMedia, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalFooterCloseBtn, ModalFooterSaveBtn } from '../'
import questinMarkSvg from './question-mark.svg'

function DeleteModal({ show, showLoader, data, closeModal, deleteModal, ...props }) {

    const [action] = useState({ isProcessing: false, isSubmitted: false });

    const onClose = () => {
        closeModal(false);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        deleteModal(data.id);
    }

    return (
        <Modal isDialogOpen={show} onClose={onClose} loader={showLoader} maxWidth="sm" >
            <Card style={{ boxShadow: `none` }}>
                <CardMedia component="img" image={questinMarkSvg} style={{ position: "absolute", padding: 0, bottom: 0, right: 0, top: 0, width: "60%", opacity: '0.1' }} />
                <form onSubmit={handleSubmit} noValidate className='ns-form' >
                    <ModalBody style={{ padding: 0 }}>
                        <CardContent style={{ display: `flex`, alignItems: `center`, paddingBottom: 0 }}>
                            <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" style={{ minHeight: `165px` }}>
                                <Typography variant='h3' paddingBottom={1}>Are You Sure?</Typography>
                                <Typography variant='body2'>Do you want to cancel this leave request ?</Typography>
                            </Stack>
                        </CardContent>
                    </ModalBody>
                    <ModalFooter>
                        <Box style={{ width: `100%` }}>
                            <Stack direction="row" justifyContent="left" alignItems="center" spacing={1}>
                                <ModalFooterSaveBtn tabIndex={3} text={`Yes`} isProcessing={action.isProcessing} />
                                <ModalFooterCloseBtn tabIndex={4} text={`Cancel`} onClose={onClose} />
                            </Stack>
                        </Box>
                    </ModalFooter>
                </form>
            </Card>
        </Modal>
    )
}

export { DeleteModal }