import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { PostAuthRoutes } from '../../../routes'
import { Stack, Typography, Grid, Avatar } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader, NoData } from '../../../_components';
import { Box } from '../../../_components/box';
import { GeneralButton } from '../../../_components/controls'
import { Card, CardContent } from '../../../_components/card'
import { DashboardAction } from '../../../redux/actions';
import { AbilityCan } from '../../../_helpers/permission/AbilityCan';
import { PreviousCompaniesCardLoader } from '.'

function PreviousCompanies() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let history = useHistory();

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);
    const { companies_loading: isCompaniesLoading, companies } = useSelector((state) => state.DashboardReducer);
    const companiesList = (params) => dispatch(DashboardAction.companiesList(params));

    /**
     * function direct to previous companies display page
     * @param {string} null 
     * @return view
     * @author
     * @created_at 2 Nov 2022
     */
    function previousCompanies() {
        history.push({
            pathname: PostAuthRoutes('previous_companies').path,
        });
    }

    useEffect(() => {
        companiesList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <React.Fragment>
            <Box>
                <PageHeader
                    title={PostAuthRoutes('previous_companies').name}
                    action={
                        <Stack spacing={1} direction="row">
                            <GeneralButton label={<React.Fragment>View All</React.Fragment>} onClick={() => previousCompanies()} />
                        </Stack>
                    }
                />
                <Card>
                    <CardContent>
                        <Grid container spacing={3} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                            <Grid item xs={12} sm={12} md={8} lg={12} xl={12} >
                                {companies.length > 0 && companies.map((company, i) => {
                                    return (
                                        <Grid container spacing={0} >
                                            <Grid item xs={12} sm={12} md={4} lg={4} xl={12}>

                                                <Stack direction="row" justifyContent="left" alignItems="center" spacing={1} padding='10px'>
                                                    <Box display="flex" justifyContent="center" alignItems="center" >
                                                        <Avatar sx={{ width: 40, height: 40 }}></Avatar>
                                                    </Box>
                                                    <Box display="flex" justifyContent="space-between" alignItems="left" flexDirection='column'>
                                                        {/* <Typography variant="body1" >&nbsp;is on leave Today</Typography> */}
                                                        <Typography variant="h5">{company.company_name}</Typography>
                                                        <Typography component="h1" variant="caption" >{company.company_email}</Typography>
                                                    </Box>
                                                </Stack>

                                            </Grid>
                                        </Grid>
                                    );
                                })
                                }
                                {!isCompaniesLoading && companies.length === 0 && <NoData content1={`No`} content2={`Pervious Companies`} description={`Stay tuned! Notifications about your activity will show up here.`} minHeight={`33vh`} ></NoData>}
                                {isCompaniesLoading && < PreviousCompaniesCardLoader />}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </React.Fragment>
    )
}

export { PreviousCompanies }