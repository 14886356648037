import { apiServices } from '../../_helpers';

export const SalaryLogServices = {
    /**
     * Service to get all Salary Logs
     * @param {Object} params - The params which are used for the api
     * @author Naveen  
     * @created_at 21 September 2022
    */
    listSalaryLog: (params) => {
        return apiServices.post('agent/salary_log', params)
            .then((response) => { return response; })
    },
}