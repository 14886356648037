import {
    CHANGE_COMPANIES_STATUS_REQUEST, CHANGE_COMPANIES_STATUS_SUCCESS, CHANGE_COMPANIES_STATUS_FAILURE,
    COMPANIES_LIST_REQUEST, COMPANIES_LIST_SUCCESS, COMPANIES_LIST_FAILURE,
} from "../actions";

const initialState = {
    companies_loading: false, companies: [], companies_errors: {},
    change_companies_status_loading: false, change_companies_status_errors: {},
    page: 1, per_page: 18, total: 0, total_pages: 0,
};

export function CompaniesReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::pervious companies redux */
        case COMPANIES_LIST_REQUEST: {
            return {
                ...state,
                companies_loading: true,
                companies_errors: {}
            };
        }
        case COMPANIES_LIST_SUCCESS: {
            return {
                ...state,
                companies_loading: false,
                companies: action.data,
                companies_errors: {}
            };
        }
        case COMPANIES_LIST_FAILURE: {
            return {
                ...state,
                companies_loading: false,
                companies_errors: action.errors
            };
        }
        /** end::pervious companies redux */

        /** begin::Change companies status redux */
        case CHANGE_COMPANIES_STATUS_REQUEST: {
            return {
                ...state,
                change_companies_status_loading: true,
                change_companies_status_errors: {}
            };
        }
        case CHANGE_COMPANIES_STATUS_SUCCESS: {

            let companiesArray = [...state.companies]; //making a new array
            if (action.data.page === 1) {
                companiesArray = []
            }

            const companiesIndex = companiesArray.findIndex(companies => companies.id === action.data.id);
            if (companiesIndex !== -1) {
                companiesArray[companiesIndex] = action.data;
            } else {
                companiesArray = companiesArray.concat(action.data);
            }

            return {
                ...state,
                change_companies_status_loading: false,
                companies: companiesArray,
                change_companies_status_errors: {}
            };
        }
        case CHANGE_COMPANIES_STATUS_FAILURE: {
            return {
                ...state,
                change_companies_status_loading: false,
                change_companies_status_errors: action.errors
            };
        }
        /** end::Change companies status redux */

        default:
            return state;

    }
}