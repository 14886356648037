/**
 =========================================================
 * Akshay's Template - v0.0.0
 =========================================================
 *
 * Created by Akshay N on 01 January 2022
 */

import typography from "../../base/typography";
import borders from "../../base/borders";
import pxToRem from "../../../functions/pxToRem";

const { fontWeightBold, size } = typography;
const { borderRadius } = borders;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: size.xs,
  fontWeight: fontWeightBold,
  borderRadius: borderRadius.md,
  padding: `${pxToRem(6)} ${pxToRem(16)}`,
  lineHeight: 1.4,
  textAlign: "center",
  textTransform: "uppercase",
  userSelect: "none",
  backgroundSize: "150% !important",
  backgroundPositionX: "25% !important",
  transition: "all 150ms ease-in",
  "&:disabled": {
    pointerEvent: "none",
    opacity: 0.65,
  },
  "& .material-icons": {
    fontSize: pxToRem(15),
    marginTop: pxToRem(-2),
  },
};
