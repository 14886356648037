import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types'
import { Close, FileUpload } from '@mui/icons-material';
import { Box } from '@mui/system';

import defaultSvg from './img/default-csv.svg';

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    padding: 4,
    position: 'relative',
    boxSizing: 'border-box',
    ':hover': {
        a: {
            color: '#000'
        }
    }
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: '100%',
    maxHeight: '100px'
};
const section = {
    border: '1px solid rgb(206, 212, 218)',
    cursor: 'pointer',
    borderRadius: '5px',
    padding: '10px',
    minHeight: '150px',
    maxHeight: '150px',
    alignItems: 'center',
    verticalAlign: 'middle',
    display: 'flex',
    textAlign: ' center',
    width: '100%',
};
const closeOuter = {
    position: 'absolute',
    right: '0',
    textAlign: 'right'

};
const closeIcon = {
    color: '#000',
    background: '#fff',
    padding: '1px',
    margin: '6px',
    boxShadow: '0 4px 8px rgb(0 0 0 / 8%)',
    border: '1px solid #ebeefd',
    cursor: `pointer`
};

const invalidFeedback = {
    width: `100%`,
    marginTop: `3px`,
    color: `#F44335`,
    fontWeight: 300,
    lineHeight: 1.25,
    fontSize: `0.65rem`,
    padding: `0px 3px`,
    marginBottom: 0,
    letterSpacing: `0.03333em`,
}



function FormBrowseFile({ label, name, placeholder, className, value, tabIndex, error, onChange, acceptType, maxSize = 2000000, info, isDisabled }) {


    const [files, setFiles] = useState((value) ? [{ type: '', preview: value }] : []);
    const [errors, setErrors] = useState("");

    const { getRootProps, getInputProps } = useDropzone({
        accept: acceptType,
        minSize: 0,
        // maxSize,
        noClick: ((files.length > 0) ? (true) : (false)),
        noDrag: ((files.length > 0) ? (true) : (false)),
        initialFiles: files,
        disabled: isDisabled,
        onChange: data => {
            console.log(data);
        },
        onDrop: (acceptedFiles, fileRejections) => {

            if (acceptedFiles.length > 0) {
                const data = [];
                data.type = 'file';
                data.name = name;
                data.files = acceptedFiles;
                onChange({ target: data });
                setFiles(acceptedFiles.map(file => Object.assign(file, {
                    file: file,
                    preview: URL.createObjectURL(file)
                })));
            }
            setErrors('');
            fileRejections.forEach((file) => {
                file.errors.forEach((err) => {
                    setErrors(`${err.message}`);
                });
            });
        }
    });

    const removeFile = file => () => {
        const newFiles = [...files]
        newFiles.splice(newFiles.indexOf(file), 1)
        const data = [];
        data.type = 'file';
        data.name = name;
        data.files = newFiles;
        onChange({ target: data });

        setFiles(newFiles)
    }

    const thumbs = files.map((file, i) => {
        var ext = file.preview.substr(file.preview.lastIndexOf('.') + 1);
        return (
            <div style={thumb} key={i}>
                <div style={thumbInner}>
                    {(file.type === 'text/csv' || ext === 'csv') && (<img src={defaultSvg} style={img} alt="default svg" />)}
                    {(file.type !== 'text/csv' && ext !== 'csv') && (<img src={file.preview} style={img} alt="file preview" />)}
                </div>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid  */}
                <a style={closeOuter} onClick={removeFile(file)}>
                    <Close style={closeIcon} />
                </a>
            </div>
        )
    }
    );

    if (isDisabled) {
        for (var i = 0; i < files.length; i++) {
            const newFiles = [...files]
            newFiles.splice(newFiles.indexOf(files[i]), 1)
            const data = [];
            data.type = 'file';
            data.name = name;
            data.files = newFiles;
            onChange({ target: data });
            setFiles(newFiles)
        }
    }

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    return (
        <div className={`form-group ${className}`}>
            <section style={section}>
                <div {...getRootProps({ className: "dropzone" })} style={{ width: '100%', cursor: isDisabled ? 'not-allowed' : 'auto' }} >
                    <input
                        type="file"
                        className={`custom-file-input ${(error) ? `is-invalid` : ``}`}
                        tabIndex={tabIndex}
                        id={name}
                        name={name}
                        {...getInputProps()} />
                    {files.length === 0 && (
                        <Box style={{ cursor: `pointer` }}>
                            <FileUpload style={{ width: `3rem`, height: `3rem`, color: '#6b6b6b', paddingBottom: 0, marginTop: `15px` }} />
                            <p style={{ margin: 0, lineHeight: `initial`, marginBottom: `25px` }}>
                                Select or Drag and drop {placeholder} file here
                                <br /> {info ? <span style={{ fontSize: '10px' }} dangerouslySetInnerHTML={{ __html: info }}></span> : ''}
                            </p>
                        </Box>
                    )}
                    <aside style={thumbsContainer}>
                        <ul style={{ padding: 0, margin: '0 auto' }}>{thumbs}</ul>
                    </aside>
                </div>
            </section>
            {(files.length === 0 && errors) && <p style={invalidFeedback}> {errors} </p>}
            {(error) && (<p style={invalidFeedback}>{error}</p>)}
        </div>
    );
}

FormBrowseFile.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    className: PropTypes.string,
    value: PropTypes.any,
    tabIndex: PropTypes.number,
    id: PropTypes.string,
    acceptType: PropTypes.string,
    info: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
}

export { FormBrowseFile }


