/**
 =========================================================
 * Akshay's Template - v0.0.0
 =========================================================
 *
 * Created by Akshay N on 01 January 2022
 */

import colors from "../../base/colors";
import typography from "../../base/typography";
import pxToRem from "../../../functions/pxToRem";

const { transparent, light, info, secondary } = colors;
const { size } = typography;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  base: {
    minHeight: pxToRem(44),
    color: light.main,
    borderColor: light.main,
    padding: `${pxToRem(9)} ${pxToRem(24)}`,
    "&:hover": {
      opacity: 0.75,
      backgroundColor: transparent.main,
    },
    "& .material-icon, .material-icons-round, svg": {
      fontSize: size.xs,
    },
  },
  small: {
    minHeight: pxToRem(40),
    padding: `${pxToRem(6)} ${pxToRem(18)}`,
    fontSize: size.xs,
    "& .material-icon, .material-icons-round, svg": {
      fontSize: size.xs,
    },
  },
  large: {
    minHeight: pxToRem(48),
    padding: `${pxToRem(12)} ${pxToRem(64)}`,
    fontSize: size.sm,
    "& .material-icon, .material-icons-round, svg": {
      fontSize: size.lg,
    },
  },
  primary: {
    backgroundColor: transparent.main,
    borderColor: info.main,
    "&:hover": {
      backgroundColor: transparent.main,
    },
  },
  secondary: {
    backgroundColor: transparent.main,
    borderColor: secondary.main,
    "&:hover": {
      backgroundColor: transparent.main,
    },
  },
};
