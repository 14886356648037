/**
 =========================================================
 * Akshay's Template - v0.0.0
 =========================================================
 *
 * Created by Akshay N on 01 January 2022
 */

import colors from "../../base/colors";
import typography from "../../base/typography";
import pxToRem from "../../../functions/pxToRem";

const { primary, secondary } = colors;
const { size } = typography;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  base: {
    minHeight: pxToRem(44),
    "&:active, &:active:focus, &:active:hover": {
      opacity: 0.85,
    },
    "& .material-icon, .material-icons-round, svg": {
      fontSize: size.xs,
    },
  },
  small: {
    minHeight: pxToRem(40),
    padding: `${pxToRem(6)} ${pxToRem(18)}`,
    fontSize: size.xs,
    "& .material-icon, .material-icons-round, svg": {
      fontSize: size.xs,
    },
  },
  large: {
    minHeight: pxToRem(48),
    padding: `${pxToRem(12)} ${pxToRem(64)}`,
    fontSize: size.sm,
    "& .material-icon, .material-icons-round, svg": {
      fontSize: size.lg,
    },
  },
  primary: {
    backgroundColor: primary.main,
    "&:hover": {
      backgroundColor: primary.focus,
    },
    "&:focus:not(:hover)": {
      backgroundColor: primary.focus,
    },
  },
  secondary: {
    backgroundColor: secondary.main,
    "&:hover": {
      backgroundColor: secondary.main,
    },
    "&:focus:not(:hover)": {
      backgroundColor: secondary.focus,
    },
  },
};
