import { apiServices } from '../../_helpers';

export const CompaniesServices = {

    /**
     * Service to get Pervious companies list
     */
    companiesList: (params) => {
        return apiServices.post('/agent/companies', params)
            .then((response) => { return response; })
    },

    /**
     * Service for change Company status
    */
    changeStatus: (params) => {
        return apiServices.put('/agent/companies/change-status', params)
            .then((response) => { return response; })

    },

}