import { CompaniesServices } from "../services";
import { GlobalAction } from "./GlobalAction";

export const CHANGE_COMPANIES_STATUS_REQUEST = 'CHANGE_COMPANIES_STATUS_REQUEST';
export const CHANGE_COMPANIES_STATUS_SUCCESS = 'CHANGE_COMPANIES_STATUS_SUCCESS';
export const CHANGE_COMPANIES_STATUS_FAILURE = 'CHANGE_COMPANIES_STATUS_FAILURE';

export const COMPANIES_LIST_REQUEST = 'COMPANIES_LIST_REQUEST';
export const COMPANIES_LIST_SUCCESS = 'COMPANIES_LIST_SUCCESS';
export const COMPANIES_LIST_FAILURE = 'COMPANIES_LIST_FAILURE';

export const CompaniesAction = {

    /**
     * Action to get Pervious companies list
     * @param {Object} params - The params which are used for the api
     * @author  
     * @created_at 3 Nov 2022
     */
    companiesList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return CompaniesServices.companiesList(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };
        function request(request) { return { type: COMPANIES_LIST_REQUEST, request: request } }
        function success(request, response) { return { type: COMPANIES_LIST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: COMPANIES_LIST_FAILURE, request: request, message: error.message, errors: error.errors, data: error.data, status: error.status, status_code: error.status_code } }
    },

    /**
        * Action for change company status
        * @param {Object} params - The params which are used for the api
        * @author  
        * @created_at 3 Nov 2022
    */
    changeStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return CompaniesServices.changeStatus(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_COMPANIES_STATUS_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_COMPANIES_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_COMPANIES_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

}