
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Helmet } from "react-helmet";

import { cookieServices } from '../../_helpers';
import { Grid, Box, styled } from '@mui/material';
import { getComponentNameByPath, PostAuthRoutes } from '../../routes';
import { Card, CardContent, ShortLogo } from '../../_components';

import { AppNameContext } from '../../_contexts';
import { CopyRight } from '.'

const StyledMuiBox = styled(({ ...props }) => (<Box {...props} />))`
        background-color: ${props => props.theme.palette.primary.main};
        & .left-grid{
            padding-left: 0;
            background-color: ${props => props.theme.palette.primary.focus};
            border-top-left-radius: ${props => props.theme.borders.borderRadius.sm};
            border-bottom-left-radius: ${props => props.theme.borders.borderRadius.sm};
        }
`;

const PreSignIn = ({ children, ...props }) => {

    /** Initialize plugins and variables */
    let history = useHistory();
    const location = useLocation();
    const appName = useContext(AppNameContext);

    /** Initialize and declare state */
    const [pageTitle, setPageTitle] = useState('Loading')

    useEffect(() => {
        if (cookieServices.get('accessToken')) {
            let redirectPath = PostAuthRoutes('home').path;
            history.push(redirectPath);
        }
    }, [history])

    useEffect(() => {
        setPageTitle(getComponentNameByPath(location.pathname));
    }, [location])

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${appName} | ${pageTitle} `}</title>
            </Helmet>
            <StyledMuiBox display="flex" alignItems="center" justifyContent="center" height="100vh">
                <Card>
                    <CardContent sx={{ width: `600px`, minHeight: `500px`, display: `flex`, pb: `0px!important`, p: `24px` }}>
                        <Grid container spacing={3}  >
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} display="flex" alignItems="flex-end" justifyContent="center" className='left-grid'>
                                <ShortLogo width={50} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={10} lg={10} xl={10} display="flex" alignItems="center" justifyContent="center" >
                                {children}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </StyledMuiBox>
            <CopyRight />
        </React.Fragment>
    )
}


export { PreSignIn }
