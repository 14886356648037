import { GlobalAction } from ".";
import { AttendanceServices } from "../services";

export const CREATE_ATTENDANCE_REQUEST = 'CREATE_ATTENDANCE_REQUEST';
export const CREATE_ATTENDANCE_SUCCESS = 'CREATE_ATTENDANCE_SUCCESS';
export const CREATE_ATTENDANCE_FAILURE = 'CREATE_ATTENDANCE_FAILURE';

export const GET_ALL_STAFF_ATTENDANCE_REQUEST = 'GET_ALL_STAFF_ATTENDANCE_REQUEST';
export const GET_ALL_STAFF_ATTENDANCE_SUCCESS = 'GET_ALL_STAFF_ATTENDANCE_SUCCESS';
export const GET_ALL_STAFF_ATTENDANCE_FAILURE = 'GET_ALL_STAFF_ATTENDANCE_FAILURE';

export const AttendanceAction = {

    /**
    * Action for create Attendance request
    * @param {Object} params - The params which are used for the api
    * @author Naveen  
    * @created_at 21 September 2022
    */
    createAttendance: (params) => {
        return dispatch => {
            dispatch(request(params));
            return AttendanceServices.createAttendance(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: CREATE_ATTENDANCE_REQUEST, request: request } }
        function success(request, response) { return { type: CREATE_ATTENDANCE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CREATE_ATTENDANCE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action to get all staff attendance
     * @param {Object} params - The params which are used for the api
     * @author Naveen  
     * @created_at 10 October 2022
    */
    getAllStaffAttendance: (params) => {
        return dispatch => {
            dispatch(request(params));
            return AttendanceServices.getAllStaffAttendance(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GET_ALL_STAFF_ATTENDANCE_REQUEST, request: request } }
        function success(request, response) { return { type: GET_ALL_STAFF_ATTENDANCE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GET_ALL_STAFF_ATTENDANCE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    }
}