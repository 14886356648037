import { Home, Notifications, Password, Person, RowingOutlined, Warning, PeopleAlt, Key, AdminPanelSettings, PaymentsRounded, ReceiptLongRounded, BackupTableRounded, EventBusyRounded } from "@mui/icons-material";
import { Dashboard } from "../app/dashboard";
import { StaffExperience, CreateStaffExperience } from "../app/staff-experience";
import { Leaves, CreateLeaveRequest } from "../app/leaves";
import { AccountStatus, ChangePassword, Profile, ProfileDetails, Notifications as NotificationList, ActivityLog, SalaryLogs } from "../app/profile";
import { Role, CreateRole, AssignPermission } from "../app/role";
import { Permission, CreatePermission } from "../app/permission";
import { Payslip } from "../app/payslip";
import { SalaryLog, SalaryLogCard } from "../app/salary-logs";
import { StaffCalendar, CreateAttendance } from "../app/staff-calendar";
import { PreviousCompanies } from "../app/companies";


const allRoutes = {

    /** begin::home module */
    'home': {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Home,
        module: 'dashboard',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'dashboard'
    },
    /** end::home module */

    /** begin::profile module  */
    'profile': {
        path: "/profile/:type?",
        name: "Profile",
        component: Profile,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Person,
        module: 'profile',
        isModuleMenu: true,
        isModuleMenuItem: false,
        isMainComponent: true
    },
    'profile_details': {
        path: "/profile/details",
        name: "Profile",
        component: ProfileDetails,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Person,
        module: 'profile',
        isModuleMenuItem: true,

    },
    /** end::profile module */

    /** begin::notifications module */
    'notifications': {
        path: "/profile/notifications",
        name: "Notifications",
        component: NotificationList,
        layout: "/post-auth",
        menuIcon: Notifications,
        isMenuItem: false,
        module: 'profile',
        isModuleMenuItem: true
    },
    /** end::notifications module */

    /** begin::activity log module */
    'activity_log': {
        path: "/profile/activity",
        name: "Activity Log",
        component: ActivityLog,
        layout: "/post-auth",
        menuIcon: RowingOutlined,
        isMenuItem: false,
        module: 'profile',
        isModuleMenuItem: true
    },
    /** end::activity log module */

    /** begin::change password  module */
    'change_password': {
        path: "/profile/change-password",
        name: "Change Password",
        component: ChangePassword,
        layout: "/post-auth",
        menuIcon: Password,
        isMenuItem: false,
        module: 'profile',
        isModuleMenuItem: true
    },
    /** end::change password module */

    /** begin::account status module */
    'account_status': {
        path: "/profile/status",
        name: "Account Status",
        component: AccountStatus,
        layout: "/post-auth",
        menuIcon: Warning,
        isMenuItem: false,
        module: 'profile',
        isModuleMenuItem: true
    },
    /** end::account status module */

    /** begin::role module */
    'role': {
        path: "/role",
        name: "Role",
        component: Role,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: AdminPanelSettings,
        module: 'role',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'roles'
    },
    'create_role': {
        path: "/role/create",
        name: "Create Role",
        component: CreateRole,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: AdminPanelSettings,
        module: 'role',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'create_role'
    },
    'update_role': {
        path: "/role/update",
        name: "Update Role",
        component: CreateRole,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: AdminPanelSettings,
        module: 'role',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'update_role'
    },
    'assign_permission': {
        path: "/role/assign-permission",
        name: "Assign Permission",
        component: AssignPermission,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: AdminPanelSettings,
        module: 'role',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'role_assign_permission'
    },
    /** end::role module */

    /** begin::permission module */
    'permission': {
        path: "/permission",
        name: "Permissions",
        component: Permission,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Key,
        module: 'permissions',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'permissions'
    },
    'create_permission': {
        path: "/permission/create",
        name: "Create Permission",
        component: CreatePermission,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Key,
        module: 'permissions',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'create_permission'
    },
    'update_permission': {
        path: "/permission/update",
        name: "Update Permission",
        component: CreatePermission,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Key,
        module: 'permissions',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'update_permission'
    },
    /** end::permission module */

    /** begin::Staff Experience module */
    'staff_experience': {
        path: "/staff_experience",
        name: "Staff Experience",
        component: StaffExperience,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: BackupTableRounded,
        module: 'staff_experience',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'staff_experience'
    },
    'create_staff_experience': {
        path: "/staff_experience/create",
        name: "Create Staff Experience",
        component: CreateStaffExperience,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: BackupTableRounded,
        module: 'staff_experience',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'create_staff_experience'
    },
    'update_staff_experience': {
        path: "/staff_experience/update",
        name: "Update Staff Experience",
        component: CreateStaffExperience,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: BackupTableRounded,
        module: 'staff_experience',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'update_staff_experience'
    },
    /** end::Staff Experience module */

    /** begin::Pay Slips module */
    'payslip': {
        path: "/payslip",
        name: "Pay Slips",
        component: Payslip,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: ReceiptLongRounded,
        module: 'payslip',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'pay_slips'
    },
    /** end::Pay Slips module */

    /** begin::Salary Log module */
    'salary_logs_history': {
        path: "/salary_log_history",
        name: "Salary History",
        component: SalaryLogCard,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: BackupTableRounded,
        module: 'salary_log',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'salary_log_history'
    },
    'salary_log': {
        path: "/salary_log",
        name: "Latest Salary Log",
        component: SalaryLog,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: BackupTableRounded,
        module: 'salary_log',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'salary_log'
    },
    /** end::Salary Log module */

    /** begin::Leave Submission module */
    'leave': {
        path: "/leaves",
        name: "Leaves Applied",
        component: Leaves,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: EventBusyRounded,
        module: 'leaves',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'leaves_applied'
    },
    'create_leave': {
        path: "/leaves/create",
        name: "Create Leave Request",
        component: CreateLeaveRequest,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: EventBusyRounded,
        module: 'leaves',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'create_leaves_permission'
    },
    /** end::Leave Submission module */

    /** begin::Staff calendar module */
    'staff_calendar': {
        path: "/staff-calendar",
        name: "Staff Calendar",
        component: StaffCalendar,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: EventBusyRounded,
        module: 'staff-calendar',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'staff_calendar'
    },
    /** end::Staff calendar module */

    /** begin::Attendance Submission module */
    'create_attendance': {
        path: "/attendance/create",
        name: "Mark Attendance",
        component: CreateAttendance,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: EventBusyRounded,
        module: 'attendance',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'staff_calendar_mark_attendance'
    },
    /** end::Attendance Submission module */

    /** begin::Previous Companies module */
    'previous_companies': {
        path: "/previous-companies",
        name: "Previous Companies",
        component: PreviousCompanies,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: EventBusyRounded,
        module: 'Previous-companies',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'previous_companies'
    }
    /** end::Previous Companies module */
};

function PostAuthRoutes(keyType = null) {
    if (keyType) {
        return allRoutes[keyType];
    }
}

function PostAuthMenuRoutes() {
    return Object.values(allRoutes).filter(function (item) {
        return item.isMenuItem === true;
    });
}

function PostAuthMainComponents() {
    return Object.values(allRoutes).filter(function (item) {
        return item.isMainComponent === true ? (
            { ...item, path: (item.isModuleMenu) ? (item.path.replace(':type?', '/*')) : (item.path) }
        ) : null;
    });
}

function PostAuthModuleMenuRoutes(module) {
    return Object.values(allRoutes).filter(function (item) {
        return (item.isModuleMenuItem === true && item.module === module) ? ({
            ...item, path: item.path.split("/").pop()
        }) : null;
    });
}

function getPostComponentNameByPath(path) {
    if (path) {
        let componentItem = Object.values(allRoutes).filter(function (item) {
            return item.path === path;
        });
        return componentItem.length > 0 ? componentItem[0]['name'] : '';
    }
}


export { PostAuthRoutes, PostAuthMenuRoutes, PostAuthModuleMenuRoutes, PostAuthMainComponents, getPostComponentNameByPath };