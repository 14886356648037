import React, { useEffect } from 'react'
import { CardHeader, Stack, Typography, Grid, Avatar } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader, NoData } from '../../../_components';
import { Box } from '../../../_components/box';
import { Card, CardContent } from '../../../_components/card'
import { DashboardAction } from '../../../redux/actions';
import { TodayInformationCardLoader } from '.'

function TodayInformation() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { leaves_today_loading: isLeavesTodayLoading, leaves_today } = useSelector((state) => state.DashboardReducer);
    const leavesToday = (params) => dispatch(DashboardAction.leavesToday(params));

    useEffect(() => {
        leavesToday();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <React.Fragment>
            <Box>
                <PageHeader
                    title="Today"
                />
                <Card>
                    <CardContent>
                        <Grid container spacing={3} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                            <Grid item xs={12} sm={12} md={8} lg={12} xl={12} >
                                {leaves_today.length > 0 && leaves_today.map((current_leaves, i) => {
                                    return (
                                        <Grid container spacing={0} >
                                            <Grid item xs={12} sm={12} md={4} lg={4} xl={12}>

                                                <Stack direction="row" justifyContent="left" alignItems="center" spacing={1} padding='10px'>
                                                    <Box display="flex" justifyContent="center" alignItems="center" >
                                                        <Avatar sx={{ width: 30, height: 30 }}></Avatar>
                                                    </Box>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                                                        <Typography variant="body1" >&nbsp;{current_leaves.staffName} is on leave Today</Typography>
                                                    </Box>
                                                </Stack>

                                            </Grid>
                                        </Grid>
                                    );
                                })
                                }
                                {!isLeavesTodayLoading && leaves_today.length === 0 && <NoData content1={`No`} content2={`Leaves Today`} description={`Stay tuned! Notifications about your activity will show up here.`} minHeight={`33vh`} ></NoData>}
                                {isLeavesTodayLoading && < TodayInformationCardLoader />}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </React.Fragment>
    )

}

export { TodayInformation }