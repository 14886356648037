import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import { PostAuthRoutes } from "../../../routes";
import { handleInputChange, validateForm, APP_NAME } from "../../../_helpers";

import { Box } from "../../../_components/box";
import { SubmitButton, CancelButton } from "../../../_components/controls";
import { FormDateTimePicker } from "../../../_components/form";
import { Card, CardContent } from "../../../_components/card";
import { Grid, Stack } from "@mui/material";
import { AttendanceAction } from "../../../redux/actions";
import { validate } from "./validate";
import { PageHeader } from "../../../_components";

// Initialize form input values to null
const inputs = { dateTime: '' };

function CreateAttendance() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Redux actions and state */
    const createAttendance = (params) => dispatch(AttendanceAction.createAttendance(params));

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });

    /**
     * function to handle input changes and modify the value
     * @author Naveen  
     * @created_at 10 Oct 2022 
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author Naveen  
     * @created_at 10 Oct 2022  
     */
    function handleSubmit(e) {

        e.preventDefault();
        setAction({ isSubmitted: true });

        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        const formData = {
            "dateTime": data.dateTime,
        }

        addAttendanceRequest(formData);
    }

    /**
     * function to create Attendance Request
     * @author Naveen  
     * @created_at 21 September 2022 
     */
    async function addAttendanceRequest(formData) {

        console.log("formData12::", formData)

        const isCreated = await createAttendance(formData)

        if (isCreated && isCreated.status === 1) {
            let redirectPath = PostAuthRoutes('staff_calendar').path;
            history.push(redirectPath);
        }

    }

    /**
     * function to cancel create Attendance request
     * @param {object} formData form object
     * @author Naveen  
     * @created_at 10 Oct 2022 
     */
    function cancelAttendanceRequest() {
        let redirectPath = PostAuthRoutes('staff_calendar').path;
        history.push(redirectPath);
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{APP_NAME} | ${PostAuthRoutes('create_attendance').name}</title>
            </Helmet>
            <PageHeader title={PostAuthRoutes('create_attendance').name} />
            <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Card>
                            <CardContent >
                                <Grid container spacing={2} justifyContent="flex-start" alignItems="center" >
                                    <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                                        <FormDateTimePicker
                                            tabIndex={2}
                                            label='Select Date'
                                            name='dateTime'
                                            placeholder="dd/MM/yyyy hh:mm a"
                                            onChange={handleChange}
                                            error={(action.isSubmitted && (errors.dateTime)) ? (errors.dateTime) : ''}
                                            value={data.dateTime}
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
                                            <SubmitButton label={'Submit'} tabIndex={6} />
                                            <CancelButton onClick={cancelAttendanceRequest} tabIndex={12} />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </form>
        </React.Fragment>
    )

}
export { CreateAttendance };