import React, { useEffect, useState } from 'react'
import queryString from 'query-string';
import { DataTable } from '../../_components/data-table/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import { PermissionAction } from '../../redux/actions';
import { Chip, ButtonGroup, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { PostAuthRoutes } from '../../routes';
import { Edit } from '@mui/icons-material';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { AbilityCan } from '../../_helpers/permission/AbilityCan';


function PermissionDataTable({ statusUpdateModal, filterAction, filter, search, limit, page, ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);
    const { per_page, total, permissions_loading: isLoading, permissions } = useSelector((state) => state.PermissionReducer);
    const getPermissionsList = (params) => dispatch(PermissionAction.getPermissionsList(params));

    /** Initialize and declare state */
    const [sort, setSort] = useState({ '_id': 'asc' });
    const [payload, setPayload] = useState({ filter, search, page, limit });

    const columns = [
        { name: '#', selector: (row, i) => (row._id), sortField: '_id', center: true, width: '6%', cell: (row, i) => ((per_page * (page - 1)) + (i + 1)) },
        { name: 'Permission', selector: (row, i) => (row.name), sortField: 'name', sortable: true, width: '35%' },
        { name: 'Parent Permission', selector: (row, i) => (row.parent_name), sortField: 'parent_name', sortable: true, width: '35%', style: { whiteSpace: `unset` } },
        { name: 'Created Date', selector: (row, i) => (row.created_at), sortField: 'created_at', center: true, sortable: true, width: '14%', cell: row => (<CreatedDate row={row} />) },
        { name: 'Status', selector: (row, i) => (row.status), sortField: 'status', center: true, sortable: true, width: '10%', cell: row => (<StatusRow row={row} />) },
    ];

    /**
     * function to display status value on rows
     * @param {Object} row
     * @param {string} row.status - status values on the row
     * @return json
     * @author 
     * @created_at 14 June 2022 
     */
    const StatusRow = ({ row }) => {

        function handleUpdateClick(row) {
            history.push({
                pathname: PostAuthRoutes('update_permission').path,
                state: { permission: row }
            });
        }

        function handleChangeStatusClick(row) {
            setTimeout(() => {
                statusUpdateModal({ id: row.id, status: row.status, item: '' });
            }, 100);
        }

        let status = <Chip label={row.status} className="badge-status capitalize" />;
        if (row.status === '1')
            status = <Chip label="Active" color="success" className="badge-status capitalize" />;
        else if (row.status === '0')
            status = <Chip label="Inactive" color="error" className="badge-status capitalize" />;

        let statusHover = (

            <ButtonGroup variant="text" size="small" className='row-action'>
                <AbilityCan I='update_permission' passThrough={(user.role === 'developer') ? true : false}>
                    <Button onClick={() => handleUpdateClick(row)}> <Edit /></Button>
                </AbilityCan>
                <AbilityCan I='permission_change_status' passThrough={(user.role === 'developer') ? true : false}>
                    <Button onClick={() => handleChangeStatusClick(row)}> <ChangeCircleIcon /></Button>
                </AbilityCan>
            </ButtonGroup>

        )

        return <React.Fragment>{status}{statusHover}</React.Fragment>;

    }

    /**
     * Function to display from date value on rows
     * @param {Object} row
     * @param {string} row.created_at - created date values on the row
     * @return json
     * @author Naveen
     * @created_at 30 sept 2022 
     */
    const CreatedDate = ({ row }) => {

        const created_date_array = row.created_at.split('-')
        const row_created_date = `${created_date_array[2].slice(0, 2)}/${created_date_array[1]}/${created_date_array[0]}`

        let created_at = <Chip label={row_created_date} className="badge-status capitalize" />;

        return <React.Fragment>{created_at}</React.Fragment>;
    }

    useEffect(() => {
        setPayload({ filter, search, page, limit, sort });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, search, page, limit])

    useEffect(() => {

        const queryParam = { ...filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });

        queryParam.p = page;
        if (search) queryParam.q = search; else delete queryParam.q;
        if (filter.status) queryParam.status = filter.status; else delete queryParam.status;
        if (filter.filter_parent) queryParam.filter_parent = filter.filter_parent; else delete queryParam.filter_parent;
        history.push({
            pathname: PostAuthRoutes('permission').path,
            search: `?${queryString.stringify(queryParam)}`
        });
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter, payload.search])

    /**
     * function to fetch permission list
     * @param {Object} payload - The params which are used for the api
     * @param {string} payload.page - Page number to load
     * @param {string} payload.limit -  Count of the documents per page
     * @param {string} payload.filter - Filter data
     * @param {string} payload.search - Search key
     * @return json 
     */
    const getData = async () => {
        await getPermissionsList(payload);
    }

    /**
     * function to sort columns
     * @return view
     * @author 
     * @created_at 14 June 2022 
     */
    const handleSort = (column, sortDirection) => {
        const sortField = column.sortField;
        setSort({ [sortField]: sortDirection })
    }

    /**
     * function to select columns
     * @return view
     * @author 
     * @created_at 14 June 2022 
     */
    const handleSelect = (data) => {
        console.log(data.selectedRows)
    }

    /**
     * function to handle page
     * @param {String} page - Page number to load 
     * @return view
     */

    const handlePage = (page) => {
        setPayload({ ...payload, page: page });
    }

    return (
        <DataTable
            loading={isLoading}
            columns={columns}
            data={permissions}
            total={total}
            per_page={per_page}
            setPage={page => handlePage(page)}
            handleSort={handleSort}
            handleSelect={handleSelect}
        />
    )
}


export { PermissionDataTable };

