import {
  STAFF_SKILL_REQUEST,
  STAFF_SKILL_SUCCESS,
  STAFF_SKILL_FAILURE,
  CREATE_STAFF_SKILL_REQUEST,
  CREATE_STAFF_SKILL_SUCCESS,
  CREATE_STAFF_SKILL_FAILURE,
  DELETE_STAFF_SKILL_REQUEST,
  DELETE_STAFF_SKILL_SUCCESS,
  DELETE_STAFF_SKILL_FAILURE
} from "../actions";

const initialState = {
  skill_loading: false,
  staff_skills: [],
  skill_errors: {},
  staff_skill_loading: false,
  staff_skill_errors: []
};

export function StaffSkillReducer(state = initialState, action) {
  switch (action.type) {
    /** begin::Create staff skill redux */
    case CREATE_STAFF_SKILL_REQUEST: {
      return {
        ...state,
        staff_skill_loading: true,
        staff_skill_errors: {}
      };
    }
    case CREATE_STAFF_SKILL_SUCCESS: {
      let newArray = [...state.staff_skills]; //making a new array
      const staffSkillIndex = newArray.findIndex(
        staff_skill => staff_skill.id === action.data.insertId
      );

      if (staffSkillIndex !== -1) {
        newArray[staffSkillIndex].status = action.data.status;
      } else {
        newArray = newArray.concat(action.data);
      }
      return {
        ...state,
        staff_skill_loading: false,
        staff_skills: newArray,
        staff_skill_errors: {}
      };
    }
    case CREATE_STAFF_SKILL_FAILURE: {
      return {
        ...state,
        staff_skill_loading: false,
        staff_skill_errors: action.errors
      };
    }
    /** end::Create staff skill redux */

    /** begin::List staff Skills redux */
    case STAFF_SKILL_REQUEST: {
      return {
        ...state,
        skill_loading: true,
        skill_errors: {}
      };
    }
    case STAFF_SKILL_SUCCESS: {
      let newArray = [...state.staff_skills]; //making a new array
      if (action.data.page === 1) {
        newArray = [];
      }

      action.data.forEach(payload => {
        const staffSkillIndex = newArray.findIndex(
          staff_skill => staff_skill.id === payload.id
        );
        if (staffSkillIndex !== -1) {
          newArray[staffSkillIndex] = payload;
        } else {
          newArray = newArray.concat(payload);
        }
      });

      return {
        ...state,
        skill_loading: false,
        staff_skills: newArray,
        skill_errors: {}
      };
    }
    case STAFF_SKILL_FAILURE: {
      return {
        ...state,
        skill_loading: false,
        skill_errors: action.errors,
        staff_skills: []
      };
    }

    /** end::List staff Skills redux */

    /** begin::Delete staff skill redux */
    case DELETE_STAFF_SKILL_REQUEST: {
      return {
        ...state,
        delete_staff_skill_loading: true,
        delete_staff_skill_errors: {}
      };
    }
    case DELETE_STAFF_SKILL_SUCCESS: {
      let staffSkillArray = [...state.staff_skills];
      const StaffSkillFilter = staffSkillArray.filter(
        staff_skill => staff_skill.id !== action.data.id
      );

      return {
        ...state,
        staff_skills: StaffSkillFilter,
        delete_staff_skill_loading: false,
        delete_staff_skill: action.data,
        delete_staff_skill_errors: {}
      };
    }
    case DELETE_STAFF_SKILL_FAILURE: {
      return {
        ...state,
        delete_staff_skill_loading: false,
        delete_staff_skill_errors: action.errors
      };
    }
    /** end::Delete staff skill redux */

    default:
      return state;
  }
}
