import React, { useEffect } from 'react';
import { useHistory } from 'react-router'
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { PostAuthRoutes } from '../../routes';
import { AbilityCan } from '../../_helpers/permission/AbilityCan';
import { GeneralButton } from '../../_components/controls/general-button/GeneralButton';
import { SalaryLogAction } from '../../redux/actions'
import { PageHeader } from '../../_components';
import { NoData } from '../../_components';

function SalaryLog() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let history = useHistory();

    /** Redux actions and state */
    const { user_loading: isLoading, user } = useSelector((state) => state.UserReducer);
    const { salary_log_loading: isSalaryLogLoading, salary_log } = useSelector((state) => state.SalaryLogReducer);
    const listSalaryLog = () => dispatch(SalaryLogAction.listSalaryLog());

    const { salaryDetails } = user

    useEffect(() => {
        getNext();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    /**
     * function to list salary log
     * @return view
     * @author Naveen
     * @created_at 21 Sept 2022
     */
    const getNext = async () => {
        await listSalaryLog();
    }

    /**
     * function to direct to salary log dataTable
     * @param {string} null 
     * @return view
     * @author Naveen
     * @created_at 21 Sept 2022
     */
    const salaryLogHistory = () => {
        history.push({
            pathname: PostAuthRoutes('salary_logs_history').path,
        });;
    }

    return (
        <React.Fragment>
            <Box>
                <PageHeader
                    title={PostAuthRoutes('salary_log').name}
                    action={
                        <Stack spacing={1} direction="row" alignItems={`center`}>
                            <AbilityCan I='salary_log_history' passThrough={(user.role === 'developer') ? true : false}>
                                {salary_log.length > 0 && <GeneralButton label={'Salary History'} onClick={() => salaryLogHistory()} />}
                            </AbilityCan>
                        </Stack>
                    }
                />

                {salaryDetails &&
                    <Grid container spacing={3} padding={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                            {salaryDetails.basic_salary &&
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                                        <Typography variant="h6"> {`Basic Salary : `}</Typography>
                                        <Typography variant="body1" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>&nbsp;{`${salaryDetails.basic_salary}`}</Typography>
                                    </Box>
                                </Stack>
                            }

                            {salaryDetails.dearness_allowance &&
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                                        <Typography variant="h6"> {`Dearness Allowance : `}</Typography>
                                        <Typography variant="body1" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>&nbsp;{`${salaryDetails.dearness_allowance}`}</Typography>
                                    </Box>
                                </Stack>
                            }

                            {salaryDetails.conveyance_allowance &&
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                                        <Typography variant="h6"> {`Conveyance Allowance : `}</Typography>
                                        <Typography variant="body1" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>&nbsp;{`${salaryDetails.conveyance_allowance}`}</Typography>
                                    </Box>
                                </Stack>
                            }

                            {salaryDetails.hra &&
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                                        <Typography variant="h6"> {`HRA : `}</Typography>
                                        <Typography variant="body1" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>&nbsp;{`${salaryDetails.hra}`}</Typography>
                                    </Box>
                                </Stack>
                            }

                            {salaryDetails.medical_allowance &&
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                                        <Typography variant="h6"> {`Medical Allowance : `}</Typography>
                                        <Typography variant="body1" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>&nbsp;{`${salaryDetails.medical_allowance}`}</Typography>
                                    </Box>
                                </Stack>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                            {salaryDetails.special_allowance &&
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                                        <Typography variant="h6"> {`Special Allowance : `}</Typography>
                                        <Typography variant="body1" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>&nbsp;{`${salaryDetails.special_allowance}`}</Typography>
                                    </Box>
                                </Stack>
                            }

                            {salaryDetails.reimbursement &&
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                                        <Typography variant="h6"> {`Reimbursement : `}</Typography>
                                        <Typography variant="body1" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>&nbsp;{`${salaryDetails.reimbursement}`}</Typography>
                                    </Box>
                                </Stack>
                            }

                            {salaryDetails.professional_tax &&
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                                        <Typography variant="h6"> {`Professional Tax : `}</Typography>
                                        <Typography variant="body1" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>&nbsp;{`${salaryDetails.professional_tax}`}</Typography>
                                    </Box>
                                </Stack>
                            }

                            {salaryDetails.tax_deduction_at_source &&
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                                        <Typography variant="h6"> {`Tax Deduction at Source : `}</Typography>
                                        <Typography variant="body1" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>&nbsp;{`${salaryDetails.tax_deduction_at_source}`}</Typography>
                                    </Box>
                                </Stack>
                            }

                            {salaryDetails.employee_providant_fund &&
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                                        <Typography variant="h6"> {`Employee Providant fund: `}</Typography>
                                        <Typography variant="body1" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>&nbsp;{`${salaryDetails.employee_providant_fund}`}</Typography>
                                    </Box>
                                </Stack>
                            }

                            {salaryDetails.net_salary &&
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                                        <Typography variant="h6"> {`Net Salary : `}</Typography>
                                        <Typography variant="body1" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>&nbsp;{`${salaryDetails.net_salary}`}</Typography>
                                    </Box>
                                </Stack>
                            }
                        </Grid>
                    </Grid>
                }
                {!isSalaryLogLoading && salary_log.length === 0 && <NoData content1={`No`} content2={`Salary Log available`} description={`Stay tuned! Notifications about your activity will show up here.`} minHeight={`55vh`} ></NoData>}
            </Box>
        </React.Fragment>
    )
}

export { SalaryLog }