import { apiServices } from '../../_helpers';


export const NotificationServices = {

    /**
     * Service to get notification list
     * @author Jasin 
     */
    getNotificationList: (params) => {
        return apiServices.post('/agent/notification/list', params)
            .then((response) => { return response; })
    },

     /**
     * Service to mark all notifications as read
     * @author Jasin 
     */
    markAllRead: () => {
        return apiServices.put('/agent/notification/mark-all-read')
            .then((response) => { return response; })

    },

    /**
     * Service to mark notification as read
     * @author Jasin 
     */
    markRead: (params) => {
        return apiServices.put('/agent/notification/mark-read', params)
            .then((response) => { return response; })

    },

};
