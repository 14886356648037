
import { Box, Card, CardHeader } from '@mui/material'
import { styled } from '@mui/material/styles';

const StyledBox = styled(({ ...props }) => (<Box {...props} />))`
        & .MuiPaper-root{
            padding-top: 5px;
            padding-bottom: 5px;
            background-color: #ffffff2b;
            & .MuiCardHeader-root {
                padding-top: 15px;
            }
            & .MuiTypography-root {
                font-weight: 600;
                position: relative;
            }
        }
`;


function PageHeader({ title, action = null, ...props }) {

    return (
        <StyledBox>
            <Card>
                <CardHeader title={title} titleTypographyProps={{ variant: 'h5', component: 'h5' }} action={action} {...props} />
            </Card>
        </StyledBox>
    )
}

export { PageHeader }
