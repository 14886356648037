import {
    SALARY_LOG_REQUEST, SALARY_LOG_SUCCESS, SALARY_LOG_FAILURE,
} from "../actions";

const initialState = {
    salary_log_loading: false, salary_log: [], salary_log_errors: {},
    // page: 1, per_page: 20, total_pages: 0, 
}

export function SalaryLogReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::List Salary Log redux */
        case SALARY_LOG_REQUEST: {
            return {
                ...state,
                salary_log_loading: true,
                salary_log_errors: {}
            };
        }
        case SALARY_LOG_SUCCESS: {

            return {
                ...state,
                salary_log_loading: false,
                salary_log: action.data,
                salary_log_errors: {}
            };

        }
        case SALARY_LOG_FAILURE: {
            return {
                ...state,
                salary_log_loading: false,
                salary_log_errors: action.errors,
                salary_log: []
            };
        }

        /** end::List salary log coupon codes redux */

        default:
            return state;
    }
}