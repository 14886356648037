import { returnAlphabets, returnAlphabetsWithSpace, returnAlphabetsNumbersWithHyphen, returnAlphabetsNumbersWithUnderScore, returnDigitWithOneDot, returnInstaHandle, returnDigitOnly } from ".";

const maskInput = (maskType, e) => {
    if (maskType === 'insta_handle') {
        return returnInstaHandle(e);
    } else if (maskType === 'alphabets-only') {
        return returnAlphabets(e);
    } else if (maskType === 'alphabets-with-space') {
        return returnAlphabetsWithSpace(e);
    } else if (maskType === 'alphanumeric-with-hyphen') {
        return returnAlphabetsNumbersWithHyphen(e);
    } else if (maskType === 'alphanumeric-with-underscore') {
        return returnAlphabetsNumbersWithUnderScore(e);
    } else if (maskType === 'digit-with-one-dot') {
        return returnDigitWithOneDot(e);
    } else if (maskType === 'digit-only') {
        return returnDigitOnly(e);
    }
}

export { maskInput }
