import {
    STAFF_EXPERIENCE_REQUEST, STAFF_EXPERIENCE_SUCCESS, STAFF_EXPERIENCE_FAILURE,
    CREATE_STAFF_EXPERIENCE_REQUEST, CREATE_STAFF_EXPERIENCE_SUCCESS, CREATE_STAFF_EXPERIENCE_FAILURE,
    UPDATE_STAFF_EXPERIENCE_REQUEST, UPDATE_STAFF_EXPERIENCE_SUCCESS, UPDATE_STAFF_EXPERIENCE_FAILURE,
    DELETE_STAFF_EXPERIENCE_REQUEST, DELETE_STAFF_EXPERIENCE_SUCCESS, DELETE_STAFF_EXPERIENCE_FAILURE,
} from "../actions";

const initialState = {
    experience_loading: false, staff_experience: [], experience_errors: {},
    staff_experience_loading: false, staff_experience_errors: [],
};

export function StaffExperienceReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::List staff Experiences redux */
        case STAFF_EXPERIENCE_REQUEST: {
            return {
                ...state,
                experience_loading: true,
                experience_errors: {}
            };
        }
        case STAFF_EXPERIENCE_SUCCESS: {

            let newArray = [...state.staff_experience]; //making a new array
            if (action.data.page === 1) {
                newArray = []
            }

            action.data.forEach((payload) => {
                const staffExperienceIndex = newArray.findIndex(staff_experience => staff_experience.id === payload.id);
                if (staffExperienceIndex !== -1) {
                    newArray[staffExperienceIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                experience_loading: false,
                staff_experience: newArray,
                experience_errors: {}
            };

        }
        case STAFF_EXPERIENCE_FAILURE: {
            return {
                ...state,
                experience_loading: false,
                experience_errors: action.errors,
                staff_experience: []
            };
        }

        /** end::List staff Experiences coupon codes redux */

        /** begin::Create staff experience redux */
        case CREATE_STAFF_EXPERIENCE_REQUEST: {
            return {
                ...state,
                staff_experience_loading: true,
                staff_experience_errors: {}
            };
        }
        case CREATE_STAFF_EXPERIENCE_SUCCESS: {
            return {
                ...state,
                staff_experience_loading: false,
                staff_experience_errors: {},
            };
        }
        case CREATE_STAFF_EXPERIENCE_FAILURE: {
            return {
                ...state,
                staff_experience_loading: false,
                staff_experience_errors: action.errors
            };
        }
        /** end::Create staff experience redux */

        /** begin::Update staff experience redux */
        case UPDATE_STAFF_EXPERIENCE_REQUEST: {
            return {
                ...state,
                update_staff_experience_loading: true,
                update_staff_experience_errors: {}
            };
        }
        case UPDATE_STAFF_EXPERIENCE_SUCCESS: {
            let staffExperienceArray = [...state.staff_experience];
            const staffExperienceIndex = staffExperienceArray.findIndex(staff_experience => staff_experience.id === action.data.id);
            if (staffExperienceIndex !== -1) {
                staffExperienceArray[staffExperienceIndex] = action.data;
            }

            return {
                ...state,
                staff_experience: staffExperienceArray,
                update_staff_experience_loading: false,
                update_staff_experience: action.data,
                update_staff_experience_errors: {}
            };
        }
        case UPDATE_STAFF_EXPERIENCE_FAILURE: {
            return {
                ...state,
                update_staff_experience_loading: false,
                update_staff_experience_errors: action.errors
            };
        }
        /** end::Update staff experience redux */

        /** begin::Delete staff experience redux */
        case DELETE_STAFF_EXPERIENCE_REQUEST: {
            return {
                ...state,
                delete_staff_experience_loading: true,
                delete_staff_experience_errors: {}
            };
        }
        case DELETE_STAFF_EXPERIENCE_SUCCESS: {
            
            let staffExperienceArray = [...state.staff_experience];
            const StaffExperienceFilter = staffExperienceArray.filter(staff_experience => staff_experience.id !== action.data.id)

            return {
                ...state,
                staff_experience: StaffExperienceFilter,
                delete_staff_experience_loading: false,
                delete_staff_experience: action.data,
                delete_staff_experience_errors: {},
            };
        }
        case DELETE_STAFF_EXPERIENCE_FAILURE: {
            return {
                ...state,
                delete_staff_experience_loading: false,
                delete_staff_experience_errors: action.errors
            };
        }
        /** end::Delete staff experience redux */


        default:
            return state;
    }
}