/**
 =========================================================
 * Akshay's Template - v0.0.0
 =========================================================
 *
 * Created by Akshay N on 01 January 2022
 */

import colors from "../../base/colors";
import typography from "../../base/typography";
import pxToRem from "../../../functions/pxToRem";

const { text, info, secondary, transparent } = colors;
const { size } = typography;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  base: {
    backgroundColor: transparent.main,
    minHeight: pxToRem(37),
    color: text.main,
    boxShadow: "none",
    padding: `${pxToRem(9)} ${pxToRem(24)}`,
    "&:hover": {
      backgroundColor: transparent.main,
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: "none",
    },
    "&:active, &:active:focus, &:active:hover": {
      opacity: 0.85,
      boxShadow: "none",
    },
    "&:disabled": {
      boxShadow: "none",
    },
    "& .material-icon, .material-icons-round, svg": {
      fontSize: size.xs,
    },
  },
  small: {
    minHeight: pxToRem(40),
    padding: `${pxToRem(6)} ${pxToRem(18)}`,
    fontSize: size.xs,
    "& .material-icon, .material-icons-round, svg": {
      fontSize: size.xs,
    },
  },
  large: {
    minHeight: pxToRem(48),
    padding: `${pxToRem(12)} ${pxToRem(64)}`,
    fontSize: size.sm,
    "& .material-icon, .material-icons-round, svg": {
      fontSize: size.lg,
    },
  },
  primary: {
    color: info.main,
    "&:hover": {
      color: info.main,
    },
    "&:focus:not(:hover)": {
      color: info.focus,
      boxShadow: "none",
    },
  },
  secondary: {
    color: secondary.main,
    "&:hover": {
      color: secondary.main,
    },
    "&:focus:not(:hover)": {
      color: secondary.focus,
      boxShadow: "none",
    },
  },
};
