module.exports = {
    /**
     * function to validate the registration form inputs
     */
    validate: (name, value, data = {}) => {
        const errors = {};
        switch (name) {

            case 'status': {
                errors.status = '';
                if (value === '')
                    errors.status = 'Select Status';
                break;
            } case 'name': {
                errors.name = '';
                if (value === '')
                    errors.name = 'Enter Name';
                break;
            } default: {
                errors[name] = '';
                break;
            }

        }
        return errors;
    }
}