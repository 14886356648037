import { apiServices } from '../../_helpers';


export const PermissionServices = {

    /**
     * Service to add permission
     * @author Jasin 
     */
    addPermission: (params) => {
        return apiServices.post('/agent/permission/add', params)
            .then((response) => { return response; })
    },

    /**
     * Service to update permission
     * @author Jasin 
     */
    updatePermission: (params) => {
        return apiServices.put('/agent/permission/update', params)
            .then((response) => { return response; })
    },
    
    /**
     * Service to change permission status
     * @author Jasin 
     */
    changePermissionStatus: (params) => {
        return apiServices.put('/agent/permission/change-status', params)
            .then((response) => { return response; })
    },

    /**
     * Service for permission select list
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
    */
    permissionSelectList: (params) => {
        return apiServices.post('agent/permission/permission-select-list', params)
            .then((response) => { return response; })
    },

    /**
     * Service for permissions list
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
    */
    getPermissionsList: (params) => {
        return apiServices.post('agent/permission/list', params)
            .then((response) => { return response; })
    },
};
