module.exports = {
    /**
     * function to validate the registration form inputs
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author Naveen
     * @created_at 21 Sept 2022
     */
    validate: (name, value, data = {}) => {
        //eslint-disable-next-line
        const errors = {};
        switch (name) {
            case 'designation': {
                errors.designation = '';
                if (value === '')
                    errors.designation = 'Select Designation.';
                break;
            } case 'joining_date': {
                errors.joining_date = '';
                if (value === '')
                    errors.joining_date = 'Enter Joined Date';
                break;
            } case 'organization': {
                errors.organization = '';
                if (value === '')
                    errors.organization = 'Enter Organization Name.';
                break;
            } case 'location': {
                errors.location = '';
                if (value === '')
                    errors.location = 'Enter Location.';
                break;
            } case 'ctc': {
                errors.ctc = '';
                if (value === '')
                    errors.ctc = 'Enter CTC.';
                break;
            } case 'resigning_date': {
                errors.resigning_date = '';
                if (value === '')
                    errors.resigning_date = 'Enter Resigned Date.';
                break;
            } case 'remark': {
                errors.remark = '';
                if (value === '')
                    errors.remark = 'Enter Remark.';
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
}