module.exports = {
    /**
     * function to validate the forgot password api
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author Akshay N
     */
    validate: (name, value, data = {}) => {
        //eslint-disable-next-line
        const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        const errors = { email: '', password: '' };
        switch (name) {
            case 'email': {
                errors.email = validEmailRegex.test(value) ? '' : 'Email provided is invalid.';
                if (value === '')
                    errors.email = 'Enter an email address.';
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
}