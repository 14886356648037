import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';

import { GlobalReducer, SignInReducer, RegisterReducer, UserReducer, ForgotPasswordReducer, DashboardReducer, NotificationReducer, RoleReducer, PermissionReducer, StaffExperienceReducer, DesignationReducer, SalaryLogReducer, LeavesReducer, AttendanceReducer, CompaniesReducer, StaffSkillReducer } from './reducers';

const loggerMiddleware = createLogger({ predicate: () => process.env.NODE_ENV !== 'production' });
const rootReducer = combineReducers({ GlobalReducer, SignInReducer, RegisterReducer, UserReducer, ForgotPasswordReducer, DashboardReducer, NotificationReducer, RoleReducer, PermissionReducer, StaffExperienceReducer, DesignationReducer, SalaryLogReducer, LeavesReducer, AttendanceReducer, CompaniesReducer, StaffSkillReducer })

export const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    )
);