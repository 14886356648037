import { GlobalAction } from ".";
import { StaffExperienceServices } from "../services";

export const CREATE_STAFF_EXPERIENCE_REQUEST = 'CREATE_STAFF_EXPERIENCE_REQUEST';
export const CREATE_STAFF_EXPERIENCE_SUCCESS = 'CREATE_STAFF_EXPERIENCE_SUCCESS';
export const CREATE_STAFF_EXPERIENCE_FAILURE = 'CREATE_STAFF_EXPERIENCE_FAILURE';

export const STAFF_EXPERIENCE_REQUEST = 'STAFF_EXPERIENCE_REQUEST';
export const STAFF_EXPERIENCE_SUCCESS = 'STAFF_EXPERIENCE_SUCCESS';
export const STAFF_EXPERIENCE_FAILURE = 'STAFF_EXPERIENCE_FAILURE';

export const UPDATE_STAFF_EXPERIENCE_REQUEST = 'UPDATE_STAFF_EXPERIENCE_REQUEST';
export const UPDATE_STAFF_EXPERIENCE_SUCCESS = 'UPDATE_STAFF_EXPERIENCE_SUCCESS';
export const UPDATE_STAFF_EXPERIENCE_FAILURE = 'UPDATE_STAFF_EXPERIENCE_FAILURE';

export const DELETE_STAFF_EXPERIENCE_REQUEST = 'DELETE_STAFF_EXPERIENCE_REQUEST';
export const DELETE_STAFF_EXPERIENCE_SUCCESS = 'DELETE_STAFF_EXPERIENCE_SUCCESS';
export const DELETE_STAFF_EXPERIENCE_FAILURE = 'DELETE_STAFF_EXPERIENCE_FAILURE';

export const StaffExperienceAction = {

    /**
    * Action for create staff experiences
    * @param {Object} params - The params which are used for the api
    * @author Naveen  
    * @created_at 21 September 2022
    */
    createStaffExperience: (params) => {
        return dispatch => {
            dispatch(request(params));
            return StaffExperienceServices.createStaffExperience(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: CREATE_STAFF_EXPERIENCE_REQUEST, request: request } }
        function success(request, response) { return { type: CREATE_STAFF_EXPERIENCE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CREATE_STAFF_EXPERIENCE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action list all Staff Experience
     * @param {Object} params - The params which are used for the api
     * @author Naveen  
     * @created_at 21 September 2022
     */
    listStaffExperience: (params) => {
        return dispatch => {
            dispatch(request(params));
            return StaffExperienceServices.listStaffExperience(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: STAFF_EXPERIENCE_REQUEST, request: request } }
        function success(request, response) { return { type: STAFF_EXPERIENCE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: STAFF_EXPERIENCE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for update staff experience
     * @param {Object} params - The params which are used for the api
     * @author Naveen  
     * @created_at 21 September 2022
    */
    updateStaffExperience: (params) => {
        return dispatch => {
            dispatch(request(params));
            return StaffExperienceServices.updateStaffExperience(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: UPDATE_STAFF_EXPERIENCE_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_STAFF_EXPERIENCE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_STAFF_EXPERIENCE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for deleting an staff experience
     * @param {Object} params - The params which are used for the api
     * @author Naveen  
     * @created_at 21 September 2022
    */
    deleteStaffExperience: (params) => {
        return dispatch => {
            dispatch(request(params));
            return StaffExperienceServices.deleteStaffExperience(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: DELETE_STAFF_EXPERIENCE_REQUEST, request: request } }
        function success(request, response) { return { type: DELETE_STAFF_EXPERIENCE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: DELETE_STAFF_EXPERIENCE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    }

}