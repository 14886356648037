import { Box, CircularProgress, Divider, Grid, Typography, Stack } from '@mui/material'
import React from 'react'
import { useSelector } from "react-redux";
import { Card, CardContent } from '../../../_components/card'
import { GeneralButton } from '../../../_components/controls/general-button/GeneralButton';
import { PageHeader } from '../../../_components';
import { PostAuthRoutes } from '../../../routes';
import { SalaryLogsDataTable } from '.'

function SalaryLogCard() {

    /** Redux actions and state */
    const { user_loading: isLoading, user } = useSelector((state) => state.UserReducer);

    if (!isLoading)
        return (
            <React.Fragment>
                <Box>
                    <PageHeader
                        title={PostAuthRoutes('salary_logs_history').name}
                    />
                    <Card>
                        <CardContent>

                            <Grid container spacing={3} display={`flex`} alignItems={`center`} justifyContent={`center`} sx={{ paddingLeft: '10px', paddingTop: '30px', paddingRight: '10px' }}>

                                <Grid item xs={12} sm={12} md={8} lg={8} xl={12} >
                                    <SalaryLogsDataTable />
                                </Grid>

                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
            </React.Fragment>
        )
    else
        return (
            <Card>
                <CardContent>
                    <CircularProgress />
                </CardContent>
            </Card>
        )
}

export { SalaryLogCard }