import React from 'react'
import PropTypes from 'prop-types'
import { Button, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    'root': {
        marginLeft: theme.spacing(1)
    }
}));


function ModalFooterSaveBtn({ text, className, tabIndex, loading = false, disabled, props }) {
    const classes = useStyles();
    return (
        <Button
            className={classes.root}
            type="submit"
            variant="contained"
            color={"primary"}
            tabIndex={tabIndex}
            disabled={loading || disabled}
            startIcon={loading ? (<CircularProgress size={14} />) : ('')}
            {...props}
        >
            {text}
        </Button>
    )
}


ModalFooterSaveBtn.defaultProps = {
    className: "",
    text: "Save",
}


ModalFooterSaveBtn.propTypes = {
    className: PropTypes.string,
    tabIndex: PropTypes.number,
    text: PropTypes.string.isRequired,
    isProcessing: PropTypes.bool,
}

export { ModalFooterSaveBtn }
