import { makeStyles } from "@mui/styles";
import colors from "../../../_theme/theme-light/base/colors";

const useStyles = makeStyles({
	notFoundWrapper: {
		minHeight: `100vh`,
		'& img': {
			width: `300px`,
			marginBottom: `10px`
		}
	},
	textWrongTurn: {
		color: colors.primary.main,
		marginLeft: `15px`
	},
	textNotFound: {
		color: colors.error.main
	}
})

export { useStyles };
