import { apiServices } from '../../_helpers';

export const AttendanceServices = {

    /**
     * Service for create Attendance Request
     * @param {Object} params - The params which are used for the api
     * @author Naveen  
     * @created_at 10 Oct 2022 
     */
    createAttendance: (params) => {
        return apiServices.post('/agent/attendance/create', params)
            .then((response) => { return response; })
    },

    /**
     * Action to get all staff attendance
     * @param {Object} params - The params which are used for the api
     * @author Naveen  
     * @created_at 10 October 2022
    */
    getAllStaffAttendance: (params) => {
        return apiServices.post('agent/attendance/staff-attendance', params)
            .then((response) => { return response; })
    },

}
