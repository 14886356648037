import { GlobalAction } from ".";
import { StaffSkillServices } from "../services";

export const CREATE_STAFF_SKILL_REQUEST = "CREATE_STAFF_SKILL_REQUEST";
export const CREATE_STAFF_SKILL_SUCCESS = "CREATE_STAFF_SKILL_SUCCESS";
export const CREATE_STAFF_SKILL_FAILURE = "CREATE_STAFF_SKILL_FAILURE";

export const STAFF_SKILL_REQUEST = "STAFF_SKILL_REQUEST";
export const STAFF_SKILL_SUCCESS = "STAFF_SKILL_SUCCESS";
export const STAFF_SKILL_FAILURE = "STAFF_SKILL_FAILURE";

export const DELETE_STAFF_SKILL_REQUEST = "DELETE_STAFF_SKILL_REQUEST";
export const DELETE_STAFF_SKILL_SUCCESS = "DELETE_STAFF_SKILL_SUCCESS";
export const DELETE_STAFF_SKILL_FAILURE = "DELETE_STAFF_SKILL_FAILURE";

export const StaffSkillAction = {
  /**
    * Action for create staff skill
    * @param {Object} params - The params which are used for the api
    * @author Naveen  
    * @created_at 07 Mar 2023
    */
  createStaffSkill: params => {
    return dispatch => {
      dispatch(request(params));
      return StaffSkillServices.createStaffSkill(params).then(
        response => {
          dispatch(GlobalAction.showToastMessage(response.message));
          return dispatch(success(params, response));
        },
        error => {
          return dispatch(failure(request, error));
        }
      );
    };

    function request(request) {
      return { type: CREATE_STAFF_SKILL_REQUEST, request: request };
    }
    function success(request, response) {
      return {
        type: CREATE_STAFF_SKILL_SUCCESS,
        request: request,
        message: response.message,
        data: response.data,
        status: response.status,
        status_code: response.status_code
      };
    }
    function failure(request, error) {
      return {
        type: CREATE_STAFF_SKILL_FAILURE,
        request: request,
        data: error.data,
        errors: error.errors,
        message: error.message,
        status: error.status,
        status_code: error.status_code
      };
    }
  },

  /**
     * Action list all Staff Skills
     * @param {Object} params - The params which are used for the api
     * @author Naveen  
     * @created_at 07 Mar 2023
     */
  listStaffSkills: params => {
    return dispatch => {
      dispatch(request(params));
      return StaffSkillServices.listStaffSkills(params).then(
        response => {
          return dispatch(success(request, response));
        },
        error => {
          return dispatch(failure(request, error));
        }
      );
    };

    function request(request) {
      return { type: STAFF_SKILL_REQUEST, request: request };
    }
    function success(request, response) {
      return {
        type: STAFF_SKILL_SUCCESS,
        request: request,
        message: response.message,
        data: response.data,
        status: response.status,
        status_code: response.status_code
      };
    }
    function failure(request, error) {
      return {
        type: STAFF_SKILL_FAILURE,
        request: request,
        data: error.data,
        errors: error.errors,
        message: error.message,
        status: error.status,
        status_code: error.status_code
      };
    }
  },

  /**
     * Action for deleting an staff skill
     * @param {Object} params - The params which are used for the api
     * @author Naveen  
     * @created_at 07 Mar 2023
    */
  deleteStaffSkill: params => {
    return dispatch => {
      dispatch(request(params));
      return StaffSkillServices.deleteStaffSkill(params).then(
        response => {
          dispatch(GlobalAction.showToastMessage(response.message));
          return dispatch(success(params, response));
        },
        error => {
          return dispatch(failure(params, error));
        }
      );
    };

    function request(request) {
      return { type: DELETE_STAFF_SKILL_REQUEST, request: request };
    }
    function success(request, response) {
      return {
        type: DELETE_STAFF_SKILL_SUCCESS,
        request: request,
        message: response.message,
        data: response.data,
        status: response.status,
        status_code: response.status_code
      };
    }
    function failure(request, error) {
      return {
        type: DELETE_STAFF_SKILL_FAILURE,
        request: request,
        data: error.data,
        errors: error.errors,
        message: error.message,
        status: error.status,
        status_code: error.status_code
      };
    }
  }
};
