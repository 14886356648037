import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PostAuthRoutes } from "../../../../routes";
import queryString from 'query-string';
import { DataTable } from '../../../../_components/data-table/DataTable';
import { useHistory } from "react-router-dom";
import { SalaryLogAction } from '../../../../redux/actions'

function PayslipDataTable({ statusUpdateModal, filterAction, filter, ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Redux actions and state */
    const { per_page, total, user_loading: isLoading } = useSelector((state) => state.UserReducer);
    const { salary_log_loading: isSalaryLogLoading, salary_log } = useSelector((state) => state.SalaryLogReducer);
    const listSalaryLog = (params) => dispatch(SalaryLogAction.listSalaryLog(params));

    /** Initialize and declare state */
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState({ '_id': 'asc' });
    const [search] = useState('');
    const [payload, setPayload] = useState({ filter });

    const columns = [
        { name: "No", center: true, width: '4%', cell: (row, i) => ((per_page * (page - 1)) + (i + 1)) },
        { name: "Salary", selector: (row, i) => (row.basic_salary), sortField: 'account_number', sortable: true, center: true, width: '6%' },
        { name: "Dearness Allowance", selector: (row, i) => (row.dearness_allowance), sortField: 'dearness_allowance', sortable: true, wrap: true, style: { whiteSpace: `unset` }, center: true, width: '9%' },
        { name: "Conveyance Allowance", selector: (row, i) => (row.conveyance_allowance), sortField: 'conveyance_allowance', sortable: true, wrap: true, style: { whiteSpace: `unset` }, center: true, width: '10%' },
        { name: "HRA", selector: (row, i) => (row.hra), sortField: 'hra', sortable: true, center: true, width: '5%' },
        { name: "Medical Allowance", selector: (row, i) => (row.medical_allowance), sortField: 'medical_allowance', sortable: true, wrap: true, style: { whiteSpace: `unset` }, center: true, width: '9%' },
        { name: "Special Allowance", selector: (row, i) => (row.special_allowance), sortField: 'special_allowance', sortable: true, wrap: true, style: { whiteSpace: `unset` }, center: true, width: '9%' },
        { name: "Reimbursement", selector: (row, i) => (row.reimbursement), sortField: 'reimbursement', sortable: true, center: true, width: '9%' },
        { name: "Professional Tax", selector: (row, i) => (row.professional_tax), sortField: 'professional_tax', sortable: true, center: true, width: '10%' },
        { name: "Tax Deduction", selector: (row, i) => (row.tax_deduction_at_source), sortField: 'tax_deduction_at_source', sortable: true, wrap: true, style: { whiteSpace: `unset` }, center: true, width: '9%' },
        { name: "Employee Providant Fund", selector: (row, i) => (row.employee_providant_fund), sortField: 'employee_providant_fund', sortable: true, wrap: true, style: { whiteSpace: `unset` }, center: true, width: '12%' },
        { name: "Net Salary", selector: (row, i) => (row.net_salary), sortField: 'net_salary', sortable: true, center: true, width: '8%' },
    ];

    useEffect(() => {
        setPayload({ filter });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])

    useEffect(() => {

        const queryParam = { ...filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });

        queryParam.p = page;
        if (search) queryParam.q = search; else delete queryParam.q;
        if (filter.date) queryParam.date = filter.date; else delete queryParam.date;
        history.push({
            pathname: PostAuthRoutes('payslip').path,
            search: `?${queryString.stringify(queryParam)}`
        });
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter])

    /**
     * function to fetch user list
     * @return json 
     * @author Naveen
     * @created_at 21 Sept 2022
     */
    const getData = async () => {
        await listSalaryLog(payload);
    }

    /**
     * function to sort columns
     * @return view
     * @author Naveen
     * @created_at 21 Sept 2022 
     */
    const handleSort = (column, sortDirection) => {
        const sortField = column.sortField;
        setSort({ [sortField]: sortDirection })
    }

    /**
     * function to select columns
     * @return view
     * @author Naveen
     * @created_at 21 Sept 2022 
     */
    const handleSelect = (data) => {
        console.log(data.selectedRows)
    }

    return (
        <DataTable
            loading={isLoading}
            columns={columns}
            data={salary_log.length === undefined ? [salary_log] : salary_log}
            total={total}
            per_page={per_page}
            setPage={page => setPage(page)}
            handleSelect={handleSelect}
            handleSort={handleSort}
        />

    )
}
export { PayslipDataTable }