
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Route, Switch } from "react-router-dom";

import { cookieServices } from '../../_helpers';
import { PostAuthMainComponents, PreAuthRoutes } from '../../routes';

import { Box } from '../../_components';

import { Header } from './'
import { useStyles } from '.'
import { SideBar } from './sidebar';
import ability from '../../_helpers/permission/UserAbility';

const PostSignIn = () => {

    const history = useHistory();

    /** Redux actions and state */
    const { isDrawerOpen } = useSelector((state) => state.GlobalReducer);
    const { user } = useSelector((state) => state.UserReducer);

    const menuRoutes = Object.values(PostAuthMainComponents());

    useEffect(() => {

        if (!cookieServices.get('accessToken')) {
            history.push({
                pathname: PreAuthRoutes('login').path
            });
        }

    }, [history])

    const classes = useStyles();

    return (
        <Box >
            <Header />
            <SideBar />
            <Box component="main" className={`${classes.mainComponent} ${isDrawerOpen ? classes.drawerOpen : ``}`}>
                <Switch>
                    {menuRoutes.map((route, index) => (
                        <Route exact key={index} path={route.path} component={((!route.menuPermission) || (user.role === 'developer') || (ability.can(route.menuPermission, 'all'))) ? route.component : null} />
                    ))}
                </Switch>
            </Box>
        </Box>
    )
}

export { PostSignIn };

