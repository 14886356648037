import { SalaryLogServices } from "../services";

export const SALARY_LOG_REQUEST = 'SALARY_LOG_REQUEST';
export const SALARY_LOG_SUCCESS = 'SALARY_LOG_SUCCESS';
export const SALARY_LOG_FAILURE = 'SALARY_LOG_FAILURE';

export const SalaryLogAction = {

    /**
     * Action list all Salary Log
     * @param {Object} params - The params which are used for the api
     * @author Naveen  
     * @created_at 21 September 2022
     */
    listSalaryLog: (params) => {
        return dispatch => {
            dispatch(request(params));
            return SalaryLogServices.listSalaryLog(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: SALARY_LOG_REQUEST, request: request } }
        function success(request, response) { return { type: SALARY_LOG_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: SALARY_LOG_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
}