import {
    DESIGNATION_SELECT_LIST_REQUEST, DESIGNATION_SELECT_LIST_SUCCESS, DESIGNATION_SELECT_LIST_FAILURE,
} from "../actions";

const initialState = {
    designation_select_list_loading: false, designation_select_list: [], designation_select_list_errors: {},
};

export function DesignationReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::Designation select list redux */
        case DESIGNATION_SELECT_LIST_REQUEST: {
            return {
                ...state,
                designation_select_list_loading: true,
                designation_select_list_errors: {}
            };
        }
        case DESIGNATION_SELECT_LIST_SUCCESS: {

            return {
                ...state,
                designation_select_list_loading: false,
                designation_select_list_errors: {},
                designation_select_list: action.data

            };

        }
        case DESIGNATION_SELECT_LIST_FAILURE: {
            return {
                ...state,
                designation_select_list_loading: false,
                designation_select_list_errors: action.errors,
                designation_select_list: []
            };
        }
        /** end::Designation select list redux */

        default:
            return state;

    }
}