import React from 'react';
import { Box, CardContent, Grid, Skeleton, Stack } from '@mui/material';

function PreviousCompaniesCardLoader({ ...props }) {

    var rows = [];
    for (var i = 0; i < 4; i++) {
        rows.push(
            <Grid container spacing={3} display={`flex`} alignItems={`center`} justifyContent={`center`} >
                <Grid item xs={12} sm={12} md={8} lg={12} xl={12} >
                    <Grid container spacing={0} >
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={12} >
                            <Stack direction="row" justifyContent="left" alignItems="center" spacing={1} padding='10px'>
                                <Box display="flex" justifyContent="center" alignItems="center" >
                                    <Skeleton animation="wave" variant="circular" width={50} height={50} paddingBottom={"15px"} />
                                </Box>
                                <Box display="flex" justifyContent="space-between" alignItems="center" padding={0}>
                                    <Stack direction="column" justifyContent="left" alignItems="center" spacing={1}>
                                        <Skeleton animation="wave" height={40} width={300} style={{ marginBottom: 5 }} />
                                        <Skeleton animation="wave" height={15} width={300} style={{ marginBottom: 5 }} />
                                    </Stack>
                                </Box>
                            </Stack>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return <React.Fragment> {rows} </React.Fragment>
}

export { PreviousCompaniesCardLoader };