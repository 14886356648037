import React from 'react'
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const StyledLink = styled(({ ...props }) => (<Link {...props} />))`
    text-decoration: none;
`;

function RouteLink({ to, label, ...props }) {

    return (
        <StyledLink to={to} {...props}>{label}</StyledLink>
    )
}

export { RouteLink }
