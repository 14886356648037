import { Chip, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormSelect } from "../../../_components/form";
import { Modal, ModalBody, ModalFooterCloseBtn, ModalFooterSaveBtn, ModalHeader } from "../../../_components/modal";
import { handleInputChange } from "../../../_helpers";

const inputs = { filter_status: '' };

function RoleFilter({ ...props }) {

    /** Initialize and declare state */
    const { show, closeModal, applyFilter, roleFilter } = props;
    const [data, setData] = useState({ ...inputs });
    const [filteredData, setFilteredData] = useState({});

    // Declare filter status select options
    const filterStatus = [
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Inactive' }
    ]

    /**
     * function to close role filter modal
     * @param {string} status Entered status on role filter modal
     * @param {string} month Entered month on role filter modal 
     * @return view
     * @author 
     * @created_at 14 June 2022 
     */
    const onClose = () => {
        setData({ filter_status: '' });
        closeModal(false);
    }

    /**
     * function to clear role filter modal
     * @param {string} status Entered status on role filter modal
     * @param {string} month Entered month on role filter modal 
     * @return view
     * @author  
     * @created_at 14 June 2022 
     */
    const onClear = () => {
        setData({ filter_status: '' });
        applyFilter({ filter_status: '' });
        closeModal(false);
    }

    useEffect(() => {
        if (show === true) {
            setData({ filter_status: roleFilter.filter_status })
        }

        let filtered = {};
        Object.keys(roleFilter).forEach(function (item, i) {
            if (roleFilter[item])
                filtered[item] = roleFilter[item];
        });
        setFilteredData({ ...filtered })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, roleFilter])

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Naveen
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author  
     * @created_at 14 June 2022 
     */
    function handleSubmit(e) {
        e.preventDefault();
        applyFilter(data);
        closeModal(false);
    }

     /**
     * function to clear all data entered on role filter modal
     * @return view
     * @author  
     * @created_at 14 June 2022 
     */
    const clearFilterItem = (item) => {
        const clearItem = { ...data };
        clearItem[item] = '';
        setData({ ...clearItem });
        applyFilter({ ...clearItem })
    }

    return (
        <React.Fragment>
            {
                Object.keys(filteredData).length > 0 &&
                (
                    <Grid container mb={2}>
                        <Grid item xs={12} sm={12} lg={12} xl={12} >
                            <Stack direction='row' spacing={1} >
                                {
                                    Object.keys(filteredData).map((item, i) => {
                                        if (item === 'status') {
                                            const status = filterStatus.find(function (status) { return status.value === filteredData[item]; });
                                            return <Chip key={i} label={status.label} onDelete={() => clearFilterItem(item)} />
                                        } else {
                                            return <Chip keys={i} label={filteredData[item]} onDelete={() => clearFilterItem(item)} style={{ textTransform: `capitalize` }} />
                                        }
                                    })
                                }

                            </Stack>
                        </Grid>
                    </Grid>
                )
            }

            <Modal isDialogOpen={show} onClose={onClose} maxWidth='xs'>
                <form onSubmit={handleSubmit} noValidate >
                    <ModalHeader id="roleFilter" onClose={onClose} title='Filter' />
                    <ModalBody>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormSelect
                                    tabIndex={1}
                                    label={`Status`}
                                    name={`filter_status`}
                                    placeholder={`Select status`}
                                    onChange={handleChange}
                                    data={filterStatus}
                                    value={data.filter_status}
                                />
                            </Grid>

                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                <Stack direction="row" justifyContent="left" alignItems="center" spacing={1}>
                                    <ModalFooterSaveBtn tabIndex={4} text='Filter' />
                                    <ModalFooterCloseBtn tabIndex={3} onClose={onClear} text='Clear' />
                                </Stack>
                            </Grid>
                        </Grid>
                    </ModalBody>
                </form>
            </Modal>
        </React.Fragment>
    );
}

export { RoleFilter }