import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { Box } from "../../../_components/box";
import { Typography } from "../../../_components/typography";

function CopyRight({ light }) {

  return (
    <Box position="absolute" width="100%" bottom={0} py={4}>
      <Container>
        <Box width="100%" display="flex" flexDirection={{ xs: "column", lg: "row" }} justifyContent="space-between" alignItems="center" px={1.5} >
          <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" color={light ? "white" : "text"} >
            &copy; {new Date().getFullYear()} Made by <Link href="https://www.confyans.com/" target="_blank"><Typography variant="button" color={light ? "white" : "dark"}>&nbsp;Confyans&nbsp;  </Typography>  </Link>for a better web experience.
          </Box>
          <Box component="ul" sx={({ breakpoints }) => ({ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", listStyle: "none", mt: 3, mb: 0, p: 0, [breakpoints.up("lg")]: { mt: 0 } })} >
            <Box component="li" pr={2} lineHeight={1}>
              <Link href="https://www.confyans.com/" target="_blank">
                <Typography variant="button" fontWeight="regular" color={light ? "white" : "dark"}> Offers </Typography>
              </Link>
            </Box>
            <Box component="li" pr={2} lineHeight={1}>
              <Link href="https://www.confyans.com/" target="_blank">
                <Typography variant="button" fontWeight="regular" color={light ? "white" : "dark"}> About Us </Typography>
              </Link>
            </Box>
            <Box component="li" pr={2} lineHeight={1}>
              <Link href="https://www.confyans.com/" target="_blank">
                <Typography variant="button" fontWeight="regular" color={light ? "white" : "dark"}> Support Center </Typography>
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

// Setting default props for the CopyRight
CopyRight.defaultProps = {
  light: true,
};

// Typechecking props for the CopyRight
CopyRight.propTypes = {
  light: PropTypes.bool,
};

export { CopyRight };
