import React from 'react'
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material'
import { Box } from '../../_components/box';
import { TodayInformation } from './today-informations';
import { PreviousCompanies } from './previous-companies';
import { AbilityCan } from '../../_helpers/permission/AbilityCan';

function Dashboard() {

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);

    return (
        <React.Fragment>
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
                        <AbilityCan I='leaves_applied' passThrough={(user.role === 'developer') ? true : false}>
                            <TodayInformation />
                        </AbilityCan>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
                        <AbilityCan I='previous_companies' passThrough={(user.role === 'developer') ? true : false}>
                            <PreviousCompanies />
                        </AbilityCan>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    )
}

export { Dashboard }