import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { GoogleLogin } from 'react-google-login'

import { handleInputChange, validateForm, GOOGLE_CLIENT_ID } from '../../../_helpers';
import { PostAuthRoutes, PreAuthRoutes } from '../../../routes';

import { FormInput, FormPassword } from '../../../_components/form';
import { SubmitButton, RouteLink } from '../../../_components/controls';
import { Button, Stack } from '@mui/material'
import { Grid, Typography } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { PreSignIn } from '../../../_layouts/pre-signin';

import { SignInAction } from '../../../redux/actions';
import { validate } from '.'
import { gapi } from 'gapi-script';

// Initialize form input values to null
const inputs = { email: '', password: '' };

function SignIn() {

    /** Initialize plugins and variables */
    const history = useHistory();
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { loading: isLoading, errors: signinErrors } = useSelector((state) => state.SignInReducer);
    const signIn = (params) => dispatch(SignInAction.signIn(params));
    const socialSignIn = (params) => dispatch(SignInAction.socialSignIn(params));

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, remember_me: '' });
    const [action, setAction] = useState({ isSubmitted: false });

    useEffect(() => {
        setErrors({ ...signinErrors });
    }, [signinErrors])

    /**
     * function to handle input changes and alter the value
     * @param object e input object with name and value
     * @author Akshay N
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit
     * @param object e form object
     * @author Akshay N
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchSignInAction();
    }

    /**
     * function to dispatch sign in
     * @author Akshay N
     */
    async function dispatchSignInAction() {
        const isSignin = await signIn(data);
        if (isSignin.status === 1) {
            let redirectPath = PostAuthRoutes('home').path;
            history.push(redirectPath);
        }
    }

    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: GOOGLE_CLIENT_ID,
                scope: 'email',
            });
        }
        gapi.load('client:auth2', start);
    }, []);

    /**
     * function to social sign in
     * @author Naveen
     */
    const googleSuccess = async (res) => {

        let authDetails = {}

        authDetails.result = res?.profileObj;
        authDetails.token = res?.tokenId;

        const isSocialSignIn = await socialSignIn(authDetails);
        if (isSocialSignIn.status === 1) {
            let redirectPath = PostAuthRoutes('home').path;
            history.push(redirectPath);
        }
    }

    /**
     * Function Shows UnsuccessFull Google signIn
     * @author Naveen
     */
    const googleFailure = (error) => {
        console.log("Google Sign In UnsuccessFull, Try Again Later")
    }

    return (
        <PreSignIn >
            <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2} sx={{ p: `40px` }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" alignItems="center" justifyContent="center">
                        <Stack direction="column" justifyContent="left" alignItems="center" spacing={1}>
                            <Typography variant="h5">{`${PreAuthRoutes('login').name}`}</Typography>
                            {errors.active_status &&
                                <Typography component="h1" variant="caption" color="red">{`${errors.active_status}`}</Typography>
                            }
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormInput
                            tabIndex={1}
                            label='Email'
                            name='email'
                            value={data.email}
                            error={action.isSubmitted && errors.email ? errors.email : ''}
                            onChange={handleChange}
                            autoFocus={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormPassword
                            tabIndex={2}
                            label='Password'
                            name='password'
                            value={data.password}
                            error={action.isSubmitted && errors.password ? errors.password : ''}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" alignItems="center" justifyContent="left" >
                        <RouteLink to={PreAuthRoutes('forgot_password').path} label={PreAuthRoutes('forgot_password').name} tabIndex={4} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" alignItems="center" justifyContent="space-between" >
                        <RouteLink to={PreAuthRoutes('register').path} label={PreAuthRoutes('register').name} tabIndex={5} />
                        <SubmitButton label={`${PreAuthRoutes('login').name}`} loading={isLoading} tabIndex={3} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" alignItems="center" justifyContent="center" >

                        <GoogleLogin
                            clientId={GOOGLE_CLIENT_ID}
                            render={renderProps => (
                                <Button fullWidth onClick={renderProps.onClick} disabled={renderProps.disabled}><GoogleIcon />Sign in with Google</Button>
                            )}
                            buttonText="Login"
                            onSuccess={googleSuccess}
                            onFailure={googleFailure}
                        />

                    </Grid>
                </Grid>
            </form>
        </PreSignIn>
    );
}

export { SignIn };
