import { AccessTime, CircleNotifications } from '@mui/icons-material'
import { Avatar, Badge, Card, CardActionArea, CardContent, CardHeader, CircularProgress, Divider, Drawer, IconButton, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NotificationAction } from '../../../../redux/actions';
import InfiniteScroll from 'react-infinite-scroll-component';
import { GeneralButton } from '../../../../_components/controls';
import { NoData } from '../../../../_components/layout';

function Notifications() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { notifications_loading: isLoading, notifications, pending_notifications } = useSelector((state) => state.NotificationReducer);
    const getNotificationList = (params) => dispatch(NotificationAction.getNotifications(params));
    const markAllRead = () => dispatch(NotificationAction.markAllRead());
    const markRead = (params) => dispatch(NotificationAction.markRead(params));

    const [payload, setPayload] = useState({ limit: 100, page: 1 });

    useEffect(() => {
        getNext() // Initially load participants list
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getNext = async () => {
        const result = await getNotificationList(payload);
        if (result.status) {
            setPayload({ ...payload, page: payload.page + 1 });
        }
    }

    const [state, setState] = useState({ right: false });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ right: open });
    };

    const markAllReadAction = async () => {
        await markAllRead();
    }

    const markReadAction = async (notification) => {
        if (notification.status === 'pending') {
            await markRead({ notification_id: notification._id });
        }
    }

    return (
        <React.Fragment>
            <IconButton onClick={toggleDrawer('right', true)}>
                <Badge badgeContent={pending_notifications} color="secondary">
                    <CircleNotifications />
                </Badge>
            </IconButton>
            <Drawer anchor={'right'} open={state['right']} onClose={toggleDrawer('right', false)}  >
                <Card sx={{ width: 384, height: `100%`, backgroundColor: `inherit`, borderRadius: 0 }} role="presentation">
                    <CardHeader title="Notifications" action={
                        <GeneralButton label='Mark all as read' size='small' variant='text' onClick={() => markAllReadAction()} />
                    }
                    />
                    <CardContent sx={{ p: 0, pt: 1, height: `100%`, pb: `48px !important` }}>
                        <InfiniteScroll dataLength={notifications.length} next={getNext} hasMore={true}>
                            {
                                notifications.length > 0 && notifications.map((notification, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <Card sx={{ height: `100%`, backgroundColor: `inherit`, borderRadius: 0 }} elevation={notification.status === 'read' ? 0 : 1} >
                                                <CardActionArea onClick={() => markReadAction(notification)}>
                                                    <CardContent sx={{ padding: 0 }}>
                                                        <Box sx={{ pl: 4, pr: 4, pt: 1, pb: 1 }} >
                                                            <Stack direction="row" alignItems="center" spacing={2} >
                                                                <Avatar src={notification.sender_profile_picture} />
                                                                <Box sx={{ width: `100%`}}>
                                                                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                                                        <Typography variant="h6">{notification.sender_info.name}</Typography>
                                                                        <Typography variant="body2" sx={{ fontSize: 10 }}> <AccessTime /> {notification.timeAgo}</Typography>
                                                                    </Stack>
                                                                    <Typography variant="body2" sx={{ fontSize: 12 }}>{notification.description}</Typography>
                                                                </Box>
                                                            </Stack>
                                                        </Box>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                            <Divider sx={{ m: 0 }} />
                                        </React.Fragment>
                                    );
                                })
                            }
                            {
                                notifications.length === 0 && !isLoading && (
                                    <Box sx={{ pl: 8, pr: 8, pt: 1, pb: 1 }}>
                                        <NoData minHeight={`80vh`} content1={`No New`} content2={`Notifications`} description={`If using a custom view, try adjusting the filters,Otherwise, create some data.`} />
                                    </Box>
                                )
                            }
                            {isLoading && <CircularProgress />}
                        </InfiniteScroll>
                    </CardContent>
                </Card>
            </Drawer>
        </React.Fragment>
    )
}

export { Notifications }