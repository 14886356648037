import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import { PostAuthRoutes } from "../../../routes";
import { handleInputChange, validateForm, APP_NAME } from "../../../_helpers";

import { Box } from "../../../_components/box";
import { SubmitButton, CancelButton } from "../../../_components/controls";
import { FormInput, FormSelect, FormDatePicker } from "../../../_components/form";
import { Card, CardContent } from "../../../_components/card";
import { Grid, Stack } from "@mui/material";
import { StaffExperienceAction, DesignationAction } from "../../../redux/actions";
import { validate } from "./validate";
import { PageHeader } from "../../../_components";

// Initialize form input values to null
const inputs = { experience_id: '', designation: '', joining_date: '', organization: '', ctc: '', resigning_date: '', remark: '', location: '' };

function CreateStaffExperience() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** staff_experience_id and details on update page */
    const location = useLocation();
    const { staff_experiences } = location.state ?? {};

    /** Redux actions and state */
    const { designation_select_list_loading: isDesignationLoading, designation_select_list } = useSelector((state) => state.DesignationReducer);
    const designationSelectList = (params) => dispatch(DesignationAction.designationSelectList(params));
    const createStaffExperience = (params) => dispatch(StaffExperienceAction.createStaffExperience(params));
    const updateStaffExperience = (params) => dispatch(StaffExperienceAction.updateStaffExperience(params));

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });

    let joining_date_array = [];
    let resigning_date_array = [];
    if (staff_experiences && staff_experiences.joining_date) {
        joining_date_array = staff_experiences.joining_date.split('-')
    }

    if (staff_experiences && staff_experiences.resigning_date) {
        resigning_date_array = staff_experiences.resigning_date.split('-')
    }

    useEffect(() => {
        designationSelectList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * function to handle input changes and modify the value
     * @author Naveen
     * @created_at 21 Sept 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    useEffect(() => {
        if (staff_experiences && staff_experiences.id) {
            setData({
                experience_id: staff_experiences.id,
                designation: `${staff_experiences.designation}`,
                joining_date: `${joining_date_array[2].slice(0, 2)}/${joining_date_array[1]}/${joining_date_array[0]}`,
                organization: staff_experiences.organization,
                location: staff_experiences.location,
                ctc: staff_experiences.ctc,
                resigning_date: `${resigning_date_array[2].slice(0, 2)}/${resigning_date_array[1]}/${resigning_date_array[0]}`,
                remark: staff_experiences.remark
            });
        }

        //eslint-disable-next-line
    }, [staff_experiences])

    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author Naveen
     * @created_at 21 Sept 2022 
     */
    function handleSubmit(e) {

        e.preventDefault();
        setAction({ isSubmitted: true });

        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        const formData = {
            "experience_id": data.experience_id,
            "designation": data.designation,
            "joining_date": data.joining_date,
            "organization": data.organization,
            "location": data.location,
            "ctc": data.ctc,
            "resigning_date": data.resigning_date,
            "remark": data.remark,
        }

        addStaffExperience(formData);
    }

    /**
     * function to create Staff Experience
     * @param {object} formData form object
     * @author Naveen
     * @created_at 21 Sept 2022
     */
    async function addStaffExperience(formData) {

        const isCreated = staff_experiences && staff_experiences.id ? await updateStaffExperience(formData) : await createStaffExperience(formData)

        if (isCreated && isCreated.status === 1) {
            let redirectPath = PostAuthRoutes('staff_experience').path;
            history.push(redirectPath);
        }

    }

    /**
     * function to cancel create/update staff experience
     * @author Naveen
     * @created_at 21 Sept 2022 
     */
    function cancelStaffExperience() {
        let redirectPath = PostAuthRoutes('staff_experience').path;
        history.push(redirectPath);
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{staff_experiences && staff_experiences.id ? `${APP_NAME} | ${PostAuthRoutes('update_staff_experience').name}` : `${APP_NAME} | ${PostAuthRoutes('create_staff_experience').name}`}</title>
            </Helmet>
            <PageHeader title={staff_experiences && staff_experiences.id ? PostAuthRoutes('update_staff_experience').name : PostAuthRoutes('create_staff_experience').name} />
            <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={3} >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Card >
                            <CardContent >
                                <Grid container spacing={2} justifyContent="flex-start" alignItems="center" >
                                    <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                                        <Grid container spacing={3}>

                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                                <FormSelect
                                                    tabIndex={4}
                                                    label={`Job Title`}
                                                    name={`designation`}
                                                    placeholder={`Select Job Title`}
                                                    onChange={handleChange}
                                                    data={designation_select_list}
                                                    error={(action.isSubmitted && (errors.designation)) ? (errors.designation) : ''}
                                                />
                                            </Grid>


                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                                <FormInput
                                                    tabIndex={4}
                                                    label='CTC'
                                                    name='ctc'
                                                    value={data.ctc}
                                                    error={(action.isSubmitted && (errors.ctc)) ? (errors.ctc) : ''}
                                                    onChange={handleChange}
                                                    maxLength={250}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                                <FormInput
                                                    tabIndex={4}
                                                    label='Organization'
                                                    name='organization'
                                                    value={data.organization}
                                                    error={(action.isSubmitted && (errors.organization)) ? (errors.organization) : ''}
                                                    onChange={handleChange}
                                                    maxLength={250}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                                <FormInput
                                                    tabIndex={4}
                                                    label='Location'
                                                    name='location'
                                                    value={data.location}
                                                    error={(action.isSubmitted && (errors.location)) ? (errors.location) : ''}
                                                    onChange={handleChange}
                                                    maxLength={250}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={6} >
                                                <FormDatePicker
                                                    tabIndex={2}
                                                    label='Joining Date'
                                                    name='joining_date'
                                                    placeholder="Year and Month"
                                                    error={(action.isSubmitted && (errors.joining_date)) ? (errors.joining_date) : ''}
                                                    onChange={handleChange}
                                                    value={data.joining_date}
                                                    views={['month', 'year']}
                                                    inputFormat='MMMM yyyy'
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={6} >
                                                <FormDatePicker
                                                    tabIndex={2}
                                                    label='Resigned Dated'
                                                    name='resigning_date'
                                                    placeholder="Year and Month"
                                                    error={(action.isSubmitted && (errors.resigning_date)) ? (errors.resigning_date) : ''}
                                                    onChange={handleChange}
                                                    value={data.resigning_date}
                                                    views={['month', 'year']}
                                                    inputFormat='MMMM yyyy'
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FormInput
                                                    tabIndex={4}
                                                    label='Remark'
                                                    name='remark'
                                                    value={data.remark}
                                                    error={(action.isSubmitted && (errors.remark)) ? (errors.remark) : ''}
                                                    onChange={handleChange}
                                                    autoFocus={true}
                                                    multiline
                                                    maxLength={250}
                                                    minRows={5}
                                                />
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
                                            <SubmitButton label={staff_experiences && staff_experiences.id ? 'Update' : 'Submit'} tabIndex={6} />
                                            <CancelButton onClick={cancelStaffExperience} tabIndex={12} />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </form>
        </React.Fragment>
    )

}

export { CreateStaffExperience };