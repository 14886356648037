import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Avatar, Grid, Stack, Typography, CardMedia } from '@mui/material'
import { PostAuthRoutes } from "../../../routes";
import { UserAction } from '../../../redux/actions';
import { AVATAR_BROKEN_IMAGE } from '../../../_helpers';
import { AbilityCan } from '../../../_helpers/permission/AbilityCan';
import { Card, CardContent } from '../../../_components/card'
import { FormBrowseFile } from "../../../_components/form";
import { UpdateProfileModal } from '../../../_components/modal/modals/UpdateProfileModal';
import { Box } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux';
import { CheckCircle, MailOutline, MobileFriendlyRounded, HomeRounded } from '@mui/icons-material';
import { ProfileDetailsCardLoader } from '.'
import { InputTags } from './input-tags'

function ProfileDetails() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let history = useHistory();

    /** Redux actions and state */
    const { user_loading: isLoading, user } = useSelector((state) => state.UserReducer);
    const updateProfilePicture = (params) => dispatch(UserAction.updateProfilePicture(params));
console.log("UP: ",user)
    const [showProfileUpdateModal, setShowProfileUpdateModal] = useState(false);
    const [profileData, setProfileData] = useState({ id: '', profile_picture: '' });

    /**
     * function to get experience page
     * @return view
     * @author Naveen
     * @created_at 29 Sep 2022
     */
    function handleExperienceClick() {

        history.push({
            pathname: PostAuthRoutes('staff_experience').path,
        });
    }

    /**
     * function to get experience page
     * @return view
     * @author Naveen
     * @created_at 29 Sep 2022
     */
    function handleSalaryLogClick() {

        history.push({
            pathname: PostAuthRoutes('salary_log').path,
        });
    }

    /**
     * function to open/close profile Update modal 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const profileUpdateModal = ({ profile_picture }) => {
        setProfileData({ profile_picture: profile_picture });
        setShowProfileUpdateModal(!showProfileUpdateModal);
    }

    /**
     * function to update Profile Picture
     * @return view
     * @author Naveen
     * @created_at 14 June 2022
     */
    const updateProfileAction = async ({ profile_picture }) => {

        const formData = new FormData();
        console.log("IPP: ", profile_picture)
        setShowProfileUpdateModal(!showProfileUpdateModal);
        // await changeProfile({ 'advertisement_id': id });
        formData.append("profile_picture", profile_picture);

        dispatchProfileUpdateAction(formData);
    }

    /**
     * function to dispatch Profile Update api
     * @param {object} formData form object
     * @return json 
     * @author Naveen
     * @created_at 21 Sept 2022
     */
    async function dispatchProfileUpdateAction(formData) {
        console.log("FDPP: " ,formData.get("profile_picture"))
        const updateProfile = await updateProfilePicture(formData);
        if (updateProfile && updateProfile.status === 1) {
            let redirectPath = PostAuthRoutes('profile_details').path;
            history.push(redirectPath);
        }
    }

    if (!isLoading)
        return (
            <React.Fragment>
                <Card>
                    <CardContent>
                        <Grid container spacing={3} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <CardMedia sx={{ width: 150, height: 150, borderRadius: '50%', objectFit: 'cover', cursor: "pointer" }} component={"img"} src={user.profile_picture} onClick={() => profileUpdateModal({ profile_picture: user.profile_picture})} onError={e => { e.target.src = AVATAR_BROKEN_IMAGE }}/>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>

                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                    <Typography variant="h4" color="primary">{user.first_name}</Typography>
                                </Stack>
                                <Typography variant="h6" display={`flex`} alignItems='center'><MailOutline color='primary' />&nbsp;{user.email}&nbsp;{user.email_verify_status ? <CheckCircle color='success' size='small' /> : <CheckCircle color='secondary' size='small' />}</Typography>

                                {user.mobile &&
                                    <Typography variant="h6" display={`flex`} alignItems='center'><MobileFriendlyRounded color='primary' />&nbsp;{`${user.mobile}`}</Typography>
                                }

                                {user.address &&
                                    <Typography variant="h6" display={`flex`} alignItems='center' ><HomeRounded color='primary' />&nbsp;{`${user.address}, ${user.city}-${user.pin}`}</Typography>
                                }

                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                <Grid container spacing={1} xs={12} sm={12} md={8} lg={8} xl={12}>

                    <Grid item xs={12} sm={12} md={8} lg={8} xl={6}>
                        <AbilityCan I='staff_experience' passThrough={(user.role === 'developer') ? true : false}>
                            <Card sx={{ marginTop: '10px' }}>
                                <CardContent>
                                    <Typography variant="h5" align='center' mb={1} onClick={handleExperienceClick} sx={{ cursor: `pointer` }}>Experience</Typography>
                                </CardContent>
                            </Card>
                        </AbilityCan>
                    </Grid>

                    <Grid item xs={12} sm={12} md={8} lg={8} xl={6}>
                        <AbilityCan I='salary_log' passThrough={(user.role === 'developer') ? true : false}>
                            <Card sx={{ marginTop: '10px' }}>
                                <CardContent>
                                    <Typography variant="h5" align='center' mb={1} onClick={handleSalaryLogClick} sx={{ cursor: `pointer` }}>Salary Log</Typography>
                                </CardContent>
                            </Card>
                        </AbilityCan>
                    </Grid>

                    <Grid item xs={12} sm={12} md={8} lg={8} xl={12}>
                        <InputTags/>
                    </Grid>

                </Grid>
                <UpdateProfileModal show={showProfileUpdateModal} closeModal={profileUpdateModal} data={profileData} updateProfile={updateProfileAction} />
            </React.Fragment>
        )
    else
        return (
            <Card>
                <CardContent>
                    <ProfileDetailsCardLoader />
                </CardContent>
            </Card>
        )
}

export { ProfileDetails };


