import React from 'react';
import { Grid, Typography } from '@mui/material';

import ErrorImage from './404.svg'
import { useStyles } from '.'

function NoData({ content1, content2, description, children, ...props }) {

    /** Initialize plugins and variables */
    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid className={classes.noDataWrapper} container spacing={0} direction="column" alignItems="center" justifyContent="center" >
                <Grid item xs={4}>
                    <img src={ErrorImage} alt="No data found" />
                    <Typography variant="h1">{content1} <span className={classes.textNotFound}>{content2}</span></Typography>
                    <Typography component="h1" variant="caption" mb={2}>{description}</Typography>
                    {children}
                </Grid>
            </Grid>
        </React.Fragment>
    )
}


export { NoData };
