import React from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import { makeStyles } from '@mui/styles';

const Calendar = ({ events, ...props }) => {

	const useStyles = makeStyles((theme) => ({

		menuPaper: {
			'& .fc-daygrid-event.fc-event-end, .fc-daygrid-event.fc-event-start': {
				borderRadius: '30px'
			},
			'& .fc-event-title': {
				fontWeight: 'bold'
			},
		}
	}))

    const classes = useStyles();

    return (
		<div className={classes.menuPaper}>
			<FullCalendar
				plugins={[ dayGridPlugin ]}
				initialView="dayGridMonth"
				displayEventTime= {false}
				events={events}
				eventDisplay="block"
			/>
		</div>
    );
};

export { Calendar };
