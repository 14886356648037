import { apiServices } from '../../_helpers';


export const DesignationServices = {

    /**
     * Service for Designation Select List 
     * @param {Object} params - The params which are used for the api
     * @author Naveen  
     * @created_at 21 September 2022 
     */
    designationSelectList: (params) => {
        return apiServices.post('/agent/designation/designation-select-list', params)
            .then((response) => { return response; })
    },

}