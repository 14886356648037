import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { handleInputChange, validateForm } from '../../../../_helpers';
import { PreSignIn } from '../../../../_layouts/pre-signin';
import { PreAuthRoutes } from '../../../../routes';

import { FormInput } from '../../../../_components/form';
import { SubmitButton } from '../../../../_components/controls';
import { RouteLink } from '../../../../_components/controls/route-link';
import { Grid, Typography } from '@mui/material';

import { ForgotPasswordAction } from '../../../../redux/actions';
import { validate } from '.'
import { SweetAlert } from '../../../../_components';

// Initialize form input values to null
const inputs = { email: '' };

function SendResetPasswordLink() {

    /** Initialize plugins and variables */
    const history = useHistory();
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { send_pwd_rst_link_loading: isLoading, send_pwd_rst_link_errors: sendPwdRstLinkErrors } = useSelector((state) => state.ForgotPasswordReducer);
    const sendPasswordResetLink = (params) => dispatch(ForgotPasswordAction.sendPasswordResetLink(params));

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, remember_me: '' });
    const [action, setAction] = useState({ isSubmitted: false });

    useEffect(() => {
        setErrors({ ...sendPwdRstLinkErrors });
    }, [sendPwdRstLinkErrors])

    /**
     * function to handle input changes and alter the value
     * @param object e input object with name and value
     * @author Akshay N
     * @created_at 14 June 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit
     * @param object e form object
     * @author Akshay N
     * @created_at 14 June 2022
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchSendPasswordResetLinkAction();
    }

    /**
     * function to dispatch send password reset link
     * @author Akshay N
     * @created_at 14 June 2022
     */
    async function dispatchSendPasswordResetLinkAction() {
        const isLinkSent = await sendPasswordResetLink(data);
        if (isLinkSent.status === 1) {
            SweetAlert.fire({
                title: 'Password Reset Link Send',
                text: "Password reset link sent to your email.Please check!",
                icon: 'success',
                confirmButtonText: 'Ok!',
                reverseButtons: true,
                showCancelButton: false,
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    let redirectPath = PreAuthRoutes('login').path;
                    history.push(redirectPath);
                }
            })
        }
    }

    return (
        <PreSignIn>
            <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2} sx={{ p: `40px` }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" alignItems="center" justifyContent="center">
                        <Typography variant="h5">{`${PreAuthRoutes('forgot_password').name}`}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormInput
                            tabIndex={1}
                            label='Email'
                            name='email'
                            value={data.email}
                            error={action.isSubmitted && errors.email ? errors.email : ''}
                            onChange={handleChange}
                            autoFocus={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" alignItems="center" justifyContent="space-between">
                        <RouteLink to={PreAuthRoutes('login').path} label={PreAuthRoutes('login').name} />
                        <SubmitButton label={'Send Link'} loading={isLoading} />
                    </Grid>
                </Grid>
            </form>
        </PreSignIn>
    );
}

export { SendResetPasswordLink };
