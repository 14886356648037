import { GlobalAction } from ".";
import { LeavesServices } from "../services";

export const CREATE_LEAVE_REQUEST = 'CREATE_LEAVE_REQUEST';
export const CREATE_LEAVE_SUCCESS = 'CREATE_LEAVE_SUCCESS';
export const CREATE_LEAVE_FAILURE = 'CREATE_LEAVE_FAILURE';

export const LEAVE_REQUEST = 'LEAVE_REQUEST';
export const LEAVE_SUCCESS = 'LEAVE_SUCCESS';
export const LEAVE_FAILURE = 'LEAVE_FAILURE';

export const DELETE_LEAVE_REQUEST = 'DELETE_LEAVE_REQUEST';
export const DELETE_LEAVE_SUCCESS = 'DELETE_LEAVE_SUCCESS';
export const DELETE_LEAVE_FAILURE = 'DELETE_LEAVE_FAILURE';

export const GET_ALL_APPROVED_LEAVES_REQUEST = 'GET_ALL_APPROVED_LEAVES_REQUEST';
export const GET_ALL_APPROVED_LEAVES_SUCCESS = 'GET_ALL_APPROVED_LEAVES_SUCCESS';
export const GET_ALL_APPROVED_LEAVES_FAILURE = 'GET_ALL_APPROVED_LEAVES_FAILURE';

export const LeavesAction = {

    /**
    * Action for create leaves request
    * @param {Object} params - The params which are used for the api
    * @author Naveen  
    * @created_at 21 September 2022
    */
    createLeaveRequest: (params) => {
        return dispatch => {
            dispatch(request(params));
            return LeavesServices.createLeaveRequest(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: CREATE_LEAVE_REQUEST, request: request } }
        function success(request, response) { return { type: CREATE_LEAVE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CREATE_LEAVE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action list all Leaves request submitted
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 21 September 2022
     */
    listLeaves: (params) => {
        return dispatch => {
            dispatch(request(params));
            return LeavesServices.listLeaves(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: LEAVE_REQUEST, request: request } }
        function success(request, response) { return { type: LEAVE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: LEAVE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for deleting an Leave request
     * @param {Object} params - The params which are used for the api
     * @author Naveen  
     * @created_at 21 September 2022
    */
    deleteLeave: (params) => {
        return dispatch => {
            dispatch(request(params));
            return LeavesServices.deleteLeave(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: DELETE_LEAVE_REQUEST, request: request } }
        function success(request, response) { return { type: DELETE_LEAVE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: DELETE_LEAVE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to get all approved leaves
     * @param {Object} params - The params which are used for the api
     * @author Naveen  
     * @created_at 03 October 2022
    */
    getAllApprovedLeaves: (params) => {
        return dispatch => {
            dispatch(request(params));
            return LeavesServices.getAllApprovedLeaves(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GET_ALL_APPROVED_LEAVES_REQUEST, request: request } }
        function success(request, response) { return { type: GET_ALL_APPROVED_LEAVES_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GET_ALL_APPROVED_LEAVES_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    }

}