import React from 'react'
import PropTypes from 'prop-types'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { FormDateRangePickerStyle as useStyles } from '.';
import { FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';

function FormDateRangePicker({ label, name, value, placeholder, onChange, error, className, info }) {

    const [val, setVal] = React.useState((value && value !== '') ? value : '');

    const DATE_FORMAT = 'DD/MM/yyyy';

    const handleApply = (event, picker) => {
        picker.element.val(
            picker.startDate.format(DATE_FORMAT) + ' - ' + picker.endDate.format(DATE_FORMAT)
        );
        onChange({ target: { type: `date_time`, name: name, value: event.target.value } });
        setVal(event.target.value);
    };

    const handleCancel = (event, picker) => {
        picker.element.val('');
        onChange({ target: { type: `date_time`, name: name, value: null } });
        setVal(event.target.value);
    };

    const handleChange = (event) => {
        setVal(event.target.value);
    };

    const classes = useStyles();
    return (
        <FormControl fullWidth className={classes.formControl}  >
            <InputLabel htmlFor={name} className={classes.label}>{label}</InputLabel>

            <DateRangePicker
                className={classes.input}
                onApply={handleApply}
                onCancel={handleCancel}
                autoApply={true}
                initialSettings={{
                    autoUpdateInput: false,
                    locale: {
                        format: DATE_FORMAT,
                    },
                }}>
                <OutlinedInput
                    type="text"
                    className={classes.input}
                    placeholder={placeholder ? placeholder : label}
                    name={name}
                    label={label}
                    error={error ? true : false}
                    value={val}
                    onChange={handleChange}
                    readOnly={true}
                />
            </DateRangePicker>
            {(info) && <FormHelperText id={`helper_${name}`} className={classes.info}>{info}</FormHelperText>}
            {(error) && <FormHelperText id={`error_${name}`} error className={classes.info}>{error}</FormHelperText>}
        </FormControl>
    )
}


FormDateRangePicker.defaultProps = {
    type: "text",
    className: ""
}

FormDateRangePicker.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    className: PropTypes.string,
    value: PropTypes.any,
    tabIndex: PropTypes.number,
    id: PropTypes.string,
    onChange: PropTypes.func.isRequired
}

export { FormDateRangePicker }
