import React from 'react';
import { Box, Card, CardContent, Grid, Skeleton, Stack } from '@mui/material';


function PreviousCompaniesCardLoader({ ...props }) {

    var rows = [];
    for (var i = 0; i < 8; i++) {
        rows.push(
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}  {...props}>
                <Card style={{ height: `330px` }}>
                    <CardContent style={{ position: 'absolute', left: 0, right: 0, bottom: 0, p: 2 }}>
                        <Grid container spacing={1} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                    <Skeleton animation="wave" height={30} width="70%" style={{ marginBottom: 0 }} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: `200px` }}>
                                    <Skeleton animation="wave" height="300px" width="200px" />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Stack display={`flex`} alignItems={`center`} justifyContent={`center`}>
                                    <Skeleton animation="wave" height={15} width="90%" style={{ marginBottom: 1 }} />
                                    <Skeleton animation="wave" height={15} width="60%" style={{ marginBottom: 1 }} />
                                    <Skeleton animation="wave" height={15} width="70%" style={{ marginBottom: 2 }} />
                                </Stack>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        )
    }

    return <React.Fragment> {rows} </React.Fragment>
}

export { PreviousCompaniesCardLoader };
