import * as React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { FormControl, FormHelperText } from '@mui/material';
import { FormDateTimePickerStyle as useStyles } from '.';



/**
 * Declare default value of input properties 
 */
const defaultProps = {
    className: "",
    isReadOnly: false,
    maxLength: 255,
    maskType: ''
};



function FormDateTimePicker({ label, name, placeholder, className, onChange, tabIndex, id, error, isReadOnly, pattern, maxLength, maskType, minDate = null, value, info, ...props }) {
    const [val, setVal] = React.useState((value && value !== '') ? value : null);

    const classes = useStyles();

    const handleChange = (e) => {
        onChange({ target: { type: `date_time`, name: name, value: new Date(e).toISOString() } });
        setVal(e);
    }

    return (

        <FormControl fullWidth className={`${className}`} >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                    value={val}
                    onChange={handleChange}
                    label={label}
                    onError={console.log}
                    minDate={minDate}
                    inputFormat="dd/MM/yyyy hh:mm a"
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
            {(info) && <FormHelperText id={`helper_${name}`} className={classes.info}>{info}</FormHelperText>}
            {(error) && <FormHelperText id={`error_${name}`} error className={classes.info}>{error}</FormHelperText>}
        </FormControl>

    )
}


FormDateTimePicker.defaultProps = defaultProps;

export { FormDateTimePicker }







// // const useStyles = makeStyles({
// //     formControl: {
// //         // ...FormControl
// //     },
// //     label: {
// //         // ...FormLabel,

// //     },
// //     input: {
// //         // border: `1px solid ${grayColor[4]}`,
// //         fontSize: 14,
// //         width: 'auto',
// //         padding: '10px 15px',
// //         height: `42px`,
// //         borderRadius: `.25rem`,
// //         '&:before': {
// //             borderBottom: `unset`
// //         },
// //         '&:before &:hover': {
// //             borderBottom: `unset`
// //         }
// //     },
// //     info: {
// //         fontSize: 12,
// //         marginTop: 0
// //     }
// // })




