import {
    INFLUENCER_COUNT_REQUEST, INFLUENCER_COUNT_SUCCESS, INFLUENCER_COUNT_FAILURE,
    BRAND_COUNT_REQUEST, BRAND_COUNT_SUCCESS, BRAND_COUNT_FAILURE,
    PROMO_COUNT_REQUEST, PROMO_COUNT_SUCCESS, PROMO_COUNT_FAILURE,
    STORY_COUNT_REQUEST, STORY_COUNT_SUCCESS, STORY_COUNT_FAILURE,
    TOP_INFLUENCERS_BY_FOLLOWERS_REQUEST, TOP_INFLUENCERS_BY_FOLLOWERS_SUCCESS, TOP_INFLUENCERS_BY_FOLLOWERS_FAILURE,
    TOP_INFLUENCERS_BY_STORIES_REQUEST, TOP_INFLUENCERS_BY_STORIES_SUCCESS, TOP_INFLUENCERS_BY_STORIES_FAILURE,
    TOP_BRANDS_BY_FOLLOWERS_REQUEST, TOP_BRANDS_BY_FOLLOWERS_SUCCESS, TOP_BRANDS_BY_FOLLOWERS_FAILURE,
    TOP_BRANDS_BY_STORIES_REQUEST, TOP_BRANDS_BY_STORIES_SUCCESS, TOP_BRANDS_BY_STORIES_FAILURE,
    LEAVES_TODAY_REQUEST, LEAVES_TODAY_SUCCESS, LEAVES_TODAY_FAILURE,
    COMPANIES_REQUEST, COMPANIES_SUCCESS, COMPANIES_FAILURE,
} from "../actions";

const initialState = {
    influencers_count_loading: false, influencers_count_details: { influencer_count: 0, influencer_percentage: 0 }, influencers_count_errors: {},
    brand_count_loading: false, brand_count_details: { brand_count: 0, brand_percentage: 0 }, brand_count_errors: {},
    promo_count_loading: false, promo_count_details: { promo_count: 0, promo_percentage: 0 }, promo_count_errors: {},
    story_count_loading: false, story_count_details: { story_count: 0, story_percentage: 0 }, story_count_errors: {},
    top_influencers_by_followers_loading: false, top_influencers_by_followers: [], top_influencers_by_followers_errors: {},
    top_influencers_by_stories_loading: false, top_influencers_by_stories: [], top_influencers_by_stories_errors: {},
    top_brands_by_followers_loading: false, top_brands_by_followers: [], top_brands_by_followers_errors: {},
    top_brands_by_stories_loading: false, top_brands_by_stories: [], top_brands_by_stories_errors: {},
    leaves_today_loading: false, leaves_today: [], leaves_today_errors: {},
    companies_loading: false, companies: [], companies_errors: {}
};

export function DashboardReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::influencer count redux */
        case INFLUENCER_COUNT_REQUEST: {
            return {
                ...state,
                influencers_count_loading: true,
                influencers_count_errors: {}
            };
        }
        case INFLUENCER_COUNT_SUCCESS: {
            return {
                ...state,
                influencers_count_loading: false,
                influencers_count_details: action.data,
                influencers_count_errors: {}
            };
        }
        case INFLUENCER_COUNT_FAILURE: {
            return {
                ...state,
                influencers_count_loading: false,
                influencers_count_errors: action.errors
            };
        }
        /** end::influencer count redux */

        /** begin::Brand count redux */
        case BRAND_COUNT_REQUEST: {
            return {
                ...state,
                brand_count_loading: true,
                brand_count_errors: {}
            };
        }
        case BRAND_COUNT_SUCCESS: {
            return {
                ...state,
                brand_count_loading: false,
                brand_count_details: action.data,
                brand_count_errors: {}
            };
        }
        case BRAND_COUNT_FAILURE: {
            return {
                ...state,
                brand_count_loading: false,
                brand_count_errors: action.errors
            };
        }
        /** end::Brand count redux */

        /** begin::Promo count redux */
        case PROMO_COUNT_REQUEST: {
            return {
                ...state,
                promo_count_loading: true,
                promo_count_errors: {}
            };
        }
        case PROMO_COUNT_SUCCESS: {
            return {
                ...state,
                promo_count_loading: false,
                promo_count_details: action.data,
                promo_count_errors: {}
            };
        }
        case PROMO_COUNT_FAILURE: {
            return {
                ...state,
                promo_count_loading: false,
                promo_count_errors: action.errors
            };
        }
        /** end::Promo count redux */

        /** begin::Story count redux */
        case STORY_COUNT_REQUEST: {
            return {
                ...state,
                story_count_loading: true,
                story_count_errors: {}
            };
        }
        case STORY_COUNT_SUCCESS: {
            return {
                ...state,
                story_count_loading: false,
                story_count_details: action.data,
                story_count_errors: {}
            };
        }
        case STORY_COUNT_FAILURE: {
            return {
                ...state,
                story_count_loading: false,
                story_count_errors: action.errors
            };
        }
        /** end::Promo count redux */

        /** begin::top influencers redux */
        case TOP_INFLUENCERS_BY_FOLLOWERS_REQUEST: {
            return {
                ...state,
                top_influencers_by_followers_loading: true,
                top_influencers_by_followers_errors: {}
            };
        }
        case TOP_INFLUENCERS_BY_FOLLOWERS_SUCCESS: {
            return {
                ...state,
                top_influencers_by_followers_loading: false,
                top_influencers_by_followers: action.data,
                top_influencers_by_followers_errors: {}
            };
        }
        case TOP_INFLUENCERS_BY_FOLLOWERS_FAILURE: {
            return {
                ...state,
                top_influencers_by_followers_loading: false,
                top_influencers_by_followers_errors: action.errors
            };
        }
        /** end::top influencers redux */

        /** begin::top influencers by stories redux */
        case TOP_INFLUENCERS_BY_STORIES_REQUEST: {
            return {
                ...state,
                top_influencers_by_stories_loading: true,
                top_influencers_by_stories_errors: {}
            };
        }
        case TOP_INFLUENCERS_BY_STORIES_SUCCESS: {
            return {
                ...state,
                top_influencers_by_stories_loading: false,
                top_influencers_by_stories: action.data,
                top_influencers_by_stories_errors: {}
            };
        }
        case TOP_INFLUENCERS_BY_STORIES_FAILURE: {
            return {
                ...state,
                top_influencers_by_stories_loading: false,
                top_influencers_by_stories_errors: action.errors
            };
        }
        /** end::top influencers by stories redux */
        /** begin::top brands by followers redux  */
        case TOP_BRANDS_BY_FOLLOWERS_REQUEST: {
            return {
                ...state,
                top_brands_by_followers_loading: true,
                top_brands_by_followers_errors: {},
            };
        }
        case TOP_BRANDS_BY_FOLLOWERS_SUCCESS: {
            return {
                ...state,
                top_brands_by_followers_loading: false,
                top_brands_by_followers: action.data,
                top_brands_by_followers_errors: {}
            };
        }
        case TOP_BRANDS_BY_FOLLOWERS_FAILURE: {
            return {
                ...state,
                top_brands_by_followers_loading: false,
                top_brands_by_followers_errors: action.errors
            }
        }
        /** end::top brands by followers redux */
        /** begin::top brands by stories redux */
        case TOP_BRANDS_BY_STORIES_REQUEST: {
            return {
                ...state,
                top_brands_by_stories_loading: true,
                top_brands_by_stories_errors: {}
            };
        }
        case TOP_BRANDS_BY_STORIES_SUCCESS: {
            return {
                ...state,
                top_brands_by_stories_loading: false,
                top_brands_by_stories: action.data,
                top_brands_by_stories_errors: {}
            };
        }
        case TOP_BRANDS_BY_STORIES_FAILURE: {
            return {
                ...state,
                top_brands_by_stories_loading: false,
                top_brands_by_stories_errors: action.errors
            };
        }
        /** end::top brands by stories redux */
        /** begin::leaves today redux */
        case LEAVES_TODAY_REQUEST: {
            return {
                ...state,
                leaves_today_loading: true,
                leaves_today_errors: {}
            };
        }
        case LEAVES_TODAY_SUCCESS: {
            return {
                ...state,
                leaves_today_loading: false,
                leaves_today: action.data,
                leaves_today_errors: {}
            };
        }
        case LEAVES_TODAY_FAILURE: {
            return {
                ...state,
                leaves_today_loading: false,
                leaves_today_errors: action.errors
            };
        }
        /** end::leaves today redux */

        /** begin::pervious companies redux */
        case COMPANIES_REQUEST: {
            return {
                ...state,
                companies_loading: true,
                companies_errors: {}
            };
        }
        case COMPANIES_SUCCESS: {
            return {
                ...state,
                companies_loading: false,
                companies: action.data,
                companies_errors: {}
            };
        }
        case COMPANIES_FAILURE: {
            return {
                ...state,
                companies_loading: false,
                companies_errors: action.errors
            };
        }
        /** end::pervious companies redux */

        default:
            return state;
    }
}