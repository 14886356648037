import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom'
import { Box, Grid, Stack } from '@mui/material';
import { FilterAlt } from '@mui/icons-material';
import { Card, CardContent } from '../../_components/card';
import { GeneralButton } from '../../_components/controls/general-button/GeneralButton';
import { DeleteModal } from '../../_components/modal/modals/DeleteModal';
import { PostAuthRoutes } from '../../routes';
import { LeavesAction } from '../../redux/actions';
import { LeavesDataTable } from '.';
import { PageHeader } from '../../_components';
import { LeavesFilter } from './leaves-filter';
import { AbilityCan } from '../../_helpers/permission/AbilityCan';

function Leaves(params) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let history = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);
    const deleteLeave = (params) => dispatch(LeavesAction.deleteLeave(params));

    /** Initialize and declare state */
    const [leaveDeleteData, setLeaveDeleteData] = useState({ id: '' });
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showLeavesFilterModal, setShowLeavesFilterModal] = useState(false);
    const [payload, setPayload] = useState({
        limit: 100, page: 1,
        filter: { date: searchParams.get('date') ?? '' }
    });

    /**
     * function to create Leave Request
     * @param {string} null 
     * @return view
     * @author Naveen
     * @created_at 21 sept 2022
     */
    function createLeaveRequest() {
        history.push({
            pathname: PostAuthRoutes('create_leave').path,
        });
    }

    /**
     * function to open/close Leave request delete modal
     * @return view
     * @author Naveen
     * @created_at 21 sept 2022
     */
    const leaveDeleteModal = async ({ id }) => {
        console.log('rowId::', id)
        setLeaveDeleteData({ id: id })
        setShowDeleteModal(!showDeleteModal)
    }

    /**
     * function to delete Leave Request
     * @return view
     * @author Naveen
     * @created_at 21 sept 2022
     */
    const leaveDeleteAction = async (id) => {
        setShowDeleteModal(!showDeleteModal)
        await deleteLeave({ 'leave_id': id });
    }

    /**
     * function to open/close Leaves filter modal
     * @param {string} null 
     * @return view
     * @author Naveen
     * @created_at 21 Sept 2022
     */
    const leavesFilterModal = () => {
        setShowLeavesFilterModal(!showLeavesFilterModal);
    }

    /**
     * function to apply the selected filter options on the Leaves list
     * @param {Object} filterData 
     * @param {String} filterData.status Selected status from the Leaves filter modal
     * @return view
     * @author Naveen
     * @created_at 21 Sept 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData });
    }

    return (
        <React.Fragment>
            <Box>
                <PageHeader
                    title={PostAuthRoutes('leave').name}
                    action={
                        <Stack spacing={1} direction="row" alignItems={`center`}>
                            <AbilityCan I='create_leaves_permission' passThrough={(user.role === 'developer') ? true : false}>
                                <GeneralButton label={<React.Fragment>Create</React.Fragment>} onClick={() => createLeaveRequest()} />
                            </AbilityCan>
                            <AbilityCan I='leaves_filter' passThrough={(user.role === 'developer') ? true : false}>
                                <GeneralButton label={<FilterAlt />} onClick={() => leavesFilterModal()} />
                            </AbilityCan>
                        </Stack>
                    }
                />
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <LeavesFilter show={showLeavesFilterModal} applyFilter={applyFilter} closeModal={leavesFilterModal} leavesFilter={payload.filter} />
                        <Card>
                            <CardContent>
                                <LeavesDataTable leaveDeleteModal={leaveDeleteModal} filter={payload.filter} />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <DeleteModal show={showDeleteModal} closeModal={leaveDeleteModal} data={leaveDeleteData} deleteModal={leaveDeleteAction} />
        </React.Fragment>
    )

}

export { Leaves }