import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router'
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { Box } from '../../_components/box'
import { UpdateStatusModal } from '../../_components/modal/modals/UpdateStatusModal';
import { Grid } from '@mui/material'
import { PreviousCompaniesCard, PreviousCompaniesCardLoader } from ".";
import { PostAuthRoutes } from "../../routes";
import { NoData } from '../no-data'
import { PageHeader } from "../../_components";
import { DashboardAction, CompaniesAction } from '../../redux/actions';

function PreviousCompanies() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let history = useHistory();

    /** Redux actions and state */
    const { companies_loading: isLoading, companies } = useSelector((state) => state.CompaniesReducer);
    const companiesList = (params) => dispatch(CompaniesAction.companiesList(params));
    const changeStatus = (params) => dispatch(CompaniesAction.changeStatus(params));

    /** Initialize and declare state */
    const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', status: '', item: '' });

    useEffect(() => {
        getNext();
        //eslint-disable-next-line
    }, [])

    /**
     * function to open/close status Update modal 
     * @return view
     * @author
     * @created_at 03 Nov 2022
     */
    const statusUpdateModal = ({ id, status }) => {
        const CStatus = status === '1' ? 'active' : 'inactive'
        setStatusData({ id: id, status: CStatus });
        setShowStatusUpdateModal(!showStatusUpdateModal);
    }

    /**
     * function to update Company
     * @return view
     * @author
     * @created_at 03 Nov 2022
     */
    const updateStatusAction = async (id) => {
        setShowStatusUpdateModal(!showStatusUpdateModal);
        await changeStatus({ 'companies_id': id });
    }

    /**
     * function to list Previous Companies
     * @param {string} null 
     * @return view
     * @author Naveen
     * @created_at 02 Nov 2022
     */
    const getNext = async () => {
        const result = await companiesList();

    }

    return (
        <React.Fragment>
            <Box>
                <PageHeader
                    title={PostAuthRoutes('previous_companies').name}
                />
            </Box>
            <Box pt={3}>
                <InfiniteScroll dataLength={companies.length} next={getNext} hasMore={true}>
                    <Grid container spacing={2} direction="row" justifyContent="left" alignItems="center" >
                        {
                            companies.length > 0 && companies.map((company, i) => {
                                return (
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={3} key={i}>
                                        <PreviousCompaniesCard company={company} statusUpdateModal={statusUpdateModal} />
                                    </Grid>
                                );
                            })
                        }
                        {isLoading && <PreviousCompaniesCardLoader />}
                        {!isLoading && companies.length === 0 && <NoData content1={`No`} content2={`Previous Companies available`} description={`Please click below button to create new staff Experience.`} minHeight={`55vh`} ></NoData>}
                    </Grid>
                </InfiniteScroll>
                <UpdateStatusModal show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction} />
            </Box>
        </React.Fragment>

    )
}

export { PreviousCompanies }