import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { handleInputChange, validateForm } from '../../../_helpers';
import { PostAuthRoutes, PreAuthRoutes } from '../../../routes';

import { FormInput, FormPassword, FormDatePicker } from '../../../_components/form';
import { SubmitButton, RouteLink } from '../../../_components/controls';
import { Grid, Typography } from '@mui/material';
import { PreSignIn } from '../../../_layouts/pre-signin';

import { RegisterAction, DesignationAction } from '../../../redux/actions';
import { validate } from '.'

// Initialize form input values to null
const inputs = { name: '', email: '', mobile_number: '', password: '', confirm_password: '', designation: '', address: '', city: '', district: '', state: '', pin: '', account_number: '', ifsc: '', joining_date: '' };

function Register() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    /** Redux actions and state */
    const { loading: isLoading, errors: registrationErrors } = useSelector((state) => state.RegisterReducer);
    const { designation_select_list_loading: isDesignationLoading, designation_select_list } = useSelector((state) => state.DesignationReducer);
    const register = (params) => dispatch(RegisterAction.register(params));
    const designationSelectList = (params) => dispatch(DesignationAction.designationSelectList(params));

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, remember_me: '' });
    const [action, setAction] = useState({ isSubmitted: false });

    useEffect(() => {
        setErrors({ ...registrationErrors });
    }, [registrationErrors])

    useEffect(() => {
        designationSelectList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Naveen
     * @created_at 21 Sept 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
        console.log("Data:  ", data)
    }

    /**
     * function to handle registration form submit action
     * @param {object} e form object
     * @author Naveen
     * @created_at 21 Sept 2022
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchRegisterAction();
    }

    /**
     * function to dispatch registration api
     * @return json 
     * @author Naveen
     * @created_at 21 Sept 2022
     */
    async function dispatchRegisterAction() {
        const registered = await register(data);
        if (registered && registered.status === 1) {
            let redirectPath = ((location.state && location.state.from.pathname) ? (location.state.from.pathname) : PostAuthRoutes('home').path);
            history.push(redirectPath);
        }
    }


    return (
        <PreSignIn>
            <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2} sx={{ p: `20px` }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" alignItems="center" justifyContent="center">
                        <Typography variant="h5" >{`${PreAuthRoutes('register').name}`}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                        <FormInput
                            tabIndex={1}
                            label='Name'
                            name='name'
                            value={data.name}
                            error={action.isSubmitted && errors.name ? errors.name : ''}
                            onChange={handleChange}
                            autoFocus={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                        <FormInput
                            tabIndex={3}
                            label='Email'
                            name='email'
                            value={data.email}
                            error={action.isSubmitted && errors.email ? errors.email : ''}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                        <FormInput
                            tabIndex={3}
                            label='Mobile Number'
                            name='mobile_number'
                            value={data.mobile_number}
                            error={action.isSubmitted && errors.mobile_number ? errors.mobile_number : ''}
                            onChange={handleChange}
                            maskType={`digit-only`}
                            inputProps={{ maxLength: 10 }}
                            autoComplete={`mobile_number`}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                        <FormInput
                            tabIndex={1}
                            label='Address'
                            name='address'
                            value={data.address}
                            error={action.isSubmitted && errors.address ? errors.address : ''}
                            onChange={handleChange}
                            autoFocus={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                        <FormInput
                            tabIndex={1}
                            label='City'
                            name='city'
                            value={data.city}
                            error={action.isSubmitted && errors.city ? errors.city : ''}
                            onChange={handleChange}
                            autoFocus={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                        <FormInput
                            tabIndex={1}
                            label='District'
                            name='district'
                            value={data.district}
                            error={action.isSubmitted && errors.district ? errors.district : ''}
                            onChange={handleChange}
                            autoFocus={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                        <FormInput
                            tabIndex={1}
                            label='State'
                            name='state'
                            value={data.state}
                            error={action.isSubmitted && errors.state ? errors.state : ''}
                            onChange={handleChange}
                            autoFocus={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                        <FormInput
                            tabIndex={1}
                            label='Pin Code'
                            name='pin'
                            value={data.pin}
                            error={action.isSubmitted && errors.pin ? errors.pin : ''}
                            onChange={handleChange}
                            autoFocus={true}
                            maskType={`digit-only`}
                            inputProps={{ maxLength: 6 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                        <FormInput
                            tabIndex={1}
                            label='Account Number'
                            name='account_number'
                            value={data.account_number}
                            error={action.isSubmitted && errors.account_number ? errors.account_number : ''}
                            onChange={handleChange}
                            autoFocus={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                        <FormInput
                            tabIndex={1}
                            label='IFSC'
                            name='ifsc'
                            value={data.ifsc}
                            error={action.isSubmitted && errors.ifsc ? errors.ifsc : ''}
                            onChange={handleChange}
                            autoFocus={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                        <FormDatePicker
                            tabIndex={2}
                            label='Joining Date'
                            name='joining_date'
                            placeholder="Year and Month"
                            error={(action.isSubmitted && (errors.joining_date)) ? (errors.joining_date) : ''}
                            onChange={handleChange}
                            value={data.joining_date}
                            views={['month', 'year']}
                            inputFormat='MMMM yyyy'
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormPassword
                            tabIndex={4}
                            label='Password'
                            name='password'
                            value={data.password}
                            error={action.isSubmitted && errors.password ? errors.password : ''}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormPassword
                            tabIndex={5}
                            label='Re-enter password'
                            name='confirm_password'
                            value={data.confirm_password}
                            error={action.isSubmitted && errors.confirm_password ? errors.confirm_password : ''}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: `0` }}>
                        <Typography variant="caption">Use 6 or more characters with a mix of letters, numbers and symbols</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" alignItems="center" justifyContent="space-between" >
                        <RouteLink to={PreAuthRoutes('login').path} label={`${PreAuthRoutes('login').name} instead`} tabIndex={9} />
                        <SubmitButton tabIndex={8} label={'Create'} loading={isLoading} />
                    </Grid>
                </Grid>
            </form>
        </PreSignIn>
    );
}

export { Register };



