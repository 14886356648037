/**
 =========================================================
 * Akshay's Template - v0.0.0
 =========================================================
 *
 * Created by Akshay N on 01 January 2022
 */

import colors from "../base/colors";

const { info, dark } = colors;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  html: {
    scrollBehavior: "smooth",
  },
  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
  },
  "a, a:link, a:visited": {
    textDecoration: "none !important",
  },
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    color: `${dark.main} !important`,
    transition: "color 150ms ease-in !important",
  },
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: `${info.main} !important`,
  },
};
