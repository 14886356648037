import { apiServices } from '../../_helpers';


export const DashboardServices = {
    /**
     * Service to get influencer count
     */
    influencerCount: (params) => {
        return apiServices.post('/agent/influencer/count', params)
            .then((response) => { return response; })

    },

    /**
     * Service to get brand count
     */
    brandCount: (params) => {
        return apiServices.post('/agent/brand/count', params)
            .then((response) => { return response; })

    },

    /**
     * Service to get promo count
     */
    promoCount: (params) => {
        return apiServices.post('/agent/promo/count', params)
            .then((response) => { return response; })

    },

    /**
     * Service to get story count
     */
    storyCount: (params) => {
        return apiServices.post('/agent/story/count', params)
            .then((response) => { return response; })

    },

    /**
     * Service to get top influencers by followers count
     */
    getTopInfluencersByFollowers: (params) => {
        return apiServices.post('/agent/influencer/top-influencers-by-followers', params)
            .then((response) => { return response; })
    },

    /**
     * Service to get top influencers by stories count
     */
    getTopInfluencersByStories: (params) => {
        return apiServices.post('/agent/influencer/top-influencers-by-stories', params)
            .then((response) => { return response; })
    },

    /**
     * 
     * Service to get top brands by followers count
     */
    getTopBrandsByFollowers: (params) => {
        return apiServices.post('/agent/brand/top-brands-by-followers', params)
            .then((response) => { return response; })
    },

    /**
     * 
     * Service to get top brands by stories count
     */
    getTopBrandsByStories: (params) => {
        return apiServices.post('/agent/brand/top-brands-by-stories', params)
            .then((response) => { return response; })
    },

    /**
     * 
     * Service to get today leave information
     */
    leavesToday: (params) => {
        return apiServices.post('/agent/leaves/approved-leaves-today', params)
            .then((response) => { return response; })
    },

    /**
     * 
     * Service to get Pervious companies list
     */
    companiesList: (params) => {
        return apiServices.post('/agent/companies', params)
            .then((response) => { return response; })
    },


}