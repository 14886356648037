import { apiServices, cookieServices } from '../../_helpers';

export const UserServices = {

    /**
     * Service for get user details
     * @author Akshay 
     */
    getUserDetails: () => {
        return apiServices.get('/agent')
            .then((response) => { return response; })
    },
    /**
     * Service for delete account
     *
     * @author Akshay 
     */
    deleteAccount: () => {
        return apiServices.put('/agent/delete')
            .then((response) => { cookieServices.remove('accessToken'); return response; })
    },
    /**
     * Service for change password
     * @param {Object} params - The params which are used for change password api.
     * @param {string} params.current_password - Current password of the admin user
     * @param {string} params.new_password - New password the admin user
     * @param {string} params.confirm_password - Password re-entered by the admin user
     * @author Akshay 
     */
    changePassword: (params) => {

        return apiServices.put('/agent/change-password', params)
            .then((response) => { cookieServices.remove('accessToken'); return response; })
    },

    /**
     * Service to get login activity list
     * @author Jasin 
     */
    getActivityList: (params) => {
        return apiServices.post('/agent/activity-log/list', params)
            .then((response) => { return response; })
    },

    /**
     * Service for get user details
     * @author  
     */
    getUserPermission: () => {
        return apiServices.get('/agent/agent-permission')
            .then((response) => { return response; })
    },

    /**
     * Service for update profile picture
     * @author Naveen
     */
    updateProfilePicture: (params) => {
        console.log("PGPP: ",params.get("profile_picture"))
        return apiServices.post('/agent/update_profile_picture', params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => { return response; })
    },

};
