import { DesignationServices } from "../services";

export const DESIGNATION_SELECT_LIST_REQUEST = 'DESIGNATION_SELECT_LIST_REQUEST';
export const DESIGNATION_SELECT_LIST_SUCCESS = 'DESIGNATION_SELECT_LIST_SUCCESS';
export const DESIGNATION_SELECT_LIST_FAILURE = 'DESIGNATION_SELECT_LIST_FAILURE';

export const DesignationAction = {

    /**
     * Action for designation select list
     * @param {Object} params - The params which are used for the api
     * @author Naveen  
     * @created_at 21 September 2022
    */
    designationSelectList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return DesignationServices.designationSelectList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: DESIGNATION_SELECT_LIST_REQUEST, request: request } }
        function success(request, response) { return { type: DESIGNATION_SELECT_LIST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: DESIGNATION_SELECT_LIST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

}