import { GlobalAction } from ".";
import { UserServices } from "../services";

export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const GET_ACTIVITY_LOG_REQUEST = 'GET_ACTIVITY_LOG_REQUEST';
export const GET_ACTIVITY_LOG_SUCCESS = 'GET_ACTIVITY_LOG_SUCCESS';
export const GET_ACTIVITY_LOG_FAILURE = 'GET_ACTIVITY_LOG_FAILURE';

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const USER_PERMISSION_REQUEST = 'USER_PERMISSION_REQUEST';
export const USER_PERMISSION_SUCCESS = 'USER_PERMISSION_SUCCESS';
export const USER_PERMISSION_FAILURE = 'USER_PERMISSION_FAILURE';

export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE';

export const UserAction = {

    /**
    * Action for get user details
    * @param {Object} params - The params which are used for the api
    * @author Akshay  
    * @created_at 21 September 2022
    */
    getUserDetails: () => {
        return dispatch => {
            dispatch(request());
            dispatch(GlobalAction.pageLoader(true));
            return UserServices.getUserDetails()
                .then(
                    response => { dispatch(GlobalAction.pageLoader(false)); return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request() { return { type: USER_REQUEST } }
        function success(response) { return { type: USER_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: USER_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
    /**
     * Action for delete account
     * @param {Object} params - The params which are used for the api
     * @author Akshay  
     * @created_at 21 September 2022
     */
    deleteAccount: () => {
        return dispatch => {
            dispatch(GlobalAction.pageLoader(true));
            // dispatch(GlobalAction.showPageLoader());
            return UserServices.deleteAccount()
                .then(
                    response => { dispatch(GlobalAction.pageLoader(false)); return response; },
                    error => { return error; }
                );
        };

    },
    /**
     * Action for change password
     * @param {Object} params - The params which are used for change password api.
     * @param {string} params.current_password - Current password of the admin user
     * @param {string} params.new_password - New password the admin user
     * @param {string} params.confirm_password - Password re-entered by the admin user
     * @author Akshay  
     * @created_at 21 September 2022
     */
    changePassword: (params) => {
        return dispatch => {
            dispatch(request(params));
            return UserServices.changePassword(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_PASSWORD_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_PASSWORD_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_PASSWORD_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action to get Activity List
     * @param {Object} params - The params which are used for change password api.
     * @author Akshay  
     * @created_at 21 September 2022
     */
    getActivityList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return UserServices.getActivityList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GET_ACTIVITY_LOG_REQUEST, request: request } }
        function success(request, response) { return { type: GET_ACTIVITY_LOG_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GET_ACTIVITY_LOG_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action to get user List
     * @param {Object} params - The params which are used for change password api.
     * @author Akshay  
     * @created_at 21 September 2022
     */
    getUsersList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return UserServices.getUsersList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GET_USERS_REQUEST, request: request } }
        function success(request, response) { return { type: GET_USERS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GET_USERS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
    * Action for get user details
    * @param {Object} params - The params which are used for change password api.
    * @author Akshay  
    * @created_at 21 September 2022
    */
    getUserPermission: () => {
        return dispatch => {
            dispatch(request());
            return UserServices.getUserPermission()
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request() { return { type: USER_PERMISSION_REQUEST } }
        function success(response) { return { type: USER_PERMISSION_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: USER_PERMISSION_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for update profile picture
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 June 2022
     */
    updateProfilePicture: (params) => {
        return dispatch => {
            dispatch(request(params));
            return UserServices.updateProfilePicture(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        }

        function request(request) { return { type: UPDATE_USER_PROFILE_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_USER_PROFILE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_USER_PROFILE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },
};



