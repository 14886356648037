import { ForgotPasswordServices } from "../services";

export const SEND_PWD_RST_LINK_REQUEST = 'SEND_PWD_RST_LINK_REQUEST';
export const SEND_PWD_RST_LINK_SUCCESS = 'SEND_PWD_RST_LINK_SUCCESS';
export const SEND_PWD_RST_LINK_FAILURE = 'SEND_PWD_RST_LINK_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const ForgotPasswordAction = {

    /**
     * Action for forgot password
     * @param {Object} params - The params which are used for send password reset link api.
     * @param {string} params.email - Email of the admin user
     * @author Akshay 
     */
    sendPasswordResetLink: (params) => {
        return dispatch => {
            dispatch(request(params));
            return ForgotPasswordServices.sendPasswordResetLink(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: SEND_PWD_RST_LINK_REQUEST, request: request } }
        function success(request, response) { return { type: SEND_PWD_RST_LINK_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: SEND_PWD_RST_LINK_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for forgot password
     * @param {Object} params - The params which are used for send password reset link api.
     * @param {string} params.email - Email of the admin user
     * @author Jasin
     */
    resetPassword: (params) => {
        return dispatch => {
            dispatch(request(params));
            return ForgotPasswordServices.resetPassword(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: RESET_PASSWORD_REQUEST, request: request } }
        function success(request, response) { return { type: RESET_PASSWORD_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: RESET_PASSWORD_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

};
