import { apiServices } from '../../_helpers';

export const LeavesServices = {

    /**
     * Service for create leaves Request
     * @param {Object} params - The params which are used for the api
     * @author Naveen  
     * @created_at 21 September 2022 
     */
    createLeaveRequest: (params) => {
        return apiServices.post('/agent/leaves/create', params)
            .then((response) => { return response; })
    },

    /**
     * Service to get all Leaves submitted
     * @param {Object} params - The params which are used for the api
     * @author Naveen 
     * @created_at 21 September 2022
    */
    listLeaves: (params) => {
        return apiServices.post('agent/leaves', params)
            .then((response) => { return response; })
    },

    /**
    * Service for deleting a Leave request
    * @param {Object} params - The params which are used for the api
    * @author Naveen 
    * @created_at 21 September 2022
   */
    deleteLeave: (params) => {
        return apiServices.put('agent/leaves/delete', params)
            .then((response) => { return response; })
    },

    /**
     * Action to get all approved leaves
     * @param {Object} params - The params which are used for the api
     * @author Naveen  
     * @created_at 03 October 2022
    */
    getAllApprovedLeaves: (params) => {
        return apiServices.post('agent/leaves/approved-leaves', params)
            .then((response) => { return response; })
    },
}