import { Box, CircularProgress, Divider, Grid, Typography, Stack } from '@mui/material'
import React from 'react'
import { useSelector } from "react-redux";
import { Card, CardContent } from '../../../_components/card'
import { PayslipDataTable } from '.'
import { PayslipCardLoader } from '.'

function PayslipCard({ filter }) {

    /** Redux actions and state */
    const { user_loading: isLoading, user } = useSelector((state) => state.UserReducer);

    if (!isLoading)
        return (
            <Card>
                <CardContent>

                    <Grid container spacing={3} display={`flex`} alignItems={`center`} justifyContent={`center`} sx={{ paddingLeft: '10px', paddingTop: '30px', paddingRight: '10px' }}>

                        <Grid item xs={12} sm={12} md={8} lg={8} xl={12} >
                            <PayslipDataTable filter={filter} />
                        </Grid>

                    </Grid>
                </CardContent>
            </Card>
        )
    else
        return (
            <Card>
                <CardContent>
                    <PayslipCardLoader />
                </CardContent>
            </Card>
        )
}

export { PayslipCard }