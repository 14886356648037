import React from 'react';
import { Box, CardContent, Grid, Divider, Skeleton } from '@mui/material';

function AccountStatusCardLoader({ ...props }) {

    var rows = [];
    for (var i = 0; i < 1; i++) {
        rows.push(
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={i} style={{ paddingTop: '10px', height: `550px` }} {...props}>
                <CardContent style={{ position: "relative", height: `350px`, top: 0, right: 0, left: 0, bottom: 0 }}>
                    <Grid container spacing={0} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={6} >
                            <Box style={{ paddingTop: 60, minHeight: `120px`, paddingLeft: `30px` }}>
                                <Skeleton animation="wave" height={40} width="50%" style={{ marginBottom: 5 }} />
                            </Box>
                            <CardContent style={{ paddingTop: `90px`, minHeight: `120px` }} >
                                <Skeleton animation="wave" height={10} width="60%" style={{ marginBottom: 5 }} />
                                <Skeleton animation="wave" height={10} width="90%" style={{ marginBottom: 5 }} />
                                <Skeleton animation="wave" height={10} width="70%" style={{ marginBottom: 5 }} />
                                <Skeleton animation="wave" height={10} width="100%" style={{ marginBottom: 5 }} />
                                <Skeleton animation="wave" height={10} width="70%" style={{ marginBottom: 5 }} />
                                <Skeleton animation="wave" height={10} width="100%" style={{ marginBottom: 5 }} />
                                <Skeleton animation="wave" height={10} width="70%" style={{ marginBottom: 5 }} />
                                <Skeleton animation="wave" height={10} width="100%" style={{ marginBottom: 5 }} />
                                <Skeleton animation="wave" height={10} width="70%" style={{ marginBottom: 5 }} />
                                <Skeleton animation="wave" height={10} width="100%" style={{ marginBottom: 10 }} />
                                <Skeleton animation="wave" height={60} width="40%" style={{ marginBottom: 10, marginTop: 10 }} />
                            </CardContent>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                            <CardContent style={{ paddingTop: `30px`, minHeight: `120px`, paddingLeft: `30px` }} >
                                <Skeleton variant="rectangular" animation="wave" height={400} width="100%" style={{ marginBottom: 5, borderRadius: 8 }} />
                            </CardContent>
                        </Grid>
                    </Grid>

                </CardContent>
            </Grid>
        )
    }

    return <React.Fragment> {rows} </React.Fragment>
}

export { AccountStatusCardLoader };