import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import { PostAuthRoutes } from "../../../routes";
import { handleInputChange, validateForm, APP_NAME } from "../../../_helpers";

import { Box } from "../../../_components/box";
import { SubmitButton, CancelButton } from "../../../_components/controls";
import { FormInput, FormSelect, FormDateRangePicker } from "../../../_components/form";
import { Card, CardContent } from "../../../_components/card";
import { Grid, Stack } from "@mui/material";
import { LeavesAction } from "../../../redux/actions";
import { validate } from "./validate";
import { PageHeader } from "../../../_components";

// Initialize form input values to null
const inputs = { leave_type: '', date: '', reason: '' };

// Declare Leave Type select options
const leave_type_select_list = [
    { value: 'Full Day', label: "Full day" },
    { value: 'Morning shift', label: "Morning shift" },
    { value: 'Afternoon shift', label: "Afternoon shift" }
]

function CreateLeaveRequest() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Redux actions and state */
    const createLeaveRequest = (params) => dispatch(LeavesAction.createLeaveRequest(params));

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });

    /**
     * function to handle input changes and modify the value
     * @author Naveen  
     * @created_at 21 September 2022 
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author Naveen  
     * @created_at 21 September 2022  
     */
    function handleSubmit(e) {

        e.preventDefault();
        setAction({ isSubmitted: true });

        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        const formData = {
            "leave_type": data.leave_type,
            "date": data.date,
            "reason": data.reason,
        }

        addLeavesRequest(formData);
    }

    /**
     * function to create Leave Request
     * @author Naveen  
     * @created_at 21 September 2022 
     */
    async function addLeavesRequest(formData) {

        const isCreated = await createLeaveRequest(formData)

        if (isCreated && isCreated.status === 1) {
            let redirectPath = PostAuthRoutes('leave').path;
            history.push(redirectPath);
        }

    }

    /**
     * function to cancel create leave request
     * @param {object} formData form object
     * @author Naveen  
     * @created_at 21 September 2022 
     */
    function cancelLeaveRequest() {
        let redirectPath = PostAuthRoutes('leave').path;
        history.push(redirectPath);
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{APP_NAME} | ${PostAuthRoutes('create_leave').name}</title>
            </Helmet>
            <PageHeader title={PostAuthRoutes('create_leave').name} />
            <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Card>
                            <CardContent >
                                <Grid container spacing={2} justifyContent="flex-start" alignItems="center" >
                                    <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                                        <Grid container spacing={3}>

                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                                <FormSelect
                                                    tabIndex={4}
                                                    label={`Leave Type`}
                                                    name={`leave_type`}
                                                    placeholder={`Select Leave Type`}
                                                    onChange={handleChange}
                                                    data={leave_type_select_list}
                                                    error={(action.isSubmitted && (errors.leave_type)) ? (errors.leave_type) : ''}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={6} >
                                                <FormDateRangePicker
                                                    tabIndex={2}
                                                    label='Select Date'
                                                    name='date'
                                                    placeholder="dd/mm/yyyy - dd/mm/yyyy"
                                                    onChange={handleChange}
                                                    error={(action.isSubmitted && (errors.date)) ? (errors.date) : ''}
                                                    value={data.date}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FormInput
                                                    tabIndex={4}
                                                    label='Reason'
                                                    name='reason'
                                                    value={data.reason}
                                                    error={(action.isSubmitted && (errors.reason)) ? (errors.reason) : ''}
                                                    onChange={handleChange}
                                                    autoFocus={true}
                                                    multiline
                                                    maxLength={250}
                                                    minRows={5}
                                                />
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
                                            <SubmitButton label={'Submit'} tabIndex={6} />
                                            <CancelButton onClick={cancelLeaveRequest} tabIndex={12} />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </form>
        </React.Fragment>
    )

}

export { CreateLeaveRequest };