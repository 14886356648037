import {
    CREATE_ATTENDANCE_REQUEST, CREATE_ATTENDANCE_SUCCESS, CREATE_ATTENDANCE_FAILURE,
    GET_ALL_STAFF_ATTENDANCE_REQUEST, GET_ALL_STAFF_ATTENDANCE_SUCCESS, GET_ALL_STAFF_ATTENDANCE_FAILURE,
} from "../actions";

const initialState = {
    attendance_loading: false, attendance: [], attendance_errors: {},
    page: 1, per_page: 20, total: 0, total_pages: 0,
    calendar_staff_attendance_loading: false, calendar_staff_attendance: [], calendar_staff_attendance_errors: {},
};

export function AttendanceReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::Create attendance redux */
        case CREATE_ATTENDANCE_REQUEST: {
            return {
                ...state,
                attendance_loading: true,
                attendance_errors: {}
            };
        }
        case CREATE_ATTENDANCE_SUCCESS: {
            return {
                ...state,
                attendance_loading: false,
                attendance_errors: {},
            };
        }
        case CREATE_ATTENDANCE_FAILURE: {
            return {
                ...state,
                attendance_loading: false,
                attendance_errors: action.errors
            };
        }
        /** end::Create attendance redux */

        /** begin::Get attendance for calendar redux */
        case GET_ALL_STAFF_ATTENDANCE_REQUEST: {
            return {
                ...state,
                calendar_staff_attendance_loading: true,
                calendar_staff_attendance_errors: {}
            };
        }
        case GET_ALL_STAFF_ATTENDANCE_SUCCESS: {

            return {
                ...state,
                calendar_staff_attendance_loading: false,
                calendar_staff_attendance: action.data,
                calendar_staff_attendance_errors: {},
            };
        }
        case GET_ALL_STAFF_ATTENDANCE_FAILURE: {
            return {
                ...state,
                calendar_staff_attendance_loading: false,
                calendar_staff_attendance_errors: action.errors
            };
        }
        /** end::Get attendance for calendar redux */

        default:
            return state;
    }
}