import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router'
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { StaffExperienceAction } from '../../redux/actions'
import { DeleteModal } from '../../_components/modal/modals/DeleteModal';
import { Box } from '../../_components/box'
import { Grid, Stack } from '@mui/material'
import { StaffExperienceCard, StaffExperienceCardLoader } from ".";
import { PostAuthRoutes } from "../../routes";
import { NoData } from '../no-data'
import { GeneralButton } from "../../_components/controls";
import { PageHeader } from "../../_components";
import { AbilityCan } from '../../_helpers/permission/AbilityCan';

function StaffExperience() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let history = useHistory();

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);
    const { experience_loading: isLoading, staff_experience } = useSelector((state) => state.StaffExperienceReducer);
    const listStaffExperience = (params) => dispatch(StaffExperienceAction.listStaffExperience(params));
    const deleteStaffExperience = (params) => dispatch(StaffExperienceAction.deleteStaffExperience(params));

    /** Initialize and declare state */
    const [staffExperienceDeleteData, setStaffExperienceDeleteData] = useState({ id: '' });
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        getNext();
        //eslint-disable-next-line
    }, [])


    /**
     * function to list Staff Experience
     * @param {string} null 
     * @return view
     * @author Naveen
     * @created_at 21 Sept 2022
     */
    const getNext = async () => {
        const result = await listStaffExperience();

    }

    /**
     * function to create Staff Experience
     * @param {string} null 
     * @return view
     * @author Naveen
     * @created_at 21 Sept 2022
     */
    function createStaffExperience() {
        history.push({
            pathname: PostAuthRoutes('create_staff_experience').path,
            state: { staff_experience: staff_experience }
        });
    }

    /**
     * function to open/close Staff Experience delete modal
     * @return view
     * @author Naveen
     * @created_at 21 Sept 2022
     */
    const staffExperienceDeleteModal = async ({ id }) => {
        setStaffExperienceDeleteData({ id: id })
        setShowDeleteModal(!showDeleteModal)
    }

    /**
     * function to delete Staff Experience
     * @return view
     * @author Naveen
     * @created_at 21 Sept 2022
     */
    const staffExperienceDeleteAction = async (id) => {
        setShowDeleteModal(!showDeleteModal)
        await deleteStaffExperience({ 'experience_id': id });
    }

    return (
        <React.Fragment>
            <Box >
                <PageHeader title={PostAuthRoutes('staff_experience').name} action={
                    <Stack spacing={1} direction="row">
                        <AbilityCan I='create_staff_experience' passThrough={(user.role === 'developer') ? true : false}>
                            <GeneralButton label={<React.Fragment>Create</React.Fragment>} onClick={() => createStaffExperience()} />
                        </AbilityCan>
                    </Stack>
                } />
            </Box>
            <Box>
                <InfiniteScroll dataLength={staff_experience.length} next={getNext} hasMore={true}>
                    <Grid container spacing={2} direction="row" justifyContent="left" alignItems="center" >
                        {
                            staff_experience.length > 0 && staff_experience.map((staff_experiences, i) => {
                                return (
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={i}>
                                        <StaffExperienceCard staff_experiences={staff_experiences} staffExperienceDeleteModal={staffExperienceDeleteModal} />
                                    </Grid>
                                );
                            })
                        }
                        {isLoading && <StaffExperienceCardLoader />}
                        {!isLoading && staff_experience.length === 0 && <NoData content1={`No`} content2={`staff Experience available`} description={`Please click below button to create new staff Experience.`} minHeight={`55vh`} ><GeneralButton label={<React.Fragment>Create</React.Fragment>} onClick={() => createStaffExperience()} /></NoData>}
                    </Grid>
                </InfiniteScroll>
            </Box>

            <DeleteModal show={showDeleteModal} closeModal={staffExperienceDeleteModal} data={staffExperienceDeleteData} deleteModal={staffExperienceDeleteAction} />
        </React.Fragment>

    )

}

export { StaffExperience }