import React from 'react'
import { useSelector } from 'react-redux'

import { Box, Grid, Typography } from '@mui/material'
import { Card, CardContent, CardHeader } from '../../../_components/card'
import { GeneralButton } from '../../../_components/controls/general-button/GeneralButton';
import { UserAction } from '../../../redux/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PreAuthRoutes } from '../../../routes';
import { SweetAlert } from '../../../_components';
import { AccountStatusCardLoader } from '.'


function AccountStatus() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Redux actions and state */
    const { user_loading: isLoading } = useSelector((state) => state.UserReducer)
    const deleteBrandAccount = () => dispatch(UserAction.deleteAccount());

    /**
     * function to delete account
     * @author Akshay N
     * @created_at 
     */
    function deleteAccount() {

        SweetAlert.fire({
            title: 'Are you sure?',
            text: "When you delete your account, you won't be able to retrieve the content or information you've shared.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true,
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                deleteBrandAccount().then(() => {
                    let timerInterval;
                    SweetAlert.fire({
                        title: 'Deleted!',
                        html: "Your account has been deleted.You will be redirected to the signup screen soon!<br/><br/>I will close in <b></b> milliseconds.",
                        icon: 'success',
                        timer: 2000,
                        timerProgressBar: true,
                        didOpen: () => {
                            SweetAlert.showLoading()
                            const b = SweetAlert.getHtmlContainer().querySelector('b')
                            timerInterval = setInterval(() => { b.textContent = SweetAlert.getTimerLeft() }, 200)
                        },
                        willClose: () => {
                            setTimeout(() => {

                                let redirectPath = PreAuthRoutes('login').path;
                                history.push(redirectPath);

                            }, 4000);
                            clearInterval(timerInterval)
                        }
                    })
                });
            }
        })

    }

    return (
        <React.Fragment>
            <Box>
                <Grid container spacing={1}  >
                    {!isLoading &&
                        <Card sx={{ p: `70px !important` }}>
                            <CardHeader title={`Deleting your account`} />
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} display={`flex`} alignItems={`center`}>
                                        <Box>
                                            <Typography variant="body2" mb={1}>This is permanent.When you delete your account, you won't be able to retrieve the content or information you've shared.If you want to delete your account please click on the below button.</Typography>
                                            <GeneralButton variant="contained" color='error' label="Delete Account" onClick={deleteAccount} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} display={`flex`} alignItems={`center`} justifyContent={`end`}>
                                        <svg width="300" height="374" viewBox="0 0 300 374" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_120_30)">
                                                <path d="M298.366 344.197C298.169 343.594 296.674 343.86 296.53 343.32C296.387 342.782 297.822 342.331 298.922 340.815C299.12 340.541 300.371 338.819 299.891 338.094C298.982 336.721 292.658 340.141 291.14 338.222C290.807 337.801 290.67 337.077 289.678 335.732C289.283 335.196 289.03 334.938 288.727 334.945C288.298 334.956 288.144 335.493 287.469 336.467C286.457 337.926 286.092 337.743 285.425 338.938C284.93 339.826 284.871 340.393 284.514 340.427C284.009 340.474 283.8 339.369 283.183 339.326C282.553 339.282 281.923 340.374 281.611 341.227C281.024 342.829 281.387 344.052 281.468 345.272C281.555 346.597 281.35 348.537 279.91 351.028L266.327 372.669C269.244 368.257 277.525 356.227 280.861 351.814C281.824 350.54 282.859 349.261 284.452 349.02C285.985 348.788 287.852 349.547 290.456 349.503C290.76 349.498 291.607 349.473 291.813 349.024C291.983 348.653 291.56 348.331 291.714 347.982C291.92 347.514 292.848 347.715 294.113 347.404C295.005 347.185 295.611 346.823 296.132 346.512C296.289 346.418 298.629 345.001 298.366 344.197H298.366V344.197Z" fill="#F2F2F2" />
                                                <path d="M262.79 349.25C262.436 349.173 262.136 349.983 261.829 349.898C261.524 349.813 261.716 348.979 261.29 348.001C261.213 347.825 260.728 346.714 260.234 346.741C259.298 346.79 259.152 350.881 257.772 351.072C257.469 351.114 257.075 350.973 256.128 351.072C255.751 351.111 255.551 351.161 255.467 351.311C255.349 351.525 255.568 351.756 255.851 352.368C256.275 353.286 256.08 353.412 256.474 354.084C256.767 354.583 257.029 354.776 256.942 354.961C256.82 355.222 256.217 355.006 256.018 355.296C255.815 355.593 256.169 356.217 256.497 356.617C257.114 357.366 257.819 357.541 258.441 357.854C259.117 358.193 260.01 358.854 260.817 360.279L267.519 373.19C266.196 370.485 262.68 362.95 261.477 360.039C261.13 359.199 260.8 358.322 261.142 357.471C261.471 356.651 262.382 355.954 263.111 354.663C263.197 354.512 263.429 354.09 263.268 353.859C263.135 353.668 262.855 353.783 262.728 353.607C262.558 353.371 262.924 352.973 263.136 352.263C263.286 351.762 263.283 351.36 263.281 351.015C263.28 350.91 263.26 349.353 262.79 349.25V349.25H262.79Z" fill="#F2F2F2" />
                                                <path d="M269.476 352.4L269.687 350.455L269.816 350.333C270.414 349.768 270.788 349.169 270.928 348.553C270.951 348.455 270.968 348.356 270.986 348.255C271.056 347.855 271.142 347.358 271.527 346.842C271.742 346.554 272.311 345.904 272.898 346.131C273.057 346.19 273.164 346.293 273.238 346.402C273.257 346.383 273.276 346.364 273.296 346.344C273.55 346.097 273.727 346.007 273.898 345.92C274.03 345.853 274.165 345.785 274.395 345.601C274.495 345.521 274.576 345.448 274.644 345.387C274.85 345.203 275.113 344.988 275.474 345.073C275.857 345.178 275.988 345.543 276.075 345.784C276.23 346.215 276.278 346.512 276.31 346.708C276.321 346.78 276.335 346.861 276.345 346.887C276.428 347.098 277.48 347.153 277.875 347.176C278.764 347.224 279.533 347.266 279.718 347.874C279.851 348.31 279.575 348.785 278.872 349.324C278.654 349.491 278.435 349.607 278.245 349.694C278.362 349.804 278.455 349.955 278.461 350.16C278.474 350.646 277.975 351.019 276.98 351.27C276.733 351.333 276.4 351.417 275.965 351.396C275.76 351.385 275.58 351.354 275.427 351.325C275.404 351.412 275.362 351.503 275.287 351.589C275.065 351.842 274.708 351.907 274.222 351.764C273.686 351.614 273.262 351.411 272.888 351.233C272.562 351.077 272.28 350.944 272.044 350.908C271.605 350.846 271.195 351.118 270.705 351.479L269.476 352.4V352.4H269.476Z" fill="#F2F2F2" />
                                                <path d="M272.982 341.321L271.048 341.611L270.897 341.517C270.198 341.082 269.525 340.872 268.893 340.893C268.793 340.896 268.692 340.904 268.59 340.913C268.186 340.947 267.683 340.989 267.086 340.748C266.753 340.613 265.98 340.227 266.05 339.601C266.068 339.433 266.14 339.304 266.226 339.204C266.203 339.191 266.18 339.177 266.155 339.162C265.853 338.98 265.72 338.831 265.593 338.688C265.495 338.578 265.394 338.464 265.158 338.289C265.055 338.212 264.964 338.152 264.888 338.102C264.658 337.949 264.383 337.749 264.374 337.378C264.378 336.982 264.698 336.762 264.909 336.617C265.287 336.357 265.561 336.235 265.743 336.155C265.81 336.126 265.885 336.092 265.908 336.076C266.091 335.942 265.877 334.911 265.798 334.523C265.62 333.65 265.465 332.896 266.007 332.562C266.394 332.323 266.924 332.47 267.623 333.013C267.841 333.182 268.008 333.364 268.14 333.526C268.218 333.385 268.34 333.256 268.537 333.199C269.003 333.063 269.49 333.451 269.986 334.35C270.11 334.573 270.275 334.874 270.365 335.3C270.407 335.501 270.422 335.683 270.433 335.838C270.523 335.838 270.621 335.856 270.724 335.907C271.025 336.057 271.178 336.386 271.163 336.892C271.155 337.449 271.066 337.91 270.988 338.317C270.92 338.672 270.863 338.978 270.888 339.216C270.94 339.657 271.306 339.984 271.779 340.367L272.983 341.321H272.982V341.321Z" fill="#F2F2F2" />
                                                <path d="M273.243 335.511L273.454 333.566L273.583 333.443C274.181 332.878 274.555 332.28 274.695 331.664C274.718 331.566 274.735 331.467 274.753 331.366C274.823 330.966 274.91 330.469 275.294 329.952C275.509 329.665 276.078 329.014 276.666 329.242C276.824 329.301 276.931 329.404 277.005 329.513C277.024 329.494 277.043 329.475 277.063 329.454C277.317 329.208 277.494 329.118 277.665 329.031C277.797 328.964 277.932 328.895 278.162 328.712C278.262 328.631 278.343 328.559 278.411 328.498C278.617 328.314 278.88 328.098 279.241 328.184C279.624 328.288 279.755 328.653 279.842 328.895C279.997 329.326 280.045 329.622 280.077 329.819C280.088 329.891 280.102 329.971 280.112 329.998C280.195 330.209 281.247 330.263 281.642 330.286C282.531 330.334 283.301 330.376 283.486 330.985C283.618 331.421 283.342 331.896 282.64 332.434C282.421 332.602 282.202 332.718 282.012 332.804C282.129 332.915 282.222 333.066 282.228 333.271C282.241 333.757 281.743 334.129 280.747 334.381C280.5 334.444 280.167 334.527 279.732 334.506C279.527 334.496 279.347 334.465 279.194 334.435C279.171 334.522 279.129 334.613 279.054 334.699C278.832 334.953 278.475 335.017 277.989 334.874C277.453 334.725 277.029 334.522 276.655 334.343C276.329 334.188 276.047 334.055 275.811 334.018C275.372 333.957 274.962 334.228 274.472 334.589L273.243 335.511H273.243V335.511Z" fill="#F2F2F2" />
                                                <path d="M266.969 372.78L266.627 372.442L266.633 371.961L266.627 372.442L266.151 372.391C266.154 372.347 266.151 372.242 266.147 372.079C266.129 371.184 266.072 368.461 266.451 364.276C266.716 361.355 267.158 358.391 267.766 355.462C268.375 352.53 269.007 350.348 269.515 348.594C269.898 347.271 270.284 346.017 270.662 344.794C271.669 341.529 272.62 338.445 273.209 334.927C273.341 334.142 273.615 332.507 273.054 330.577C272.729 329.458 272.172 328.405 271.399 327.448L272.147 326.843C273.001 327.901 273.616 329.068 273.978 330.31C274.599 332.449 274.301 334.231 274.157 335.087C273.558 338.667 272.597 341.781 271.58 345.077C271.205 346.295 270.819 347.544 270.438 348.862C269.935 350.599 269.309 352.762 268.708 355.657C268.107 358.55 267.67 361.479 267.408 364.363C267.034 368.494 267.09 371.177 267.109 372.059C267.118 372.529 267.12 372.628 266.969 372.78V372.78H266.969Z" fill="#F2F2F2" />
                                                <path d="M269.346 328.555C269.307 328.546 269.268 328.536 269.23 328.525C268.44 328.304 267.807 327.66 267.347 326.613C267.132 326.121 267.081 325.602 266.98 324.569C266.964 324.409 266.893 323.603 266.98 322.534C267.036 321.836 267.111 321.556 267.301 321.336C267.512 321.09 267.796 320.95 268.097 320.879C268.105 320.787 268.135 320.696 268.195 320.611C268.442 320.255 268.856 320.401 269.081 320.477C269.194 320.518 269.335 320.569 269.493 320.596C269.742 320.639 269.891 320.595 270.116 320.53C270.332 320.468 270.6 320.39 270.97 320.396C271.701 320.406 272.242 320.735 272.42 320.843C273.359 321.407 273.684 322.308 274.059 323.351C274.134 323.56 274.381 324.315 274.439 325.288C274.48 325.99 274.388 326.277 274.283 326.483C274.068 326.905 273.738 327.101 272.919 327.513C272.064 327.944 271.635 328.16 271.283 328.271C270.464 328.528 269.95 328.689 269.346 328.555V328.555V328.555Z" fill="#F2F2F2" />
                                                <path d="M50.0006 344.197C49.8033 343.594 48.3084 343.86 48.1649 343.32C48.0218 342.782 49.4563 342.331 50.5565 340.815C50.7549 340.541 52.0052 338.819 51.5257 338.094C50.6168 336.721 44.2929 340.141 42.775 338.222C42.4418 337.801 42.3047 337.077 41.3123 335.732C40.9175 335.196 40.6642 334.938 40.3616 334.945C39.9322 334.956 39.7785 335.493 39.1036 336.467C38.0914 337.926 37.7262 337.743 37.0594 338.938C36.5644 339.826 36.5057 340.393 36.1488 340.427C35.6437 340.474 35.4349 339.369 34.8177 339.326C34.1879 339.282 33.5577 340.374 33.2452 341.227C32.6589 342.829 33.022 344.052 33.1025 345.272C33.1899 346.597 32.9846 348.537 31.5445 351.028L17.9614 372.669C20.8785 368.257 29.159 356.227 32.4952 351.814C33.4583 350.54 34.4933 349.261 36.0863 349.02C37.62 348.788 39.4861 349.547 42.0908 349.503C42.3949 349.498 43.2415 349.473 43.4474 349.024C43.6177 348.653 43.195 348.331 43.3487 347.982C43.555 347.514 44.483 347.715 45.7476 347.404C46.6394 347.185 47.2452 346.823 47.7662 346.512C47.9233 346.418 50.2632 345.001 50.0006 344.197H50.0006L50.0006 344.197Z" fill="#F2F2F2" />
                                                <path d="M14.4241 349.25C14.0709 349.173 13.77 349.983 13.4637 349.898C13.1584 349.813 13.3509 348.979 12.9243 348.001C12.8474 347.825 12.3627 346.714 11.8687 346.741C10.9324 346.79 10.7866 350.881 9.40647 351.072C9.1036 351.114 8.70909 350.973 7.76219 351.072C7.38548 351.111 7.18554 351.161 7.10197 351.311C6.98339 351.525 7.20272 351.756 7.4856 352.368C7.90984 353.286 7.71451 353.412 8.10901 354.084C8.40186 354.583 8.66331 354.776 8.57672 354.961C8.45421 355.222 7.85156 355.006 7.65243 355.296C7.44924 355.593 7.80337 356.217 8.13197 356.617C8.74863 357.366 9.454 357.541 10.0758 357.854C10.7516 358.193 11.6445 358.854 12.4512 360.279L19.1537 373.19C17.8301 370.485 14.3147 362.95 13.1115 360.039C12.7641 359.199 12.4349 358.322 12.7764 357.471C13.1052 356.651 14.0161 355.954 14.7459 354.663C14.8312 354.512 15.0631 354.09 14.9023 353.859C14.7694 353.668 14.4891 353.783 14.3623 353.607C14.1923 353.371 14.5585 352.973 14.7709 352.263C14.9207 351.762 14.9179 351.36 14.9155 351.015C14.9147 350.91 14.8942 349.353 14.4241 349.25V349.25H14.4241Z" fill="#F2F2F2" />
                                                <path d="M21.1108 352.4L21.3215 350.455L21.4507 350.333C22.0486 349.768 22.4225 349.169 22.5628 348.553C22.5852 348.455 22.6024 348.356 22.6201 348.255C22.6901 347.855 22.7769 347.358 23.1614 346.842C23.3766 346.554 23.9459 345.904 24.5329 346.131C24.6914 346.19 24.7983 346.293 24.8726 346.402C24.8913 346.383 24.9104 346.364 24.9308 346.344C25.1844 346.097 25.3618 346.007 25.5327 345.92C25.664 345.853 25.7996 345.785 26.0291 345.601C26.1297 345.521 26.2104 345.448 26.2783 345.387C26.4843 345.203 26.7478 344.988 27.1087 345.073C27.4913 345.178 27.6225 345.543 27.7095 345.784C27.8649 346.215 27.9128 346.512 27.9444 346.708C27.9559 346.78 27.9691 346.861 27.9791 346.887C28.0628 347.098 29.114 347.153 29.5096 347.176C30.3987 347.224 31.168 347.266 31.353 347.874C31.4858 348.31 31.2091 348.785 30.507 349.324C30.2882 349.491 30.0696 349.607 29.8791 349.694C29.9963 349.804 30.0896 349.955 30.0953 350.16C30.1082 350.646 29.61 351.019 28.6147 351.27C28.3673 351.333 28.0346 351.417 27.5993 351.396C27.3942 351.385 27.2146 351.354 27.0615 351.325C27.0387 351.412 26.9968 351.503 26.9213 351.589C26.6998 351.842 26.342 351.907 25.8564 351.764C25.3203 351.614 24.8967 351.411 24.5229 351.233C24.1964 351.077 23.9149 350.944 23.6787 350.908C23.2394 350.846 22.8297 351.118 22.3394 351.479L21.1109 352.4H21.1109H21.1108Z" fill="#F2F2F2" />
                                                <path d="M24.617 341.321L22.6821 341.611L22.5311 341.517C21.8329 341.082 21.1591 340.872 20.5276 340.893C20.4272 340.896 20.327 340.904 20.2249 340.913C19.8203 340.947 19.3174 340.989 18.7203 340.748C18.3874 340.613 17.6141 340.227 17.6849 339.601C17.7021 339.433 17.7744 339.304 17.8609 339.204C17.8378 339.191 17.8148 339.177 17.7898 339.162C17.4871 338.98 17.3548 338.831 17.2274 338.688C17.1293 338.578 17.0284 338.464 16.7926 338.289C16.6893 338.212 16.5987 338.152 16.5227 338.102C16.2925 337.949 16.0173 337.749 16.0083 337.378C16.0124 336.982 16.3322 336.762 16.5438 336.617C16.9212 336.357 17.1958 336.235 17.3779 336.155C17.4444 336.126 17.5193 336.092 17.5422 336.076C17.7251 335.942 17.5112 334.911 17.433 334.523C17.2541 333.65 17.0994 332.896 17.6413 332.562C18.0289 332.323 18.5586 332.47 19.2579 333.013C19.4756 333.182 19.643 333.364 19.7749 333.526C19.8523 333.385 19.9744 333.256 20.1714 333.199C20.638 333.063 21.1249 333.451 21.6204 334.35C21.7442 334.573 21.9094 334.874 21.9994 335.3C22.0416 335.501 22.0568 335.683 22.0673 335.838C22.1575 335.838 22.2558 335.856 22.3582 335.907C22.6597 336.057 22.8128 336.386 22.7977 336.892C22.7892 337.449 22.7001 337.91 22.6222 338.317C22.5546 338.672 22.4973 338.978 22.5221 339.216C22.5741 339.657 22.9404 339.984 23.414 340.367L24.6171 341.321H24.617H24.617Z" fill="#F2F2F2" />
                                                <path d="M24.8779 335.511L25.0886 333.566L25.2178 333.443C25.8156 332.878 26.1896 332.28 26.3299 331.664C26.3522 331.566 26.3695 331.467 26.3872 331.366C26.4572 330.966 26.544 330.469 26.9285 329.952C27.1437 329.665 27.713 329.014 28.3 329.242C28.4585 329.301 28.5654 329.404 28.6397 329.513C28.6584 329.494 28.6775 329.475 28.6979 329.454C28.9515 329.208 29.1289 329.118 29.2998 329.031C29.4311 328.964 29.5667 328.895 29.7962 328.712C29.8968 328.631 29.9774 328.559 30.0454 328.498C30.2514 328.314 30.5149 328.098 30.8758 328.184C31.2584 328.288 31.3895 328.653 31.4766 328.895C31.632 329.326 31.6799 329.622 31.7115 329.819C31.7229 329.891 31.7362 329.971 31.7462 329.998C31.8299 330.209 32.8811 330.263 33.2767 330.286C34.1658 330.334 34.9351 330.376 35.1201 330.985C35.2529 331.421 34.9762 331.896 34.274 332.434C34.0553 332.602 33.8367 332.718 33.6462 332.804C33.7633 332.915 33.8567 333.066 33.8624 333.271C33.8753 333.757 33.3771 334.129 32.3817 334.381C32.1344 334.444 31.8017 334.527 31.3664 334.506C31.1613 334.496 30.9817 334.465 30.8286 334.435C30.8058 334.522 30.7639 334.613 30.6884 334.699C30.4669 334.953 30.1091 335.017 29.6235 334.874C29.0874 334.725 28.6638 334.522 28.29 334.343C27.9635 334.188 27.682 334.055 27.4458 334.018C27.0064 333.957 26.5968 334.228 26.1065 334.589L24.878 335.511H24.8779L24.8779 335.511Z" fill="#F2F2F2" />
                                                <path d="M18.6036 372.78L18.2615 372.442L18.2676 371.961L18.2615 372.442L17.786 372.391C17.7882 372.347 17.7851 372.242 17.7816 372.079C17.7633 371.184 17.7066 368.461 18.0858 364.276C18.3506 361.355 18.793 358.391 19.4009 355.462C20.0098 352.53 20.6417 350.348 21.1493 348.594C21.5323 347.271 21.9189 346.017 22.2963 344.794C23.3035 341.529 24.2545 338.445 24.8435 334.927C24.9753 334.142 25.249 332.507 24.6887 330.577C24.3635 329.458 23.8068 328.405 23.0335 327.448L23.782 326.843C24.6353 327.901 25.251 329.068 25.6121 330.31C26.2334 332.449 25.9352 334.231 25.7919 335.087C25.1925 338.667 24.232 341.781 23.2149 345.077C22.8394 346.295 22.454 347.544 22.0727 348.862C21.5697 350.599 20.9434 352.762 20.3425 355.657C19.7415 358.55 19.3047 361.479 19.0428 364.363C18.6686 368.494 18.7248 371.177 18.7432 372.059C18.7527 372.529 18.7548 372.628 18.6036 372.78V372.78H18.6036Z" fill="#F2F2F2" />
                                                <path d="M20.9801 328.555C20.9416 328.546 20.9029 328.536 20.8641 328.525C20.0747 328.304 19.4415 327.66 18.9815 326.613C18.7662 326.121 18.7154 325.602 18.6141 324.569C18.5981 324.409 18.5279 323.603 18.6143 322.534C18.6705 321.836 18.7455 321.556 18.9355 321.336C19.1466 321.09 19.4309 320.95 19.7314 320.879C19.7395 320.787 19.7697 320.696 19.8297 320.611C20.0769 320.255 20.4906 320.401 20.7151 320.477C20.8282 320.518 20.9691 320.569 21.1277 320.596C21.3768 320.639 21.5256 320.595 21.7508 320.53C21.9662 320.468 22.2342 320.39 22.6045 320.396C23.3352 320.406 23.8761 320.735 24.0545 320.843C24.9939 321.407 25.3182 322.308 25.6936 323.351C25.7681 323.56 26.0157 324.315 26.0733 325.288C26.1149 325.99 26.0229 326.277 25.9175 326.483C25.7021 326.905 25.3723 327.101 24.5537 327.513C23.6985 327.944 23.2694 328.16 22.9176 328.271C22.0989 328.528 21.5849 328.689 20.9801 328.555V328.555V328.555Z" fill="#F2F2F2" />
                                                <path d="M207.441 0H73.4708C64.3326 0 56.925 7.40755 56.925 16.5457V307.412C56.925 316.551 64.3326 323.958 73.4708 323.958H207.441C216.58 323.958 223.987 316.551 223.987 307.412V16.5457C223.987 7.40754 216.58 0 207.441 0ZM201.837 299.704C201.837 306.482 196.34 311.974 189.562 311.974H92.8041C86.0268 311.974 80.5341 306.482 80.5341 299.704V41.6867C80.5341 34.9094 86.0268 29.4168 92.8041 29.4168H189.562C196.34 29.4168 201.837 34.9094 201.837 41.6867V299.704V299.704Z" fill="#F9F9F9" />
                                                <path d="M196.024 157.985C198.431 157.985 200.382 156.033 200.382 153.626C200.382 151.219 198.431 149.268 196.024 149.268C193.617 149.268 191.666 151.219 191.666 153.626C191.666 156.033 193.617 157.985 196.024 157.985Z" fill="#F9F9F9" />
                                                <path d="M196.128 174.981C198.535 174.981 200.486 173.03 200.486 170.623C200.486 168.216 198.535 166.265 196.128 166.265C193.721 166.265 191.77 168.216 191.77 170.623C191.77 173.03 193.721 174.981 196.128 174.981Z" fill="#F9F9F9" />
                                                <path d="M124.388 45.5796H101.322C97.8701 45.5796 95.0583 48.3865 95.0583 51.8382V202.747C95.0583 206.204 97.8701 209.011 101.322 209.011H124.388C125.542 209.011 126.623 208.696 127.554 208.153C128.688 207.488 129.59 206.475 130.123 205.263C130.462 204.492 130.651 203.644 130.651 202.747V51.8382C130.651 48.3865 127.844 45.5796 124.388 45.5796H124.388ZM129.197 202.747C129.197 204.046 128.678 205.229 127.83 206.097L127.825 206.102C127.738 206.194 127.651 206.281 127.554 206.364C126.705 207.105 125.6 207.556 124.388 207.556H101.322C98.67 207.556 96.5127 205.399 96.5127 202.747V51.8382C96.5127 49.1864 98.67 47.0291 101.322 47.0291H124.388C127.04 47.0291 129.197 49.1864 129.197 51.8382V202.747H129.197Z" fill="#F9F9F9" />
                                                <path d="M179.595 45.5796H156.524C153.073 45.5796 150.261 48.3865 150.261 51.8382V202.747C150.261 206.204 153.073 209.011 156.524 209.011H179.595C183.047 209.011 185.854 206.204 185.854 202.747V51.8382C185.854 48.3865 183.047 45.5796 179.595 45.5796ZM184.404 202.747C184.404 205.399 182.247 207.556 179.595 207.556H156.524C153.873 207.556 151.715 205.399 151.715 202.747V51.8382C151.715 49.1864 153.873 47.0291 156.524 47.0291H179.595C182.247 47.0291 184.404 49.1864 184.404 51.8382V202.747Z" fill="#F9F9F9" />
                                                <path d="M114.61 230.729L108.138 230.7V231.529H126.933C127.142 231.529 127.35 231.543 127.554 231.568V230.792L114.61 230.729ZM129.861 230.802C129.163 230.428 128.383 230.186 127.554 230.108C127.35 230.084 127.142 230.075 126.933 230.075H103.862C102.912 230.075 102.015 230.288 101.21 230.666C99.0821 231.67 97.6035 233.837 97.6035 236.338V286.281C97.6035 289.732 100.41 292.544 103.862 292.544H126.933C127.142 292.544 127.35 292.534 127.554 292.51C130.714 292.2 133.192 289.524 133.192 286.281V236.338C133.192 233.943 131.839 231.859 129.861 230.802V230.802ZM131.742 286.281C131.742 288.724 129.914 290.746 127.554 291.051C127.35 291.075 127.142 291.09 126.933 291.09H103.862C101.21 291.09 99.053 288.932 99.053 286.281V236.338C99.053 233.686 101.21 231.529 103.862 231.529H126.933C127.142 231.529 127.35 231.543 127.554 231.568C129.914 231.873 131.742 233.895 131.742 236.338V286.281V286.281ZM126.933 230.075H114.61V231.529H126.933C127.142 231.529 127.35 231.543 127.554 231.568V230.108C127.35 230.084 127.142 230.075 126.933 230.075Z" fill="#F9F9F9" />
                                                <path d="M159.477 230.942V231.529H169.274V230.991L159.477 230.942V230.942ZM185.51 231.064C184.729 230.564 183.828 230.23 182.863 230.118C182.625 230.089 182.383 230.075 182.136 230.075H159.065C157.916 230.075 156.844 230.385 155.918 230.928C154.057 232.014 152.806 234.035 152.806 236.338V286.281C152.806 289.732 155.613 292.544 159.065 292.544H182.136C185.587 292.544 188.399 289.732 188.399 286.281V236.338C188.399 234.127 187.245 232.179 185.51 231.064V231.064ZM186.945 286.281C186.945 288.932 184.787 291.09 182.136 291.09H159.065C156.413 291.09 154.261 288.932 154.261 286.281V236.338C154.261 233.686 156.413 231.529 159.065 231.529H182.136C184.787 231.529 186.945 233.686 186.945 236.338V286.281Z" fill="#F9F9F9" />
                                                <path d="M184.246 235.407C185.728 239.019 188.792 241.182 191.091 240.239C193.389 239.295 194.05 235.603 192.568 231.99C191.995 230.539 191.083 229.246 189.909 228.219L183.437 212.996L176.358 216.185L183.489 230.854C183.374 232.41 183.634 233.972 184.246 235.407V235.407Z" fill="#FFB6B6" />
                                                <path d="M168.95 139.548L172.661 138.063V138.063C177.028 144.857 179.43 152.726 179.602 160.8L180.081 183.329L190.47 221.915L178.597 226.368L163.756 178.134L168.95 139.548V139.548Z" fill="#2F2E41" />
                                                <path d="M176.604 247.209C176.137 247.209 175.664 247.139 175.199 246.994L162.572 243.049C161.111 242.592 159.999 241.497 159.523 240.042C159.046 238.587 159.294 237.046 160.201 235.813L195.459 187.945C196.318 186.778 197.643 186.085 199.092 186.044C200.55 186 201.902 186.62 202.825 187.737L210.012 196.423C211.34 198.029 211.44 200.348 210.255 202.062L180.439 245.19C179.546 246.481 178.108 247.209 176.604 247.209H176.604Z" fill="#E97E98" />
                                                <path d="M204.856 204.211C204.706 204.211 204.557 204.151 204.448 204.032L196.849 195.841C196.64 195.616 196.653 195.264 196.878 195.054C197.103 194.845 197.456 194.858 197.665 195.084L205.264 203.275C205.473 203.5 205.46 203.853 205.235 204.062C205.128 204.162 204.992 204.211 204.856 204.211V204.211Z" fill="white" />
                                                <path d="M201.515 209.222C201.365 209.222 201.216 209.163 201.106 209.044L193.508 200.853C193.298 200.628 193.312 200.275 193.537 200.066C193.762 199.857 194.115 199.87 194.324 200.096L201.923 208.287C202.132 208.512 202.119 208.865 201.893 209.074C201.786 209.173 201.65 209.222 201.515 209.222V209.222Z" fill="white" />
                                                <path d="M197.617 214.791C197.467 214.791 197.318 214.731 197.209 214.613L189.61 206.422C189.4 206.197 189.414 205.844 189.639 205.635C189.864 205.426 190.217 205.438 190.426 205.665L198.025 213.856C198.234 214.081 198.221 214.433 197.995 214.643C197.888 214.742 197.752 214.791 197.617 214.791Z" fill="white" />
                                                <path d="M193.718 219.803C193.569 219.803 193.42 219.743 193.31 219.625L185.711 211.434C185.502 211.208 185.515 210.856 185.741 210.647C185.966 210.438 186.319 210.45 186.528 210.677L194.127 218.868C194.336 219.093 194.323 219.445 194.097 219.654C193.99 219.754 193.854 219.803 193.718 219.803Z" fill="white" />
                                                <path d="M189.82 224.815C189.671 224.815 189.522 224.755 189.412 224.637L181.813 216.445C181.604 216.22 181.617 215.868 181.843 215.659C182.068 215.449 182.42 215.462 182.63 215.688L190.228 223.88C190.438 224.105 190.424 224.457 190.199 224.666C190.092 224.766 189.956 224.815 189.82 224.815Z" fill="white" />
                                                <path d="M185.365 229.27C185.216 229.27 185.067 229.21 184.957 229.091L177.358 220.9C177.149 220.675 177.162 220.323 177.388 220.114C177.613 219.904 177.966 219.917 178.175 220.143L185.774 228.334C185.983 228.56 185.97 228.912 185.744 229.121C185.637 229.221 185.501 229.27 185.365 229.27Z" fill="white" />
                                                <path d="M159.552 367.562L167.977 365.021L162.185 331.314L149.75 335.064L159.552 367.562Z" fill="#FFB6B6" />
                                                <path d="M188.36 358.667C188.756 359.029 189.324 360.414 189.479 360.927C189.955 362.507 189.061 364.173 187.482 364.649L161.389 372.517C160.312 372.842 159.175 372.232 158.85 371.154L158.523 370.068C158.523 370.068 156.248 367.192 157.692 362.367C157.692 362.367 161.945 364.522 165.392 358.098L166.052 355.023L178.91 359.551L184.966 358.509C186.291 358.281 187.369 357.758 188.36 358.667L188.36 358.667V358.667Z" fill="#2F2E41" />
                                                <path d="M123.684 349.959L130.374 355.675L155.61 332.591L145.735 324.154L123.684 349.959Z" fill="#FFB6B6" />
                                                <path d="M146.734 369.395C146.642 369.924 145.785 371.15 145.436 371.558C144.364 372.812 142.479 372.96 141.225 371.888L120.507 354.182C119.652 353.451 119.551 352.165 120.282 351.31L121.019 350.447C121.019 350.447 122.21 346.979 127.051 345.587C127.051 345.587 127.535 350.33 134.803 349.76L137.747 348.654L140.884 361.921L145.033 366.454C145.941 367.446 146.963 368.07 146.734 369.395L146.734 369.395Z" fill="#2F2E41" />
                                                <path d="M157.681 123.205C165.457 123.205 171.761 116.902 171.761 109.125C171.761 101.349 165.457 95.0454 157.681 95.0454C149.905 95.0454 143.601 101.349 143.601 109.125C143.601 116.902 149.905 123.205 157.681 123.205Z" fill="#FFB6B6" />
                                                <path d="M146.318 122.851L164.869 128.788L167.095 135.466L166.353 160.696L141.865 131.756L146.318 122.851Z" fill="#E97E98" />
                                                <path d="M179.339 138.063L165.609 131.007V155.624L144.551 126.385L140.01 131.014L120.717 144L133.332 181.844V200.396L122.943 230.078L159.304 250.113L183.791 236.014L176.371 184.071L181.565 144.742L179.339 138.063Z" fill="#2F2E41" />
                                                <path d="M174.145 233.788L188.986 289.442L144.463 346.58L133.332 335.449L165.611 285.361L137.042 230.82L174.145 233.788Z" fill="#2F2E41" />
                                                <path d="M128.138 228.594L143.721 289.442L152.254 348.721L169.164 346.58L163.756 282.021L154.11 227.11L128.138 228.594Z" fill="#2F2E41" />
                                                <path d="M154.48 119.883V112.778L158.933 106.526L157.449 103.663L156.707 100.017C156.707 100.017 166.353 103.95 170.063 103.383C173.774 102.816 171.548 102.629 172.661 101.98C173.774 101.332 176 98.352 172.661 95.3896C169.321 92.4273 171.91 91.6852 165.05 92.4273C158.191 93.1693 149.286 93.1693 147.802 95.3955C146.318 97.6216 146.318 99.1057 146.318 99.1057C146.318 99.1057 137.413 99.8478 144.092 116.173L147.802 124.336L149.286 121.367C149.286 121.367 147.802 113.205 150.028 116.173C152.254 119.141 154.48 119.883 154.48 119.883V119.883Z" fill="#2F2E41" />
                                                <path d="M169.475 156.97L161.46 138.831C161.33 138.536 161.33 138.199 161.459 137.904C161.589 137.609 161.839 137.381 162.144 137.279L177.079 132.255C177.38 132.154 177.714 132.183 177.993 132.335C178.273 132.487 178.478 132.751 178.557 133.059L183.478 152.32C183.626 152.902 183.296 153.506 182.724 153.694L170.884 157.596C170.764 157.636 170.642 157.655 170.522 157.655C170.079 157.655 169.664 157.397 169.475 156.97V156.97Z" fill="#F2F2F2" />
                                                <path d="M160.783 137.105C160.978 137.001 161.241 136.864 161.558 136.703C161.404 136.576 161.29 136.397 161.247 136.184L160.951 134.702C160.874 134.317 161.051 133.927 161.392 133.733C163.423 132.578 170.492 128.812 175.995 129.041C176.339 129.055 176.644 129.269 176.782 129.585L177.418 131.036C177.497 131.215 177.512 131.405 177.476 131.582C177.905 131.529 178.335 131.483 178.765 131.449C179.035 131.427 179.292 131.569 179.422 131.807L179.747 132.403C179.953 132.781 179.766 133.254 179.357 133.388L161.449 139.262C161.041 139.396 160.612 139.129 160.551 138.704L160.424 137.811C160.383 137.523 160.526 137.241 160.783 137.105V137.105Z" fill="#3F3D56" />
                                                <path d="M171.33 150.734C174.287 148.185 175.369 144.593 173.746 142.712C172.124 140.83 168.412 141.372 165.455 143.922C164.259 144.924 163.321 146.198 162.719 147.638L150.328 158.596L155.594 164.301L167.251 152.893C168.764 152.509 170.162 151.769 171.33 150.734V150.734Z" fill="#FFB6B6" />
                                                <path d="M142.691 199.014L139.86 238.339C139.686 240.746 137.848 242.696 135.457 243.025C123.812 244.628 110.91 243.006 97.0219 238.855C94.6296 238.14 93.1289 235.769 93.4684 233.295L98.8174 194.315C99.1171 192.13 100.788 190.381 102.958 189.992C114.261 187.967 126.469 189.922 139.111 193.78C141.378 194.471 142.861 196.651 142.691 199.014V199.014Z" fill="#E97E98" />
                                                <path d="M125.868 174.631C131.854 174.885 133.507 189.292 133.641 194.821L135.124 194.785C135.103 193.917 134.511 173.52 125.932 173.148" fill="#3F3D56" />
                                                <path d="M128.88 144.742L120.717 144L113.559 178.14C112.195 184.645 116.459 190.996 122.996 192.196L128.88 193.276L169.692 162.551L160.243 148.173L132.59 165.519L128.88 144.742V144.742Z" fill="#2F2E41" />
                                                <path d="M133.352 162.759C124.898 162.397 110.818 190.308 110.57 191.085L111.984 191.536C112.042 191.356 125.96 163.928 133.289 164.242" fill="#3F3D56" />
                                                <path d="M0 372.501C0 372.869 0.515264 373.164 1.15691 373.164H292.831C293.473 373.164 293.988 372.869 293.988 372.501C293.988 372.134 293.473 371.838 292.831 371.838H1.15691C0.515264 371.838 0 372.134 0 372.501Z" fill="#CCCCCC" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_120_30">
                                                    <rect width="300" height="373.19" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    }
                    {isLoading && <AccountStatusCardLoader />}
                </Grid>
            </Box>
        </React.Fragment>
    )
}

export { AccountStatus };
