/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React Base Styles
import pxToRem from "../../../functions/pxToRem";
import colors from "../../base/colors";
import typography from "../../base/typography";

const { text } = colors;
const { size } = typography;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    styleOverrides: {
        root: {
            marginLeft: `0 !important`,
            fontSize: size.xxs,
            marginTop: pxToRem(3),
            padding: 0,
            color: text.secondary
        }
    },
};
