
import { CardHeader as MuiCardHeader } from '@mui/material'
import { styled } from '@mui/material/styles';


const StyledMuiCardHeader = styled(({ ...props }) => (<MuiCardHeader {...props} />))`
        padding: ${props => props.theme.spacing(1)};
        margin-bottom: 25px;
        padding-left:0;
        & .MuiTypography-root {
            font-weight: 600;
            position: relative;
            &:after {
                content: "";
                display: block;
                position: absolute;
                z-index: 1;
                width: 75px;
                height: 2px;
                background: #1242de;
                margin-top: 7px;
            }
        }
`;


function CardHeader({ title, action = null, ...props }) {


    return (
        <StyledMuiCardHeader title={title} titleTypographyProps={{ variant: 'h5', component: 'h5' }} action={action} {...props} />
    )
}

export { CardHeader }
